import { BusinessDay } from "./BusinessDay";
import { NUMBER, OBJECT, ResolveObject, STRING, UUID } from "./MetaType";
import { User } from "./User";

export type ActivityType = "payment" | "refund" | "credit" | "void";
export type TenderType = "cash" | "credit";

const MetaTransactionData = {
    amount: NUMBER(),
    count: NUMBER(),
};
export type TransactionData = ResolveObject<typeof MetaTransactionData>;

const MetaTransactionTaxData = {
    ...MetaTransactionData,
    tax: NUMBER(),
};
export type TransactionTaxData = ResolveObject<typeof MetaTransactionTaxData>;

const MetaTaxDataSummarization = {
    id: UUID(),
    name: STRING(),
    rate: NUMBER(),
    amount: NUMBER(),
    tax: NUMBER(),
    types: OBJECT({
        payment: OBJECT(MetaTransactionTaxData),
        refund: OBJECT(MetaTransactionTaxData),
        credit: OBJECT(MetaTransactionTaxData),
    }),
};
export type TaxDataSummarization = ResolveObject<typeof MetaTaxDataSummarization>;

const MetaSummarization = {
    location: STRING().array(),
    from: STRING(),
    to: STRING(),
    amount: NUMBER(),
    count: NUMBER(),
    tax: NUMBER(),
    types: OBJECT({
        payment: OBJECT(MetaTransactionData),
        refund: OBJECT(MetaTransactionData),
        credit: OBJECT(MetaTransactionData),
    }),
    taxes: OBJECT(MetaTaxDataSummarization).array(),
};

export type Summarization = ResolveObject<typeof MetaSummarization>;

export interface IUseTax {
    locationId: string;
    amount: number;
    supplemental: boolean;
    bizday: BusinessDay;
    createdBy: string;
}

export type IUseTaxAndUser = IUseTax & {
    user: User;
};

export interface IDailyDetailActivity {
    locationId: string;
    date: BusinessDay;
    sales: number;
    refunds: number;
    net: number;
    tax?: null | number;
    taxes: {
        locationId: string;
        taxId: string;
        taxRate: number;
        taxName: string;
        sales: number;
        refunds: number;
        net: number;
        tax: number;
    }[];
}
