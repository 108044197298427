import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    useTheme,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import {
    Cell,
    Column,
    createColumnHelper,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Header,
    Row,
    RowSelectionState,
    TableOptions,
    Updater,
    useReactTable,
} from "@tanstack/react-table";
import { ColumnDef, HeaderGroup } from "@tanstack/table-core/src/types";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ReactTableToolbar8 } from "./ReactTableToolbar8";

export function ReactTable8<T>({
    columns,
    data,
    refreshData,
    skipPageReset,
    title,
    options,
    renderRowSubComponent,
}: {
    columns: ColumnDef<T, any>[];
    data: any;
    refreshData?: any;
    skipPageReset?: boolean;
    title?: string;
    options?: {
        pageSize?: number;
        pageSizeOptions?: number[];
        showMultiSelection?: {
            toggleAllRowsSelectedFn(toggleAllRowsSelected: (value?: boolean) => void): void;
            onSelectionChange: (selectedRows: any[]) => void;
            showSelectedCount?: boolean;
        };
        hiddenColumns?: string[];
        hideToolbar?: boolean;
        hideHeader?: boolean;
        headerRowStyle?: any;
        hideShowAll?: boolean;
        dataTestId?: string;
    };
    // NOTE: we use this in lieu of the getSubRows property above to provide expanded column data.
    // NOTE: a side effect of this is Row.getCanExpand() is always false for us. Beware.
    renderRowSubComponent?: (row: Row<T>) => any;
}) {
    const [columnData, setColumnData] = useState<ColumnDef<T, any>[]>(columns);

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    useEffect(() => {
        if (options?.showMultiSelection) {
            const columnHelper = createColumnHelper<T>();
            const newData = Array.from(columns);
            newData.unshift(
                columnHelper.display({
                    id: "selection",
                    header: (h) => {
                        return (
                            <div>
                                <Checkbox
                                    checked={h.table.getIsAllRowsSelected()}
                                    indeterminate={h.table.getIsSomeRowsSelected()}
                                    onChange={h.table.getToggleAllRowsSelectedHandler()}
                                />
                            </div>
                        );
                    },

                    // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
                    cell: (r) => {
                        const row = r.row;
                        return (
                            <div>
                                <Checkbox
                                    checked={row.getIsSelected()}
                                    disabled={!row.getCanSelect()}
                                    indeterminate={row.getIsSomeSelected()}
                                    onChange={row.getToggleSelectedHandler()}
                                />
                            </div>
                        );
                    },
                })
            );
            setColumnData(newData);
        } else {
            setColumnData(columns);
        }
    }, [columns, options]);

    let _pageSize: number = options?.pageSize ?? 5;
    let pageSizeOptions: { label: string; value: number }[] = [];
    if (options?.pageSizeOptions) {
        options?.pageSizeOptions.map((val) => pageSizeOptions.push({ label: `${val}`, value: val }));
    }

    if (isEmpty(pageSizeOptions)) {
        pageSizeOptions = [{ label: `${_pageSize}`, value: _pageSize }];
        if (_pageSize * 5 < data.length) {
            pageSizeOptions.push({ label: `${_pageSize * 5}`, value: _pageSize * 5 });
        }
        if (_pageSize * 10 < data.length) {
            pageSizeOptions.push({ label: `${_pageSize * 10}`, value: _pageSize * 10 });
        }
        if (_pageSize * 50 < data.length) {
            pageSizeOptions.push({ label: `${_pageSize * 50}`, value: _pageSize * 50 });
        }
    }

    const pSize = pageSizeOptions.find((i) => i.value === options?.pageSize);
    if (options?.pageSize && isNil(pSize)) {
        pageSizeOptions.push({ label: `${options?.pageSize}`, value: options?.pageSize });
    }
    const all = pageSizeOptions.find((i) => i.label === "All");
    if (!options?.hideShowAll && isNil(all)) {
        pageSizeOptions.push({ label: "All", value: data.length });
    }

    pageSizeOptions.sort((a, b) => {
        if (Object.keys(a).indexOf("value") > -1 && Object.keys(b).indexOf("value") > -1) {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
        }
        return 0;
    });

    if (!_pageSize) {
        _pageSize = pageSizeOptions[0].value;
    }

    const visibleColumns: { [key: string]: boolean } = {};
    for (const colName of options?.hiddenColumns ?? []) {
        visibleColumns[colName] = false;
    }

    const [rowSelection, setRowSelection] = useState<any>({});
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [expanded, setExpanded] = useState<ExpandedState>({});

    const handleOnRowSelectionChange = useCallback(
        (valueFn: Updater<RowSelectionState>) => {
            if (typeof valueFn === "function") {
                const updatedRowSelection = valueFn(rowSelection);
                setRowSelection(updatedRowSelection);

                const selectedRows = Object.keys(updatedRowSelection).reduce((acc, key) => {
                    if (updatedRowSelection[key]) {
                        const index = parseInt(key, 10);
                        const row = data[index];
                        if (row) {
                            // @ts-ignore the typing in here is odd and thinks it's 'never'
                            acc.push(row);
                        }
                    }
                    return acc;
                }, []);

                options?.showMultiSelection?.onSelectionChange?.(selectedRows);
            }
        },
        [data, options?.showMultiSelection, rowSelection]
    );

    const tableOptions: TableOptions<T> = {
        columns: columnData,
        data,
        autoResetPageIndex: !Boolean(skipPageReset).valueOf(),
        initialState: {
            pagination: {
                pageSize: _pageSize,
            },
            columnVisibility: visibleColumns,
        },
        state: {
            rowSelection,
            globalFilter,
            expanded,
        },
        onRowSelectionChange: handleOnRowSelectionChange,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        enableMultiRowSelection: !!options?.showMultiSelection,
        enableSorting: true,
    };

    const table = useReactTable(tableOptions);

    useEffect(() => {
        options?.showMultiSelection?.toggleAllRowsSelectedFn(table.toggleAllRowsSelected);
    }, [options?.showMultiSelection, table]);

    const handleChangePage = (event: any, newPage: number) => {
        table.setPageIndex(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        table.setPageSize(Number(event.target.value));
    };

    const headerColumnStyles = (column: Column<T, any>): React.CSSProperties => {
        const meta: any = column.columnDef.meta ?? {};
        const rightAlign = meta.rightAlign;
        const inlineColumnHeaderStyles = meta.inlineColumnHeaderStyles;

        return {
            ...inlineColumnHeaderStyles,
            ...(rightAlign ? { textAlign: "right" } : {}),
            color: isDarkMode ? "#fff" : "#252631",
            verticalAlign: "top",
            fontWeight: "bold",
        };
    };

    return (
        <TableContainer>
            {options?.hideToolbar ? (
                <div />
            ) : (
                <ReactTableToolbar8
                    showSelectedCount={options?.showMultiSelection?.showSelectedCount ?? true}
                    numSelected={Object.keys(table.getSelectedRowModel().rowsById).length}
                    title={title}
                    preGlobalFilteredRowCount={table.getRowCount()}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                    refresh={refreshData}
                />
            )}

            <Table size={"small"} data-testid={`${options?.dataTestId ? options?.dataTestId : "react-table"}`}>
                {!options?.hideHeader && (
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup: HeaderGroup<T>) => (
                            <TableRow style={options?.headerRowStyle} key={headerGroup.id}>
                                {headerGroup.headers.map((header: Header<T, any>) => (
                                    <TableCell key={header.id} style={headerColumnStyles(header.column)}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.id !== "selection" && header.column.getCanSort() ? (
                                            <TableSortLabel
                                                active={!!header.column.getIsSorted()}
                                                // react-table has a unsorted state which is not treated here
                                                direction={
                                                    header.column.getNextSortingOrder() === "desc" ? "desc" : "asc"
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                            />
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                )}

                <TableBody>
                    {table.getRowModel().rows.map((row: Row<T>) => {
                        return (
                            <Fragment key={row.id}>
                                <TableRow>
                                    {row.getVisibleCells().map((cell: Cell<T, any>) => {
                                        return (
                                            <TableCell key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                {row.getIsExpanded() && renderRowSubComponent ? (
                                    <tr>
                                        <td colSpan={row.getVisibleCells().length}>{renderRowSubComponent(row)}</td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        );
                    })}
                </TableBody>

                <TableFooter>
                    {data.length > table.getState().pagination.pageSize && (
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={pageSizeOptions}
                                count={data.length}
                                rowsPerPage={table.getState().pagination.pageSize}
                                page={table.getState().pagination.pageIndex}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    )}
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default ReactTable8;
