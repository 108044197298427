import { POSLogo } from "@davo/portal-common";
import { LocationRecord, toDisplayDateTimeString } from "@davo/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { AccountDetailCard } from "./AccountDetailCard";

const useStyles = makeStyles({
    card_content: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
});

export interface ILocationDetailType {
    location: LocationRecord;
}

export function LocationDetail({ location }: ILocationDetailType) {
    const classes = useStyles();

    return (
        <div key={location.id} style={{ display: "flex", flexWrap: "wrap" }}>
            <AccountDetailCard
                title={<h2 style={{ display: "inline-block", fontSize: "25px" }}>{location.name}</h2>}
                subheader={
                    <div className={classes.card_content}>
                        {location.externalLocationId && (
                            <Typography>External Partner ID: {location.externalLocationId}</Typography>
                        )}
                        <POSLogo posType={location.posType} width={125} />
                        {location.disconnected && (
                            <Typography style={{ fontSize: "0.95em" }}>
                                Disconnected: {toDisplayDateTimeString(location.disconnected)}
                            </Typography>
                        )}
                    </div>
                }
                content={
                    <div className={classes.card_content}>
                        <Typography>{location.address1}</Typography>
                        {location.address2 ?? <Typography>{location.address2}</Typography>}
                        <Typography>
                            {location.city} {location.state}, {location.zip}
                        </Typography>
                        <Typography>{location.timezone}</Typography>
                    </div>
                }
            />
        </div>
    );
}
