import { DateTime } from "luxon";
import { BOOLEAN, DATETIME, ResolveObject, STRING, UUID } from "./MetaType";

export const automationUserId = "automation";
export const automationEmailAddress = "automation@davotechnologies.com";
export const automationFirstName = "Automation";
export const automationDisplayName = "Beth";

export const MetaUserSummary = {
    id: UUID(),
    firstName: STRING().optional(),
    lastName: STRING().optional(),
    email: STRING(),
};

export type UserSummary = ResolveObject<typeof MetaUserSummary>;

export const MetaUser = {
    ...MetaUserSummary,
    type: STRING<UserType>(),
    active: BOOLEAN(),
    phone: STRING().optional(),
    doNotSms: BOOLEAN(),
    emailUndeliverable: DATETIME().optional(),
    smsUndeliverable: DATETIME().optional(),
    googleId: STRING(40).optional(),
    screenMode: STRING<UserScreenMode>(),
    referralCode: STRING(10).optional(),
    boardingAuthType: STRING<LoginType>().optional(),
    addedBy: STRING(36).optional(),
    avalaraIdentityId: STRING(40).optional(),
    cloverSSOId: STRING(40).optional(),
    opsLoginId: STRING(40).optional(),
    updatedBy: UUID().optional(),
    updated: DATETIME().optional(),
};
export type User = ResolveObject<typeof MetaUser>;

export const CloverIneligibleForSSORoles = ["EMPLOYEE"];
export type CloverUserInfoRoles = "ADMIN" | "EMPLOYEE" | "MANAGER";
export type CloverUserInfoRolesIncludingOwner = CloverUserInfoRoles & "OWNER";

export const MetaCloverUserInfo = {
    id: UUID(),
    employeeId: STRING(40).optional(),
    merchantId: STRING(255),
    role: STRING<CloverUserInfoRolesIncludingOwner>(36).optional(),
    created: DATETIME().optional(),
};

export type CloverUserInfo = ResolveObject<typeof MetaCloverUserInfo>;

export const MetaUserSearch = {
    id: UUID(),
    firstName: STRING().optional(),
    lastName: STRING().optional(),
    email: STRING(),
};
export type UserSearch = ResolveObject<typeof MetaUserSearch>;

export enum UserTypes {
    "Admin" = "Admin",
    "Merchant" = "Merchant",
    "Superuser" = "Superuser",
    "API" = "API",
    "Partner" = "Partner",
    "POS" = "POS",
}

export type UserType = keyof typeof UserTypes;
export const OpsUserTypes = ["Admin", "Superuser"];

export const MetaScreenModes = ["light", "dark", "os"];
export type UserScreenMode = (typeof MetaScreenModes)[number];

export enum LoginTypes {
    "google" = "google",
    "api" = "api",
    "site" = "site",
    "oidc" = "oidc",
    "clover" = "clover",
    "opsLogin" = "opsLogin",
}

export type LoginType = keyof typeof LoginTypes;

export enum AppTypes {
    "opsPortal" = "opsPortal",
    "api" = "api",
    "merchantPortal" = "merchantPortal",
    "partnerPortal" = "partnerPortal",
    "unlock" = "unlock",
    "automation" = "automation",
    "posApi" = "posApi",
}

export type AppType = keyof typeof AppTypes;

export const AppTypeDescriptions = {
    api: "API",
    merchantPortal: "Merchant Portal",
    opsPortal: "Operations Portal",
    partnerPortal: "Partner Portal",
} as { [key in AppType]: string };

export const UserPermissionAppType = {
    ...AppTypeDescriptions,
    unlock: "Unlock",
    automation: "Automation",
} as { [key in AppType]: string };

export const MetaLoginAttempt = {
    email: STRING(),
    ts: DATETIME(),
    success: BOOLEAN(),
    ip: STRING().optional(),
    locked: BOOLEAN(),
    loginType: STRING<LoginType>(),
    app: STRING<AppType>(),
};

export type LoginAttempt = ResolveObject<typeof MetaLoginAttempt>;

export type PermissionRole = "admin" | "user";

export interface IAccountPermission {
    userId: string;
    accountId: string;
    emailOptOut: boolean;
    summaryOptIn: boolean;
    role: PermissionRole;
}

export interface IUserAndPermissions extends User {
    role: PermissionRole;
    emailOptOut: boolean;
    summaryOptIn: boolean;
    accountId: string;
}

export interface IUserAndPassword extends User {
    password?: null | string;
    updatedBy: string;
    updated?: null | DateTime;
}
