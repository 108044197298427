import React from "react";
import { useMatch } from "react-router-dom";
import { AccountDetailPane } from "./AccountDetailPane";
import { PartnerAccountSearch } from "./PartnerAccountSearch";

export function Accounts() {
    const match = useMatch("/accounts/:accountId");
    const accountId = match?.params?.accountId;

    if (accountId) {
        return <AccountDetailPane accountId={accountId} />;
    }

    return <PartnerAccountSearch />;
}
