import {
    ConnectionStatusLevelMessageOverride,
    IAccountLocationStatus,
    IConnectionStatus,
    IConnectionStatusLevelMessage,
    IConnectionStatusLevelMessageLinks,
    ILocationStatusInfo,
    ITaxProfileFormData,
    NonEmptyArray,
    toDisplayDateString,
} from "@davo/types";

export const getCredentialsStatus = (data: ITaxProfileFormData) => {
    // Always set credentials as working when a take give back tax profile is created or edited
    if (data.taxProfile?.filingMethod === "take-give-back" || data.filingMethod === "take-give-back") return "working";

    //When changing the TP’s frequency, whether it is group or not, it should mark the credentials as untested.
    if (data.oldFrequency && data.oldFrequency !== data.frequency) return "untested";

    // When initially creating new tax profiles or changing something other than frequency, group login credentials should be marked working
    if (data.credentialsType !== "SIMPLE") return "working";

    // For all other scenarios depending on whether reusing credentials is selected or not
    if (data.reuseExistingCredentials && data.taxProfile?.credentialsFailing)
        return data.taxProfile?.credentialsFailing;

    return "untested";
};

export const reduceToSingleAccountLocationStatus = (
    als: NonEmptyArray<IAccountLocationStatus>
): IAccountLocationStatus => {
    return als.reduce((acc: IAccountLocationStatus, cur: IAccountLocationStatus) => {
        const hasAnyIssue =
            cur.statusInfo.areBankAccountsRestricted ||
            cur.statusInfo.arePausedBankAccountsInUse ||
            cur.statusInfo.hasFailingTaxInfo ||
            cur.statusInfo.hasMissingBankInfo ||
            cur.statusInfo.hasMissingTaxInfo ||
            cur.statusInfo.hasUntestedTaxCredentials ||
            cur.statusInfo.pausedWithUnpauseDate;
        if (!hasAnyIssue) {
            return acc;
        }
        return {
            ...acc,
            locationId: cur.locationId,
            statusInfo: {
                areBankAccountsRestricted:
                    acc.statusInfo?.areBankAccountsRestricted || cur.statusInfo.areBankAccountsRestricted,
                arePausedBankAccountsInUse:
                    acc.statusInfo?.arePausedBankAccountsInUse || cur.statusInfo.arePausedBankAccountsInUse,
                hasFailingTaxInfo: acc.statusInfo?.hasFailingTaxInfo || cur.statusInfo.hasFailingTaxInfo,
                hasMissingBankInfo: acc.statusInfo?.hasMissingBankInfo || cur.statusInfo.hasMissingBankInfo,
                hasMissingTaxInfo: acc.statusInfo?.hasMissingTaxInfo || cur.statusInfo.hasMissingTaxInfo,
                hasUntestedTaxCredentials:
                    acc.statusInfo?.hasUntestedTaxCredentials || cur.statusInfo.hasUntestedTaxCredentials,
                pausedWithUnpauseDate: acc.statusInfo?.pausedWithUnpauseDate || cur.statusInfo.pausedWithUnpauseDate,
            },
        };
    }, als[0]);
};

export const defaultLocationStatusInfo: ILocationStatusInfo = {
    areBankAccountsRestricted: false,
    arePausedBankAccountsInUse: false,
    hasFailingTaxInfo: false,
    hasMissingBankInfo: false,
    hasMissingTaxInfo: false,
    hasUntestedTaxCredentials: false,
    pausedWithUnpauseDate: undefined,
};

export const defaultConnectionStatus: IConnectionStatus = {
    singleLocationIsInactive: false,
    hasMissingBankInfo: false,
    arePausedBankAccountsInUse: false,
    areBankAccountsRestricted: false,
    hasMissingTaxInfo: false,
    hasFailingTaxInfo: false,
    hasUntestedTaxCredentials: false,
    pausedWithUnpauseDate: undefined,
};

export const mapIAccountLocationsStatusToIConnectionStatus = (
    accountLocationStatus?: IAccountLocationStatus,
    isSingleLocationIsInactive: boolean = false
): IConnectionStatus => {
    const statusInfo: ILocationStatusInfo = accountLocationStatus?.statusInfo
        ? {
              ...accountLocationStatus.statusInfo,
          }
        : defaultLocationStatusInfo;
    return {
        ...defaultConnectionStatus,
        singleLocationIsInactive: isSingleLocationIsInactive,
        ...statusInfo,
    };
};

export const getConnectionStatusLevelMessage = (
    locationId: string | undefined,
    status: IConnectionStatus,
    links: IConnectionStatusLevelMessageLinks,
    statusLevelOverride?: ConnectionStatusLevelMessageOverride
): IConnectionStatusLevelMessage => {
    if (locationId && status.singleLocationIsInactive) {
        // For a single location also check if it's inactive. Don't include this when we're considering all locations.
        return {
            statusLevel: "error",
            statusMessage: "Location inactive",
            ...(statusLevelOverride?.singleLocationIsInactive || {}),
        };
    } else if (status.hasMissingBankInfo) {
        return {
            statusLevel: "error",
            statusMessage: "Missing bank info",
            statusDestination: links.missingBankLink,
            ...(statusLevelOverride?.hasMissingBankInfo || {}),
        };
    } else if (status.areBankAccountsRestricted) {
        return {
            statusLevel: "error",
            statusMessage: "New bank account required",
            statusDestination: links.restrictedBankLink,
            ...(statusLevelOverride?.areBankAccountsRestricted || {}),
        };
    } else if (status.arePausedBankAccountsInUse) {
        return {
            statusLevel: "error",
            statusMessage: "Paused bank account",
            statusDestination: links.pausedBankInUseLink,
            ...(statusLevelOverride?.arePausedBankAccountsInUse || {}),
        };
    } else if (status.hasMissingTaxInfo) {
        return {
            statusLevel: "error",
            statusMessage: "Missing tax profile info",
            statusDestination: links.missingTaxProfileLink,
            ...(statusLevelOverride?.hasMissingTaxInfo || {}),
        };
    } else if (status.hasFailingTaxInfo) {
        return {
            statusLevel: "error",
            statusMessage: "Failing tax credentials",
            statusDestination: links.failingTaxProfileLink,
            ...(statusLevelOverride?.hasFailingTaxInfo || {}),
        };
    } else if (status.pausedWithUnpauseDate) {
        return {
            statusLevel: "ok",
            statusMessage: `Set-asides start ${toDisplayDateString(status.pausedWithUnpauseDate)}`,
            statusDestination: links.pausedBankInUseLink,
            ...(statusLevelOverride?.pausedWithUnpauseDate || {}),
        };
    } else if (status.hasUntestedTaxCredentials) {
        return {
            statusLevel: "warning",
            statusMessage: "Credentials to be verified",
            ...(statusLevelOverride?.hasUntestedTaxCredentials || {}),
        };
    }
    return {
        statusLevel: "ok",
        statusMessage: "Everything looks good!",
        ...(statusLevelOverride?.default || {}),
    };
};
