import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useTaxProfileFormContext } from "../context";
import { TextField } from "../TextField";

export const AdditionalMetadata2: FunctionComponent = () => {
    const {
        isSubmitting,
        filingType,
        shouldBypassDetails,
        isTakeGiveBack,
        linked,
        showLinkedTaxProfileDetails,
        additionalStateMetadata2FieldDefinition,
        additionalMetadata2ValidationResults,
        additionalStateMetadata2,
        setAdditionalStateMetadata2,
    } = useTaxProfileFormContext();

    if (
        !filingType ||
        shouldBypassDetails ||
        isTakeGiveBack ||
        !additionalStateMetadata2FieldDefinition ||
        !additionalStateMetadata2FieldDefinition.isVisible ||
        (linked &&
            (!linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata2 || !showLinkedTaxProfileDetails))
    ) {
        return null;
    }

    return (
        <TextField
            data-testid={"additionalStateMetadata2"}
            validate={() => additionalMetadata2ValidationResults}
            inputProps={{
                [`data-testid`]: "additionalStateMetadataInput2",
                pattern: additionalStateMetadata2FieldDefinition.validationRegex,
            }}
            InputProps={{
                endAdornment: additionalStateMetadata2FieldDefinition.toolTip && (
                    <Tooltip title={additionalStateMetadata2FieldDefinition.toolTip}>
                        <InfoIcon fontSize={"small"} color="primary" />
                    </Tooltip>
                ),
            }}
            className="fs-exclude"
            label={`${additionalStateMetadata2FieldDefinition.label} ${
                additionalStateMetadata2FieldDefinition.helpText
                    ? ` (e.g. ${additionalStateMetadata2FieldDefinition.helpText})`
                    : ""
            }`}
            isDisabled={isSubmitting}
            value={additionalStateMetadata2}
            onChange={setAdditionalStateMetadata2}
            isRequired
        />
    );
};
