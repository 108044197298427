import {
    FilingDetails,
    FilingFrequency,
    FilingFrequencyMap,
    FilingHistoricalWithAttachments,
    MetaFilingHistoricalWithAttachments,
    TaxPeriod,
} from "@davo/types";
import { apiUrl } from "../util";
import { get, getArray } from "./api";

export async function getFilingFrequencyByJurisdiction(jurisdictionId: string) {
    return get<FilingFrequencyMap>(`api/common/filing-frequency/${jurisdictionId}/`, true);
}

export async function calculateFilingPeriod(jurisdictionId: string, frequency: FilingFrequency) {
    return get<TaxPeriod>(`api/common/filing-frequency/filing-period/${jurisdictionId}/${frequency}/`, true);
}

export async function getHistoricalFilingsForAccount(
    accountId: string,
    abortController?: AbortController
): Promise<FilingHistoricalWithAttachments[]> {
    return getArray(
        `api/common/account/${accountId}/filings/historical`,
        true,
        MetaFilingHistoricalWithAttachments,
        abortController
    );
}

export async function getAttachment(accountId: string, taxProfileId: string, filingId: string, attachmentId: string) {
    const url = `${apiUrl}/api/common/account/${accountId}/tax-profiles/${taxProfileId}/filings/${filingId}/attachments/${attachmentId}`;
    const result = await fetch(url, {
        method: "GET",
        headers: {},
        credentials: "include",
    });
    if (result.ok) {
        return result.blob();
    }
    throw new Error("Could not fetch from S3!");
}

export async function getAttachmentHistorical(
    accountId: string,
    taxProfileId: string,
    filingHistoricalId: string,
    attachmentHistoricalId: string
) {
    const url = `${apiUrl}/api/common/account/${accountId}/tax-profiles/${taxProfileId}/filings-historical/${filingHistoricalId}/attachments-historical/${attachmentHistoricalId}`;
    const result = await fetch(url, {
        method: "GET",
        headers: {},
        credentials: "include",
    });
    if (result.ok) {
        return result.blob();
    }
    throw new Error("Could not fetch from S3!");
}

export async function getFiling(accountId: string, taxProfileId: string, filingId: string) {
    return get<FilingDetails>(`api/common/account/${accountId}/tax-profiles/${taxProfileId}/filing/${filingId}`, true);
}
