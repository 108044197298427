import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";
import { BankAccount, Location, TaxProfile } from "./Account";
import { StatusLevel } from "./Status";
import { toDisplayDateString } from "./Utils";

export function getBankingStatus(bankAccount?: BankAccount | null) {
    const isBankAccountPaused = bankAccount ? bankAccount.paused : false;
    const bankAccountUnpauseDate =
        bankAccount?.paused && bankAccount.unpauseDate ? toDisplayDateString(bankAccount.unpauseDate) : "";
    const arePausedBankAccountsInUse = isBankAccountPaused && isEmpty(bankAccountUnpauseDate);

    return {
        isBankAccountPaused,
        bankAccountUnpauseDate,
        arePausedBankAccountsInUse,
    };
}

export function getHasFailingTaxInfo(taxProfiles?: TaxProfile[] | null) {
    return (
        taxProfiles?.some((tp) => tp.credentialsFailing === "failing" && tp.filingMethod !== "take-give-back") ?? false
    );
}

export function getHasUntestedTaxCredentials(taxProfiles?: TaxProfile[] | null) {
    return (
        taxProfiles?.some((tp) => tp.credentialsFailing === "untested" && tp.filingMethod !== "take-give-back") ?? false
    );
}

export function getTaxProfileStatus(taxProfiles?: TaxProfile[] | null) {
    const hasMissingTaxInfo = taxProfiles?.length === 0;
    const hasFailingTaxInfo = getHasFailingTaxInfo(taxProfiles);
    const hasUntestedTaxCredentials = getHasUntestedTaxCredentials(taxProfiles);

    return {
        hasMissingTaxInfo,
        hasFailingTaxInfo,
        hasUntestedTaxCredentials,
    };
}

export function getLocationsInfo(
    locations: Location[],
    bankAccount?: BankAccount | null,
    taxProfiles?: TaxProfile[] | null
) {
    const hasMultipleLocations = locations && locations.length > 0 ? locations.length > 1 : false;
    const hasMissingBankInfo = locations && locations.length > 0 ? locations.some((l) => !l.bankAccountId) : false;
    const hasMissingTaxInfo =
        locations.length > 0 ? locations.some((l) => l.taxProfileIds.length === 0 && l.bankAccountId) : false;
    const hasFailingTaxInfo = taxProfiles
        ? getHasFailingTaxInfo(taxProfiles)
        : locations && locations.length > 0
          ? locations.some((l) =>
                l.taxProfiles.some((tp) => tp.credentialsFailing === "failing" && tp.filingMethod !== "take-give-back")
            )
          : false;
    const hasUntestedTaxCredentials = taxProfiles
        ? getHasUntestedTaxCredentials(taxProfiles)
        : locations && locations.length > 0
          ? locations.some((l) =>
                l.taxProfiles.some((tp) => tp.credentialsFailing === "untested" && tp.filingMethod !== "take-give-back")
            )
          : false;
    // specific to bank being passed in
    const arePausedBankAccountsInUse = locations.length > 0 ? bankAccountConnectionStatus(locations) : false;

    const { isBankAccountPaused, bankAccountUnpauseDate } = getBankingStatus(bankAccount);
    const hasAccountLocationWithoutFileOnlyOrMerchantPay =
        locations && locations.length > 0
            ? locations.some((l) =>
                  l.taxProfiles.some((tp) => tp.filingMethod !== "file-only" && tp.filingMethod !== "merchant-pay")
              )
            : false;

    // Converting created to a string due to full location objects being put on the queue (which deserializes created as a string vs. object). Once daily sales tax report is fixed, this can be removed
    const hasAccountLocationWithFirstMonthMerchantPay =
        locations.length > 0
            ? locations.some(
                  (l) =>
                      l.firstMonthMerchantPay &&
                      l.created &&
                      DateTime.fromISO(l.created.toString()).startOf("month") >= DateTime.utc().startOf("month")
              )
            : false;

    const isActionRequired =
        hasMissingTaxInfo ||
        hasFailingTaxInfo ||
        hasMissingBankInfo ||
        (hasAccountLocationWithoutFileOnlyOrMerchantPay && isBankAccountPaused && !bankAccountUnpauseDate);

    return {
        hasMultipleLocations,
        hasMissingBankInfo,
        hasMissingTaxInfo,
        isBankAccountPaused,
        bankAccountUnpauseDate,
        hasFailingTaxInfo,
        hasUntestedTaxCredentials,
        hasAccountLocationWithoutFileOnlyOrMerchantPay,
        hasAccountLocationWithFirstMonthMerchantPay,
        arePausedBankAccountsInUse,
        isActionRequired,
    };
}

const bankAccountConnectionStatus = (allLocations: Location[]) =>
    allLocations.some(
        (loc) =>
            loc.active &&
            isNil(loc.disconnected) &&
            loc.bankAccount?.paused &&
            isNil(loc.bankAccount?.unpauseDate) &&
            (!loc.firstMonthMerchantPay ||
                (loc.created && loc.created.startOf("month") < DateTime.utc().startOf("month"))) &&
            !(
                loc.taxProfiles.length > 0 &&
                loc.taxProfiles.every(
                    (tp: TaxProfile) =>
                        tp.filingMethod && (tp.filingMethod === "file-only" || tp.filingMethod === "merchant-pay")
                )
            )
    );

export interface IConnectionStatus {
    singleLocationIsInactive: boolean;
    hasMissingBankInfo: boolean;
    arePausedBankAccountsInUse: boolean;
    areBankAccountsRestricted: boolean;
    hasMissingTaxInfo: boolean;
    hasFailingTaxInfo: boolean;
    hasUntestedTaxCredentials: boolean;
    pausedWithUnpauseDate: DateTime | null | undefined;
}

export type ConnectionStatusKeys = keyof (IConnectionStatus & { default: never });

export type ConnectionStatusLevelMessageOverride = {
    [key in ConnectionStatusKeys]?: IConnectionStatusLevelMessage;
};

export interface IConnectionStatusLevelMessageLinks {
    missingBankLink: string | undefined;
    restrictedBankLink: string | undefined;
    pausedBankInUseLink: string | undefined;
    missingTaxProfileLink: string | undefined;
    failingTaxProfileLink: string | undefined;
}

export interface IConnectionStatusLevelMessage {
    statusLevel: StatusLevel;
    statusMessage?: string;
    statusDestination?: string;
}

export interface ILocationStatusInfo {
    arePausedBankAccountsInUse: boolean;
    areBankAccountsRestricted: boolean;
    hasMissingBankInfo: boolean;
    hasMissingTaxInfo: boolean;
    hasFailingTaxInfo: boolean;
    hasUntestedTaxCredentials: boolean;
    pausedWithUnpauseDate: DateTime | null | undefined;
}

export interface IAccountLocationStatus {
    accountId: string;
    locationId?: string;
    statusInfo: ILocationStatusInfo;
}
