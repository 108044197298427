import { BusinessDay, INotice, NoticeType } from "@davo/types";
import { apiUrl } from "../util";
import { post } from "./api";
import { auth } from "./auth";

export const getNotices = async (accountId: string, periodStart?: BusinessDay, periodEnd?: BusinessDay) => {
    return post<(INotice & { downloadReady: boolean })[]>(
        `api/common/notice/search`,
        {
            accountId,
            periodStart,
            periodEnd,
        },
        true
    );
};

export const addNotice = async (
    taxProfileId: string,
    fileId: string,
    periodStart: BusinessDay,
    periodEnd: BusinessDay,
    taxDue?: number,
    penalty?: number,
    interest?: number,
    type?: NoticeType,
    noteFromMerchant?: string
) => {
    await post(
        `api/common/notice/add/${taxProfileId}`,
        {
            fileId,
            periodStart: periodStart.toSQLDate(),
            periodEnd: periodEnd.toSQLDate(),
            taxDue,
            penalty,
            interest,
            type,
            noteFromMerchant,
        },
        true
    );
};

export const cancelNotice = async (noticeId: string) => {
    await post(`api/common/notice/cancel/${noticeId}`, {}, true);
};

export const addNoticeFile = async (filename: string, validationId: string) => {
    return post<{ fileId: string }>(
        `api/common/notice/files/add`,
        {
            filename,
            validationId,
        },
        true
    );
};

export async function getNoticeFile(fileId: string) {
    const url = `${apiUrl}/api/common/notice/files/${fileId}`;
    const result = await fetch(url, {
        method: "GET",
        headers: {},
        credentials: "include",
    });
    if (result.ok) {
        return result.blob();
    } else if (result.status === 401) {
        await auth.logout();
        throw new Error("Authorization Error: " + (await result.text()));
    }
    throw new Error("Could not fetch from S3!");
}
