import groupBy from "lodash/groupBy";
import pick from "lodash/pick";
import { DateTime } from "luxon";
import { ACHTransactionStatus } from "./ACH";
import { AmendmentReason, AmendmentStatus } from "./Amendment";
import { BusinessDay } from "./BusinessDay";
import { OptionalDateTime } from "./DateUtil";
import { FilingFrequency } from "./FilingFrequencyInfo";
import { IJurisdiction } from "./Jurisdiction";
import { IAccountLocationStatus } from "./LocationInfo";
import { ManualSetAsideType } from "./ManualSetAside";
import {
    BOOLEAN,
    BUSINESS_DAY,
    DATETIME,
    INTEGER,
    JSON_BLOB,
    JSON_FIELD,
    NUMBER,
    OBJECT,
    ResolveObject,
    STRING,
    STRING_ARRAY,
    UUID,
} from "./MetaType";
import { OnboardCommunicationStatus } from "./OnboardCommunicationStatus";
import { POSType } from "./POS";
import { PenaltyReason, PenaltyStatus, PenaltyType } from "./Penalty";
import { TaxProfileFailureReason } from "./TPFailureReasons";
import { TaxProfileFilingMethod } from "./TaxProfileFilingMethod";
import { MetaUser, MetaUserSummary, PermissionRole } from "./User";
import { OptionalString } from "./Utils";

export const demoLocationNamePrefix = "DEMO:";
export const MetaAddress = {
    address1: STRING().optional(),
    address2: STRING().optional(),
    city: STRING().optional(),
    county: STRING().optional(),
    state: STRING().optional(),
    zip: STRING().optional(),
};
export type Address = ResolveObject<typeof MetaAddress>;

export const MetaMerchantAccountHistory = {
    account: STRING().optional(),
    location: STRING().optional(),
    event: STRING().optional(),
    eventType: STRING().optional(),
    eventDescription: STRING().optional(),
    name: STRING().optional(),
    created: DATETIME().optional(),
    updatedBy: STRING().optional(),
};

export type MerchantAccountHistory = ResolveObject<typeof MetaMerchantAccountHistory>;

export const MetaBankAccount = {
    id: UUID(),
    name: STRING(),
    routing: STRING(),
    accountStarred: STRING(),
    updatedBy: UUID(),
    paused: BOOLEAN(),
    hidden: BOOLEAN(),
    pausedMessage: STRING().optional(),
    unpauseDate: DATETIME().optional(),
    achFirstName: STRING().optional(),
    achLastName: STRING().optional(),
    nickname: STRING().optional(),
};
export type BankAccount = ResolveObject<typeof MetaBankAccount>;

export type SupplementalDataRequirements = {
    requireAdditionalSalesData: boolean;
    requireUseData: boolean;
};

export enum CredentialsTypes {
    SIMPLE = "SIMPLE",
    INTERNAL_GROUP = "INTERNAL_GROUP",
    PARTNER_GROUP = "PARTNER_GROUP",
}

export type CredentialsType = keyof typeof CredentialsTypes;

export const MetaTaxProfile = {
    id: UUID(),
    name: STRING(),
    legalName: STRING(),
    state: STRING(),
    stateTaxId: STRING().optional(),
    federalTaxId: STRING().optional(),
    frequency: STRING<FilingFrequency>(),
    filingNote: STRING().optional(),
    updatedBy: UUID(),
    updated: DATETIME().optional(),
    createdBy: UUID(),
    created: DATETIME().optional(),
    credentialsFailing: STRING(),
    failingReason: STRING<TaxProfileFailureReason>().optional(),
    hidden: BOOLEAN(),
    filingMethod: STRING<TaxProfileFilingMethod>().optional(),
    manualFiling: BOOLEAN(),
    requireAdditionalSalesData: BOOLEAN(),
    requireUseData: BOOLEAN(),
    earlyFilingRequired: BOOLEAN(),
    manualReconcileRequired: BOOLEAN(),
    manualFilingReason: STRING().optional(),
    taxRateType: STRING(),
    credentialsType: STRING<CredentialsType>(),
    credentialsNote: STRING().optional(),
    ratesVerified: DATETIME().optional(),
    ratesVerifiedBy: UUID().optional(),
    typeVerified: DATETIME().optional(),
    typeVerifiedBy: UUID().optional(),
    jurisdictionFilingTypeId: UUID().optional(),
    jurisdictionUIFlavorGroupId: UUID().optional(),
    showExpandedFields: BOOLEAN(),
    additionalStateMetadata: STRING().optional(),
    additionalStateMetadata2: STRING().optional(),
    additionalStateMetadata3: STRING().optional(),
};
export type TaxProfile = ResolveObject<typeof MetaTaxProfile>;

export const taxRateInclusionTypes: TaxRateInclusionType[] = ["included", "precluded", "unverified"];
export type TaxRateInclusionType = "included" | "precluded" | "unverified";

export const MetaTaxProfileTaxRate = {
    locationId: UUID(),
    taxProfileId: UUID(),
    taxName: STRING(),
    taxRate: NUMBER(),
    taxId: STRING(),
    included: STRING<TaxRateInclusionType>(),
    city: STRING().optional(),
    county: STRING().optional(),
    state: STRING().optional(),
    jurisdictionRateTypeId: UUID().optional(),
    updated: DATETIME(),
    updatedBy: UUID().optional(),
};

export type TaxProfileTaxRate = ResolveObject<typeof MetaTaxProfileTaxRate>;

export const MetaFilingTaxProfileTaxRate = {
    id: UUID(),
    filingId: UUID(),
    locationId: UUID(),
    taxProfileId: UUID(),
    taxName: STRING(),
    taxRate: NUMBER(),
    taxId: STRING(),
    included: STRING<TaxRateInclusionType>(),
    city: STRING().optional(),
    county: STRING().optional(),
    state: STRING().optional(),
    jurisdictionRateTypeId: UUID().optional(),
    updated: DATETIME(),
    updatedBy: UUID().optional(),
};

export type FilingTaxProfileTaxRate = ResolveObject<typeof MetaFilingTaxProfileTaxRate>;

export const MetaTaxRateInfo = {
    locationId: STRING(),
    name: STRING(),
    sales: NUMBER(),
    returns: NUMBER(),
    tax: NUMBER(),
    rate: INTEGER(), // * 10^7
    state: STRING().optional(),
    rateTypeId: STRING().optional(),
    rateTypeTag: STRING().optional(),
    rateTypeExternalSiteTag: STRING().optional(),
    rateTypeDescription: STRING().optional(),
    rateTypeTaxBreakDown: JSON_FIELD().optional(),
};
export type TaxRateInfo = ResolveObject<typeof MetaTaxRateInfo>;

export interface IFilteredBreakoutType {
    [key: string]: TaxRateInfo;
}

export const MetaCloverBillingStatus = {
    __id: INTEGER(),
    locationId: UUID(),
    posLocationId: STRING(),
    status: STRING(),
    daysLapsed: NUMBER(),
    isInTrial: BOOLEAN(),
    appVersion: STRING(),
    billingStartTime: DATETIME(),
    asOf: BUSINESS_DAY(),
    cloverSubscriptionId: STRING().optional(),
};
export type CloverBillingStatus = ResolveObject<typeof MetaCloverBillingStatus>;

export type DisconnectReasonCodes =
    | "SOLD"
    | "CLOSED"
    | "CPA"
    | "SELF"
    | "UNSUPPORTED"
    | "PRICE"
    | "UPSET"
    | "COMMERCE"
    | "MIDTRANS"
    | "ACCIDENT"
    | "OTHER"
    | "DELINQUENT";

export const DiscountReasons = {
    SOLD: "Sold",
    CLOSED: "Closed",
    CPA: "CPA/Accountant",
    SELF: "Self-File",
    UNSUPPORTED: "Unsupported POS",
    PRICE: "Too expensive",
    UPSET: "Upset",
    COMMERCE: "e-commerce",
    MIDTRANS: "Mid-Trans",
    ACCIDENT: "Accidentally subscribed",
    DELINQUENT: "Delinquent billing",
    OTHER: "Other",
} as { [key in DisconnectReasonCodes]: string };

export const DiscountReasonKeys = [...Object.keys(DiscountReasons)] as DisconnectReasonCodes[];

export type FirstFundingCatchupEligibilityStatus =
    | "eligible"
    | "ineligible"
    | "accepted"
    | "declined"
    | "expired"
    | "initiated";

// NOTE: this is the modal that's actually stored in the database
export const MetaLocationRecord = {
    ...MetaAddress,
    id: UUID(),
    preAccount: BOOLEAN(),
    accountId: UUID().optional(),
    active: BOOLEAN(),
    awaitingDisconnect: BOOLEAN(),
    bankAccountId: UUID().optional(),
    closeoutOffset: STRING(), // number of minutes to offset into the following day to define business day
    created: DATETIME().optional(),
    disconnected: DATETIME().optional(),
    fromDevice: BOOLEAN().optional(),
    deviceOS: STRING().optional(),
    fromDiceId: INTEGER().optional(), // temporary during migration
    name: STRING(),
    posLocation: STRING(),
    posMerchant: STRING(),
    posMid: STRING().optional(),
    posType: STRING<POSType>(),
    timezone: STRING(), // https://www.iana.org/time-zones
    updated: DATETIME().optional(),
    updatedBy: STRING(),
    firstFundingEligible: STRING<FirstFundingCatchupEligibilityStatus>(),
    firstFundingTimestamp: DATETIME().optional(),
    firstFundingDeclineReason: STRING().optional(),
    firstMonthMerchantPay: BOOLEAN(),
    validatedAddress: BOOLEAN(),
    disconnectNote: STRING().optional(),
    disconnectNoteBy: UUID().optional(),
    disconnectNoteDate: DATETIME().optional(),
    disconnectReasonCode: STRING<DisconnectReasonCodes>(20).optional(),
    disconnectReasonNote: STRING().optional(),
    externalLocationId: STRING().optional(),
    externalPosType: STRING().optional(),
    beta: BOOLEAN(),
    queryIntervalHours: NUMBER().optional(),
    replacedByLocationId: STRING().optional(),
    onboardingNote: STRING().optional(),
    onboardingNoteBy: UUID().optional(),
    onboardingNoteDate: DATETIME().optional(),
    onboardingCommunicationStatus: STRING<OnboardCommunicationStatus>(64).optional(),
};

// NOTE: this is an extension of the modal above adding additional fields that aren't stored in the account_location db table
export const MetaLocation = {
    ...MetaLocationRecord,
    bankAccount: OBJECT(MetaBankAccount).optional(),
    taxProfileIds: UUID().array(),
    taxProfiles: OBJECT(MetaTaxProfile).array(),
    cloverBillingStatus: OBJECT(MetaCloverBillingStatus).optional(),
};

export const MetaLocationHistory = {
    ...MetaLocationRecord,
    historyTimestamp: DATETIME(),
    historyId: UUID(),
};

export type LocationHistory = ResolveObject<typeof MetaLocationHistory>;

export const MetaPartnerLocation = {
    ...MetaLocationRecord,
    externalLocationId: STRING().optional(),
};

export const MetaIntegratorWebhookLocation = {
    address1: STRING(),
    address2: STRING().optional(),
    city: STRING(),
    closeoutOffset: STRING().optional(),
    email: STRING(),
    id: STRING(),
    name: STRING(),
    phone: STRING(),
    postalCode: STRING(),
    state: STRING(),
};

export const MetaIntegratorConnectWebhook = {
    locationId: STRING(),
    type: STRING(),
    location: OBJECT(MetaIntegratorWebhookLocation).optional(),
};

export type LocationRecord = ResolveObject<typeof MetaLocationRecord>;
export type Location = ResolveObject<typeof MetaLocation>;
export type PartnerLocation = ResolveObject<typeof MetaPartnerLocation>;
export type IntegratorConnectWebhook = ResolveObject<typeof MetaIntegratorConnectWebhook>;
export type IntegratorConnectWebhookLocation = ResolveObject<typeof MetaIntegratorWebhookLocation>;

export const MetaLocationInfo = {
    id: UUID(),
    locationId: STRING(),
    data: JSON_BLOB().optional(),
    acquired: DATETIME().optional(),
};
export type LocationInfo = ResolveObject<typeof MetaLocationInfo>;

export const MetaCloverMerchantInfo = {
    id: UUID(),
    merchantId: STRING(255),
    planType: STRING(255).optional(),
    hasTaxRates: BOOLEAN(),
    created: DATETIME().optional(),
};

export type CloverMerchantInfo = ResolveObject<typeof MetaCloverMerchantInfo>;

export const MetaQuickBooksMerchantInfo = {
    id: UUID(),
    posMerchantId: STRING(255),
    posLocationId: STRING(255),
    planInfo: JSON_BLOB().optional(),
    created: DATETIME().optional(),
};

export type QuickBooksMerchantInfo = ResolveObject<typeof MetaQuickBooksMerchantInfo>;

export const MetaRemittance = {
    id: UUID(),
    amount: NUMBER(),
    frequency: STRING<FilingFrequency>(),
    jurisdiction: STRING(),
    locationId: STRING().optional(),
};
export type Remittance = ResolveObject<typeof MetaRemittance>;

export const MetaAchInfo = {
    achTransactionId: UUID(),
    statusNotes: STRING(),
    statusUpdated: DATETIME().optional(),
    created: DATETIME(),
    amount: NUMBER(),
};
export type SettlementAchInfo = ResolveObject<typeof MetaAchInfo>;

export const MetaSettlement = {
    fundedIn: NUMBER(),
    fundedOut: NUMBER(),
    fundedInfo: OBJECT(MetaAchInfo).array(),
    transferred: NUMBER(),
    transferredInfo: OBJECT(MetaAchInfo).array(),
    pendingIn: NUMBER(),
    pendingInInfo: OBJECT(MetaAchInfo).array(),
    pendingOut: NUMBER(),
    pendingOutInfo: OBJECT(MetaAchInfo).array(),
    failed: NUMBER(),
    failedInfo: OBJECT(MetaAchInfo).array(),
    none: NUMBER(),
    noneInfo: OBJECT(MetaAchInfo).array(),
};
export type Settlement = ResolveObject<typeof MetaSettlement>;

export const MetaInternalSummary = {
    daily: OBJECT({
        date: STRING(),
        sales: NUMBER(),
        returns: NUMBER(),
        tax: NUMBER(),
        taxableSales: NUMBER(),
        status: OBJECT(MetaSettlement),
        manualStatus: OBJECT(MetaSettlement),
        remittances: OBJECT(MetaRemittance).array(),
    }).array(),
    sales: NUMBER(),
    refunds: NUMBER(),
    tax: NUMBER(),
    taxableSales: NUMBER(),
    tenderCash: NUMBER(),
    tenderCredit: NUMBER(),
    status: OBJECT(MetaSettlement),
    remitted: NUMBER(),
    taxProfileTaxRates: OBJECT(MetaTaxProfileTaxRate).array(),
    breakout: OBJECT({
        id: STRING(),
        locationId: STRING(),
        name: STRING(),
        sales: NUMBER(),
        returns: NUMBER(),
        tax: NUMBER(),
        rate: NUMBER(),
        state: STRING().optional(),
    }).array(),
};
export type InternalSummary = ResolveObject<typeof MetaInternalSummary>;

export const MetaAccountRecord = {
    id: UUID(),
    name: STRING(),
    updated: DATETIME().optional(),
    updatedBy: UUID(),
    fromDiceId: INTEGER().optional(), // temporary during migration
    partnerId: STRING().optional(),
    accountOwnerId: STRING().optional(),
    externalId: STRING().optional(),
    note: STRING().optional(),
    enableConsolidatedBilling: BOOLEAN(),
};

export type AccountRecord = ResolveObject<typeof MetaAccountRecord>;

export type AccountRecordWithLocationStatus = AccountRecord & {
    status: IAccountLocationStatus;
};

export const MetaAccountHistoryRecord = {
    id: UUID(),
    name: STRING(),
    updated: DATETIME().optional(),
    updatedBy: UUID(),
    fromDiceId: INTEGER().optional(),
    historyId: UUID(),
    historyTimestamp: DATETIME(),
    partnerId: STRING().optional(),
    accountOwnerId: STRING().optional(),
    externalId: STRING().optional(),
    note: STRING().optional(),
    enableConsolidatedBilling: BOOLEAN(),
};

export type AccountHistoryRecord = ResolveObject<typeof MetaAccountHistoryRecord>;

export const MetaMinimalAccount = {
    id: UUID(),
    name: STRING(),
    partnerId: STRING().optional(),
    accountOwnerId: STRING().optional(),
    note: STRING().optional(),
};

export const MetaAccountSearchRecord = {
    id: UUID(),
    name: STRING(),
    hasActiveLocations: BOOLEAN(),
    externalLocationId: STRING().optional(),
};
export type AccountSearchRecord = ResolveObject<typeof MetaAccountSearchRecord>;

export const MetaInvitationSearchRecord = {
    id: STRING(),
    posType: STRING(),
    posMerchant: STRING(),
    email: STRING(),
    businessName: STRING(),
};
export type InvitationSearchRecord = ResolveObject<typeof MetaInvitationSearchRecord>;

export const MetaAccountSummary = {
    ...MetaAccountRecord,
    failingTaxCredsCount: INTEGER().optional(),
    numberOfActiveLocations: INTEGER(),
    posTypeList: STRING(),
    minimumLocationStatus: STRING<"error" | "warning" | "ok" | "inactive">(),
    minimumLocationStatusMessage: STRING(),
    locationIdList: STRING().optional(),
    locationNameList: STRING().optional(),
    noBankCount: INTEGER().optional(),
    pausedBankCount: INTEGER().optional(),
    noTaxCount: INTEGER().optional(),
};
export type AccountSummary = ResolveObject<typeof MetaAccountSummary>;

export const MetaBankDetails = {
    routing: STRING(),
    account: STRING(),
    paused: BOOLEAN().optional(),
    pausedMessage: STRING().optional(),
    nickname: STRING().optional(),
};
export type BankDetails = ResolveObject<typeof MetaBankDetails>;

export const MetaBankAccountWithAccount = {
    ...MetaBankAccount,
    account: STRING(),
};
export type BankAccountWithAccount = ResolveObject<typeof MetaBankAccountWithAccount>;

export const MetaBankAccountPausedMessage = {
    pausedMessage: STRING(),
    unpauseDate: DATETIME().optional(),
};
export type BankAccountPausedMessage = ResolveObject<typeof MetaBankAccountPausedMessage>;

export const MetaBankAccountAchInfoMessage = {
    achFirstName: STRING().optional(),
    achLastName: STRING().optional(),
};
export type BankAccountAchInfoMessage = ResolveObject<typeof MetaBankAccountAchInfoMessage>;

export const MetaBankAccountNachaRestrictedMessage = {
    isNachaRestricted: BOOLEAN(),
};
export type BankAccountNachaRestrictedMessage = ResolveObject<typeof MetaBankAccountNachaRestrictedMessage>;

export type FilingWithAttachments = Filing & { attachments: FilingAttachment[] };

export const MetaLocationToTaxProfile = {
    accountLocationId: UUID(),
    accountTaxProfileId: UUID(),
    created: DATETIME(),
    externalStateLocationId: STRING().optional(),
};
export type LocationToTaxProfile = ResolveObject<typeof MetaLocationToTaxProfile>;

export const MetaPenaltyToNotice = {
    penaltyId: UUID(),
    noticeId: UUID(),
    created: DATETIME(),
};
export type PenaltyToNotice = ResolveObject<typeof MetaPenaltyToNotice>;

export const MetaPotentialMidTrans = {
    locationId: STRING(),
    locationName: STRING(),
};
export type PotentialMidTrans = ResolveObject<typeof MetaPotentialMidTrans>;

export type FailingCredentialsType = "failing" | "untested" | "working";

export const MetaTaxProfileAndAccount = {
    ...MetaTaxProfile,
    account: OBJECT(MetaAccountRecord),
};
export type TaxProfileAndAccount = ResolveObject<typeof MetaTaxProfileAndAccount>;

export const MetaTaxProfileRecord = {
    ...MetaTaxProfile,
    accountId: UUID(),
};
export type TaxProfileRecord = ResolveObject<typeof MetaTaxProfileRecord>;

export const MetaTaxProfileHistoryRecord = {
    ...MetaTaxProfile,
    historyId: UUID(),
    historyTimestamp: DATETIME(),
    accountId: UUID(),
    credentialsType: STRING<CredentialsType>().optional(),
};
export type TaxProfileHistoryRecord = ResolveObject<typeof MetaTaxProfileHistoryRecord>;

export const MetaUntestedTaxProfileRecord = {
    id: UUID(),
    name: STRING(),
    legalName: STRING(),
    state: STRING(),
    frequency: STRING<FilingFrequency>(),
    url: STRING().optional(),
    accountId: UUID(),
    failedCount: NUMBER().optional(),
    bankPaused: BOOLEAN(),
    updated: DATETIME().optional(),
    jurisdictionFilingTypeId: UUID().optional(),
    jurisdictionFilingTypeName: STRING().optional(),
    defaultJurisdictionFilingTypeId: UUID().optional(),
    defaultJurisdictionFilingTypeName: STRING().optional(),
    locationIds: STRING_ARRAY(),
    potentialMidTrans: OBJECT(MetaPotentialMidTrans).array(),
    frequencyChanged: BOOLEAN(),
};
export type UntestedTaxProfileRecord = ResolveObject<typeof MetaUntestedTaxProfileRecord>;

export const MetaFailedTaxProfileRecord = {
    ...MetaTaxProfileRecord,
    failedTimestamp: DATETIME(),
    adminEmailAddresses: STRING(),
    hasLate: BOOLEAN().optional(),
    hasUpcoming: BOOLEAN().optional(),
    reason: STRING().optional(),
    failedCount: NUMBER().optional(),
    bankPaused: BOOLEAN(),
    recentlyBoarded: BOOLEAN(),
};
export type FailedTaxProfileRecord = ResolveObject<typeof MetaFailedTaxProfileRecord>;

export const MetaPOSCredentials = {
    locationId: STRING(),
    posMerchant: STRING(),
    posLocation: STRING(),
    token: STRING().optional(),
    updated: DATETIME().optional(),
    disconnected: DATETIME().optional(),
};
export type POSCredentials = ResolveObject<typeof MetaPOSCredentials>;

export const MetaPOSCredCheck = {
    valid: BOOLEAN(),
    validityPeriod: STRING(),
    validUntil: DATETIME().optional(),
};
export type POSCredCheck = ResolveObject<typeof MetaPOSCredCheck>;

export const MetaTaxProfileCredentials = {
    username: STRING().optional(),
    password: STRING().optional(),
    pin: STRING().optional(),
};
export type TaxProfileCredentials = ResolveObject<typeof MetaTaxProfileCredentials>;

export const MetaTaxProfileWithCredentials = {
    ...MetaTaxProfile,
    credentials: OBJECT(MetaTaxProfileCredentials),
    groupLoginCreds: OBJECT(MetaTaxProfileCredentials).optional(),
};
export type TaxProfileWithCredentials = ResolveObject<typeof MetaTaxProfileWithCredentials>;

export const MetaNonTax = {
    sales: NUMBER(),
    location: STRING(),
};

export const MetaFilingAttachment = {
    id: UUID(),
    filingId: UUID(),
    mimeType: STRING(64),
    filename: STRING(),
};
export type FilingAttachment = ResolveObject<typeof MetaFilingAttachment>;

export const MetaFilingPenalty = {
    id: UUID(),
    filingId: UUID(),
    status: STRING<PenaltyStatus>(36),
    reason: STRING<PenaltyReason>(36),
    type: STRING<PenaltyType>(36).optional(),
    amount: INTEGER().optional(),
    davoResponsible: BOOLEAN(),
    notes: STRING().optional(),
    created: DATETIME(),
    createdBy: UUID(),
    updated: DATETIME(),
    updatedBy: UUID(),
    wellsNote: STRING().optional(),
    wellsNoteBy: UUID().optional(),
    wellsNoteDate: DATETIME().optional(),
    wellsTxn: UUID().optional(),
};
export type FilingPenalty = ResolveObject<typeof MetaFilingPenalty>;

export const MetaFilingPenaltyExtension = {
    ...MetaFilingPenalty,
    state: STRING(),
    accountName: STRING(),
    createdByName: STRING().optional(),
    wellsNoteByName: STRING().optional(),
    periodEnd: BUSINESS_DAY(),
    noticeIds: STRING().array().optional(),
};
export type FilingPenaltyExtension = ResolveObject<typeof MetaFilingPenaltyExtension>;

export enum FilingStatuses {
    "open" = "open",
    "filed" = "filed",
    "returned" = "returned",
    "wontfile" = "wontfile",
    "reviewed" = "reviewed",
}

export type FilingStatus = keyof typeof FilingStatuses;
// note: we never actually save reviewed status to the DB, filing maintains existing status

export interface IFilingWithAttachmentsAndLocations {
    filings: (FilingWithAttachments & { locations: (IFilingLocation & { name: string })[] })[];
    filingsHistorical: FilingWithAttachments[];
}

export const MetaFiling = {
    id: UUID(),
    taxProfileId: UUID(),
    periodStart: BUSINESS_DAY(),
    periodEnd: BUSINESS_DAY(),
    frequency: STRING<FilingFrequency>(),
    jurisdiction: STRING(),
    created: DATETIME(),
    filingDueDate: BUSINESS_DAY(),
    asOf: DATETIME().optional(),
    filedDate: DATETIME().optional(),
    status: STRING<FilingStatus>(16),
    filedById: UUID().optional(),
    taxDue: INTEGER().optional(),
    adjustment: INTEGER().optional(),
    discount: INTEGER().optional(),
    fees: INTEGER().optional(),
    remitted: INTEGER().optional(),
    remittedPart2: INTEGER().optional(),
    remittedPart3: INTEGER().optional(),
    remittedPart4: INTEGER().optional(),
    remittedPart5: INTEGER().optional(),
    remittedDirect: INTEGER().optional(),
    returned: INTEGER().optional(),
    davoError: BOOLEAN().optional(),
    hasNotice: BOOLEAN().optional(),
    note: STRING().optional(),
    assignedUserId: UUID().optional(),
    wfPart1: UUID().optional(),
    wfPart2: UUID().optional(),
    wfPart3: UUID().optional(),
    wfPart4: UUID().optional(),
    wfPart5: UUID().optional(),
    prepayment: BOOLEAN().optional(),
    wellsNote: STRING().optional(),
    wellsNoteBy: UUID().optional(),
    wellsNoteDate: DATETIME().optional(),
    duration: INTEGER().optional(),
    reviewed: DATETIME().optional(),
    reviewedById: UUID().optional(),
    reviewNote: STRING().optional(),
    autofiledById: UUID().optional(),
    bulkTaskId: STRING().optional(),
    bulkTaskStarted: DATETIME().optional(),
    amendmentReason: STRING<AmendmentReason>().optional(),
    amendmentStatus: STRING<AmendmentStatus>().optional(),
    amendmentDate: DATETIME().optional(),
    amendmentNote: STRING().optional(),
    merchantNote: STRING().optional(),
    amendmentAdditionalNote: STRING().optional(),
    amendmentAdditionalNoteDate: DATETIME().optional(),
    amendmentAdditionalNoteBy: STRING().optional(),
    jurisdictionFilingTypeId: UUID().optional(),
};
export type Filing = ResolveObject<typeof MetaFiling>;
export type FilingRemittedField = "remitted" | "remittedPart2" | "remittedPart3" | "remittedPart4" | "remittedPart5";
export type FilingRemittedWellsField = "wfPart1" | "wfPart2" | "wfPart3" | "wfPart4" | "wfPart5";

export function toRemittedField(f: FilingRemittedWellsField) {
    switch (f) {
        case "wfPart1":
            return "remitted";
        case "wfPart2":
            return "remittedPart2";
        case "wfPart3":
            return "remittedPart3";
        case "wfPart4":
            return "remittedPart4";
        case "wfPart5":
            return "remittedPart5";
    }
}

export const MetaFilingWithAccount = {
    ...MetaFiling,
    accountName: STRING(),
    wellsNoteByName: STRING().optional(),
};
export type FilingWithAccount = ResolveObject<typeof MetaFilingWithAccount>;

export const MetaFilingClose = pick(MetaFiling, [
    "status",
    "taxDue",
    "discount",
    "fees",
    "remitted",
    "remittedPart2",
    "remittedPart3",
    "remittedPart4",
    "remittedPart5",
    "remittedDirect",
    "note",
    "merchantNote",
    "returned",
    "wfPart1",
    "wfPart2",
    "wfPart3",
    "wfPart4",
    "wfPart5",
    "filedDate",
    "duration",
    "assignedUserId",
    "reviewed",
    "reviewedById",
    "reviewNote",
    "autofiledById",
    "merchantNote",
    "bulkTaskId",
    "jurisdictionFilingTypeId",
]);
export type FilingClose = ResolveObject<typeof MetaFilingClose>;

export const MetaFilingUpdate = {
    ...MetaFilingClose,
    sendMerchantEmail: BOOLEAN().optional(),
    updateAsOfDate: BOOLEAN().optional(),
    updateReason: STRING(300),
    filedDate: DATETIME().optional(),
    amendmentReason: STRING<AmendmentReason>().optional(),
    amendmentStatus: STRING<AmendmentStatus>().optional(),
    amendmentDate: DATETIME().optional(),
    amendmentNote: STRING().optional(),
    davoError: BOOLEAN().optional(),
    hasNotice: BOOLEAN().optional(),
};
export type FilingUpdate = ResolveObject<typeof MetaFilingUpdate>;

export const MetaDryRunResults = {
    filingErrorMessage: STRING().optional(),
    discountErrorMessage: STRING().optional(),
    pullBackErrorMessage: STRING().optional(),
    reconcileErrorMessage: STRING().optional(),
    discount: INTEGER().optional(),
    discountUsedForUnderpay: INTEGER().optional(),
    returnToMerchantTotal: INTEGER().optional(),
    pullBackAmount: INTEGER().optional(),
    reconcileAmount: INTEGER().optional(),
    availableAmountAfterClose: INTEGER().optional(),
    amountOutstanding: INTEGER().optional(),
    coveredUnderfund: INTEGER().optional(),
};
export type DryRunResults = ResolveObject<typeof MetaDryRunResults>;

export const MetaFilingUpdateAuditType = {
    id: UUID(),
    filingId: UUID(),
    userId: UUID(),
    reason: STRING(300),
    created: DATETIME(),
};
export type FilingUpdateAuditType = ResolveObject<typeof MetaFilingUpdateAuditType>;

export const MetaFilingWithAttachments = {
    ...MetaFiling,
    attachments: OBJECT(MetaFilingAttachment).array(),
};

export const MetaFilingSummary = {
    id: STRING(),
    description: STRING(),
};
export type FilingSummary = ResolveObject<typeof MetaFilingSummary>;

export const MetaFilingDetails = {
    ...MetaFilingWithAttachments,
    taxProfile: OBJECT(MetaTaxProfileAndAccount),
    assignedUser: OBJECT(MetaUserSummary).optional(),
    filedBy: OBJECT(MetaUserSummary).optional(),
    autofiledBy: OBJECT(MetaUserSummary).optional(),
    hasUnsettled: BOOLEAN(),
    hasCloverBillingIssue: BOOLEAN().optional(),
    recentlyUpdatedCredentials: BOOLEAN().optional(),
    canAutofile: BOOLEAN(),
    reviewedUser: OBJECT(MetaUserSummary).optional(),
};
export type FilingDetails = ResolveObject<typeof MetaFilingDetails>;

export const MetaFilingHistoricalAttachment = {
    id: UUID(),
    filingHistoricalId: UUID(),
    mimeType: STRING(64).optional(),
    response: STRING().optional(),
    filename: STRING().optional(),
};
export type FilingHistoricalAttachment = ResolveObject<typeof MetaFilingHistoricalAttachment>;

export const MetaFilingHistorical = {
    id: UUID(),
    taxProfileId: UUID(),
    periodStart: BUSINESS_DAY(),
    periodEnd: BUSINESS_DAY(),
    frequency: STRING<FilingFrequency>(),
    jurisdiction: STRING(),
    created: DATETIME(),

    filedDate: DATETIME().optional(),
    filedById: UUID().optional(),
    note: STRING().optional(),
    merchantNote: STRING().optional(),
    notified: BOOLEAN(),

    remitted: INTEGER(),
    discount: INTEGER(),
    fromDiceId: INTEGER(),
    locationFromDiceId: INTEGER(),
    locationId: STRING(),
};
export type FilingHistorical = ResolveObject<typeof MetaFilingHistorical>;

export type FilingHistoricalWithAttachments = FilingHistorical & {
    attachments: FilingHistoricalAttachment[];
};
export type FilingWithAttachmentsAndHistory = FilingWithAttachments & { historical: boolean };
export type FilingWithAttachmentHistoryAndLocations = FilingWithAttachmentsAndHistory & {
    locations: (IFilingLocation & { name: string })[];
};

export const MetaFilingHistoricalWithAttachments = {
    ...MetaFilingHistorical,
    attachments: OBJECT(MetaFilingHistoricalAttachment).array(),
};

export const MetaUserRole = {
    ...MetaUser,
    role: STRING<PermissionRole>(),
    emailOptOut: BOOLEAN(),
    summaryOptIn: BOOLEAN(),
};
export type UserRole = ResolveObject<typeof MetaUserRole>;

export const MetaRelatedLocation = {
    active: BOOLEAN(),
    accountId: STRING().optional(),
    accountName: STRING().optional(),
    locationName: STRING(),
};
export type RelatedLocation = ResolveObject<typeof MetaRelatedLocation>;

export const MetaLocationWithPartnerId = {
    ...MetaLocation,
    partnerId: STRING().optional(),
};

export type LocationWithPartnerId = ResolveObject<typeof MetaLocationWithPartnerId>;

export const MetaMinimalAccountWithLocationInfo = {
    ...MetaMinimalAccount,
    minimalLocations: OBJECT(MetaLocationRecord).array(),
};
export type MinimalAccount = ResolveObject<typeof MetaMinimalAccountWithLocationInfo>;

export type MinimalAccountWithLocationStatus = MinimalAccount & {
    status: IAccountLocationStatus;
};

export const MetaSetAside = {
    id: UUID(),
    achTransactionId: UUID(),
    amount: NUMBER(),
    status: STRING(),
    statusNotes: STRING(),
    businessDay: BUSINESS_DAY(),
    debitDate: DATETIME(),
    locations: OBJECT({
        locationId: UUID(),
        amount: NUMBER(),
    }).array(),
};
export type SetAside = ResolveObject<typeof MetaSetAside>;

export const MetaUserInvitation = {
    id: UUID(),
    accountId: UUID(),
    role: STRING<PermissionRole>(),
    expires: DATETIME(),
    redeemed: DATETIME().optional(),
    redeemedBy: UUID().optional(),
    originalEmail: STRING().optional(),
    addedBy: STRING().optional(),
};
export type UserInvitation = ResolveObject<typeof MetaUserInvitation>;

export const MetaPartnerInvitation = {
    id: UUID(),
    partnerId: UUID(),
    expires: DATETIME(),
    redeemed: DATETIME().optional(),
    redeemedBy: UUID().optional(),
    originalEmail: STRING().optional(),
    addedBy: STRING().optional(),
};
export type PartnerInvitation = ResolveObject<typeof MetaPartnerInvitation>;

export const MetaDailyDetailTax = {
    taxId: STRING(),
    taxRate: NUMBER(),
    taxName: STRING(),
    state: STRING().optional(),
    county: STRING().optional(),
    city: STRING().optional(),
    sales: NUMBER(),
    refunds: NUMBER(),
    tax: NUMBER(),
    net: NUMBER(),
    locationId: UUID(),
};

export const MetaPeriodTransfer = {
    id: UUID(),
    locationId: UUID(),
    amount: NUMBER(), // integer pennies
    originDay: BUSINESS_DAY(),
    destinationDay: BUSINESS_DAY(),
    description: STRING().optional(),
    created: DATETIME(),
    createdBy: UUID(),
};
export type PeriodTransfer = ResolveObject<typeof MetaPeriodTransfer>;

export const MetaManualSetAside = {
    id: UUID(),
    locationId: STRING(),
    amount: NUMBER(), // integer pennies
    achTransactionId: STRING(),
    created: DATETIME(),
    createdBy: UUID(),
    periodDay: BUSINESS_DAY(),
    note: STRING().optional(),
    merchantNote: STRING().optional(),
    type: STRING<ManualSetAsideType>(),
};
export type ManualSetAside = ResolveObject<typeof MetaManualSetAside>;

export const MetaManualSetAsideAndACH = {
    ...MetaManualSetAside,
    status: STRING(),
    statusNotes: STRING().optional(),
    statusUpdatedBy: STRING().optional(),
};
export type ManualSetAsideAndACH = ResolveObject<typeof MetaManualSetAsideAndACH>;

export const MetaManualSetAsideFailure = {
    accountId: UUID(),
    accountName: STRING(),
    locationId: STRING(),
    periodDay: BUSINESS_DAY(),
    amount: NUMBER(),
    timestamp: DATETIME(),
    forteStatus: STRING(),
    manualType: STRING<ManualSetAsideType>(),
    direction: STRING<"push" | "pull">(),
};
export type ManualSetAsideFailure = ResolveObject<typeof MetaManualSetAsideFailure>;

export const MetaItemizedDailyDetail = {
    activityType: STRING(),
    facilitatorRemitted: BOOLEAN().optional(),
    normalized: STRING(),
    posActivityGroupId: STRING(),
    posActivityId: STRING(),
    posTaxId: STRING().optional(),
    posTimestamp: DATETIME(),
    tax: NUMBER().optional(),
    taxName: STRING().optional(),
    taxRate: NUMBER().optional(),
    taxableSales: NUMBER().optional(),
    totalSales: NUMBER(),
};
export type ItemizedDailyDetail = ResolveObject<typeof MetaItemizedDailyDetail>;

export const MetaDailyDetailSetAside = {
    status: STRING<ACHTransactionStatus>(16),
    amount: NUMBER(),
    statusNotes: STRING(),
    achTransactionId: UUID(),
};

export const MetaDailyDetailManualSetAside = {
    id: UUID(),
    amount: NUMBER(),
    note: STRING().optional(),
    status: STRING<ACHTransactionStatus>(16),
    statusNotes: STRING(),
    achTransactionId: UUID(),
    merchantNote: STRING().optional(),
};

export const MetaMerchantDetailSetAsideSummary = {
    amount: NUMBER(),
    status: STRING<ACHTransactionStatus>(16),
    statusNotes: STRING(),
    isManual: BOOLEAN(),
    manualNote: STRING().optional(),
};

export const MetaMerchantDailyDetail = {
    locationId: UUID(),
    locationName: STRING().optional(),
    date: BUSINESS_DAY(),
    sales: NUMBER(),
    salesTax: NUMBER(),
    totalSetAsideAmount: NUMBER(),
    status: STRING(),
    // NOTE: both daily setaside and any manual setasides
    setAsides: OBJECT(MetaMerchantDetailSetAsideSummary).array(),
};

export const MetaDailyDetail = {
    locationId: UUID(),
    locationName: STRING().optional(),
    date: BUSINESS_DAY(),
    sales: NUMBER(),
    refunds: NUMBER(),
    net: NUMBER(),
    taxes: OBJECT(MetaDailyDetailTax).array(),
    setAside: OBJECT(MetaDailyDetailSetAside).optional(),
    manualSetAsides: OBJECT(MetaDailyDetailManualSetAside).array(),
    transfersIn: OBJECT(MetaPeriodTransfer).array(),
    transfersOut: OBJECT(MetaPeriodTransfer).array(),
    remittances: OBJECT(MetaRemittance).array(),
    tenderCash: NUMBER().optional(),
    tenderCredit: NUMBER().optional(),
};
export type DailyDetailTax = ResolveObject<typeof MetaDailyDetailTax>;
export type DailyDetail = ResolveObject<typeof MetaDailyDetail>;
export type MerchantDailyDetail = ResolveObject<typeof MetaMerchantDailyDetail>;
export type DailyDetailSetAside = ResolveObject<typeof MetaDailyDetailSetAside>;
export type DailyDetailManualSetAside = ResolveObject<typeof MetaDailyDetailManualSetAside>;
export type MerchantDetailSetAsideSummary = ResolveObject<typeof MetaMerchantDetailSetAsideSummary>;

export const getMerchantDailyDetailStatus = (summaries: MerchantDetailSetAsideSummary[]) => {
    if (summaries.length === 0) {
        return "--";
    } else if (summaries.length === 1) {
        return summaries[0].status;
    } else if (Object.keys(groupBy(summaries, "status")).length === 1) {
        // If all the summaries have the same status return that status
        return summaries[0].status;
    } else {
        return "Multiple";
    }
};

export const MetaFilingCalculation = {
    allSales: NUMBER(),
    allReturns: NUMBER(),
    includedSales: NUMBER(),
    includedReturns: NUMBER(),
    includedTaxableSales: NUMBER(),
    includedTaxableReturns: NUMBER(),
    includedNonTaxableSales: NUMBER(),
    includedNonTaxableReturns: NUMBER(),
    includedTax: NUMBER(),
    allTenderCash: NUMBER(),
    allTenderCredit: NUMBER(),
    summary: OBJECT(MetaInternalSummary),
    dailyDetails: OBJECT(MetaDailyDetail).array(),
};

export type FilingCalculation = ResolveObject<typeof MetaFilingCalculation>;

export const MetaMerchantInvitation = {
    id: UUID(),
    posType: STRING<POSType>(64),
    expires: DATETIME(),
    businessName: STRING(255).optional(),
    phone: STRING(255).optional(),
    email: STRING(255).optional(),
    locationId: STRING(255).optional(),
};
export type MerchantInvitation = ResolveObject<typeof MetaMerchantInvitation>;

export const MetaNSFOpsPaneRecord = {
    accountId: UUID(),
    name: STRING(),
    date: DATETIME().optional(),
    emails: STRING().optional(),
    phones: STRING().optional(),
};
export type NSFOpsPaneRecord = ResolveObject<typeof MetaNSFOpsPaneRecord>;

export const MetaBankPausedOpsPaneRecord = {
    accountId: UUID(),
    name: STRING(),
    date: DATETIME().optional(),
    emails: STRING().optional(),
    phones: STRING().optional(),
    message: STRING().optional(),
};
export type BankPausedOpsPaneRecord = ResolveObject<typeof MetaBankPausedOpsPaneRecord>;

export const MetaReconcile = {
    filingId: UUID(),
    accountId: UUID(),
    locationId: UUID(),
    locationName: STRING(),
    name: STRING(),
    amount: NUMBER(),
    periodEnd: BUSINESS_DAY(), // date within month which this reconcile will help close
    periodStart: BUSINESS_DAY(),
    frequency: STRING(),
    jurisdiction: STRING(),
    requiresManualReconcile: BOOLEAN(),
    earlyFilingRequired: BOOLEAN(),
    filedDate: DATETIME(),
    totalDiscount: NUMBER(),
    note: STRING().optional(),
    noteBy: STRING().optional(),
    noteByName: STRING().optional(),
    noteDate: DATETIME().optional(),
};
export type Reconcile = ResolveObject<typeof MetaReconcile>;

export const MetaDisconnectData = {
    accountId: UUID(),
    locationId: UUID(),
    accountName: STRING().optional(),
    disconnectedDate: DATETIME().optional(),
    locationName: STRING(),
    posType: STRING(),
    canDeactivate: BOOLEAN(),
    periodDavoBalance: NUMBER(),
    periodPendingIn: NUMBER(),
    periodPendingOut: NUMBER(),
    jurisdiction: STRING().optional(),
    disconnectNote: STRING().optional(),
    disconnectNoteBy: STRING().optional(),
    disconnectNoteByName: STRING().optional(),
    disconnectNoteDate: DATETIME().optional(),
    disconnectReasonCode: STRING<DisconnectReasonCodes>(20).optional(),
    disconnectReasonNote: STRING().optional(),
    potentialMidTrans: OBJECT(MetaPotentialMidTrans).array(),
};
export type DisconnectData = ResolveObject<typeof MetaDisconnectData>;

export const MetaAccountWithUnverifiedTaxRatesData = {
    accountId: UUID(),
    accountName: STRING(),
    filingId: STRING(),
    profileName: STRING(),
    legalName: STRING(),
    periodStart: BUSINESS_DAY(),
    periodEnd: BUSINESS_DAY(),
    filingDueDate: BUSINESS_DAY(),
    jurisdiction: STRING(),
};
export type AccountWithUnverifiedTaxRates = ResolveObject<typeof MetaAccountWithUnverifiedTaxRatesData>;

export const MetaFailedBillingData = {
    invoiceDate: BUSINESS_DAY(),
    amount: NUMBER(),
    accountName: STRING(),
    forteStatus: STRING(),
    retryFailed: BOOLEAN(),
    accountId: UUID(),
    billingInvoiceId: STRING(),
    billingId: STRING(),
};
export type FailedBillingData = ResolveObject<typeof MetaFailedBillingData>;

export const MetaAccountLogHistory = {
    accountId: UUID(),
    timestamp: DATETIME(),
    updatedBy: STRING(),
    event: STRING(),
    description: STRING().optional(),
    label: STRING().optional(),
    userType: STRING().optional(),
    locationId: STRING().optional(),
    ref: UUID().optional(),
    alert: BOOLEAN(),
    historyId: UUID(), // log ID from the DB, called out here to differentiate ID from accountID from locationID
};

export type AccountLogHistory = ResolveObject<typeof MetaAccountLogHistory>;

export const MetaInvitationTestingLookup = {
    accountLocationInvitationId: UUID(),
    created: DATETIME(),
};
export type InvitationTestingLookup = ResolveObject<typeof MetaInvitationTestingLookup>;

export const MetaUnredeemedInvitationForHubspot = {
    id: UUID(),
    email: STRING(),
    phone: STRING().optional(),
    locationIds: STRING_ARRAY(),
};
export type UnredeemedInvitationForHubspot = ResolveObject<typeof MetaUnredeemedInvitationForHubspot>;

export interface IFilingRow {
    id: string;
    status: string;
    accountName: string;
    accountId: string;
    profileName: string;
    legalName: string;
    periodStart: BusinessDay;
    periodEnd: BusinessDay;
    filingDueDate: BusinessDay;
    filedDate?: null | DateTime;
    details: FilingDetails;
    hasUnsettled?: null | boolean;
    historical: boolean;
    recentlyUpdatedCredentials?: null | boolean;
    reviewed: string;
    reviewNote: string;
}

export interface IInvitation {
    id: string;
    posType: POSType;
    posMerchant?: null | string;
    businessName?: null | string;
    email?: null | string;
    phone?: null | string;
    partnerId?: null | string;
    created: DateTime;
    updated: DateTime;
    externalId?: null | string;
}

export interface IUnredeemedInvitation extends IInvitation {
    locationId: string;
    awaitingDisconnect: boolean;
    canDeactivate: boolean;
    address?: null | string;
    city?: null | string;
    state?: null | string;
    zip?: null | string;
    accountId?: null | string;
    relatedLocations?: null | RelatedLocation[];
}

export interface ILocationNoAccount extends LocationRecord {
    invitation: IUnredeemedInvitation;
}

export interface IFilingSession {
    id: string;
    userId: string;
    sessionId: string;
    startDate: DateTime;
    endDate: DateTime;
}

export function getTaxProfileFailureReasonDescription(reason: TaxProfileFailureReason, isEmail = false) {
    switch (reason) {
        case "password":
            return "The credentials you supplied are not allowing us to login to your tax agency website.";
        case "pin":
            return "Your tax agency requires that we get a pin from you so that we can file your sales tax.";
        case "twofactor":
            // note: the string "schedule a time" is a magic string so don't mess with it
            // it is used in a jsx context in TaxProfileFailingCreds.tsx -- sorry for this dirty dirty hack
            return (
                "The credentials we have for your tax agency website are working, but they require that we get an " +
                "authorization code from you. Please check for the authentication code they sent you via email or SMS and respond to this email or " +
                "schedule a time with a representative so we can request and receive the code before the code expires."
            );
        case "registration":
            return (
                "The credentials we have for your tax agency website are working, but we were unable to access the business associated within the " +
                "account. This may be because the associated business is not registered to file sales tax. Please update your credentials" +
                (isEmail ? " or reply to this email when you have corrected the issue." : ".")
            );
        case "state":
            return "We were unable to access your state account with the tax account numbers you provided.";
        case "webfile":
            return "The webfile number, also known as an RT number, is invalid. If you are having trouble locating this number, please check on the back or top left of the pre-printed return from the Comptroller's office.";
        case "draft":
            return "A DAVO representative started this tax profile for you. Please fill in the required fields.";
        default:
            return "";
    }
}

export type NewBoardingTypes = "new" | "no-account" | "no-bank" | "no-tp";

export interface INewBoarding {
    boardingDate: DateTime;
    status: string;
    locationName: string;
    locationId: string;
    accountId: string;
    hubspotId: OptionalString;
    hasChargedTax: boolean;
    potentialMidTrans: PotentialMidTrans[];
    onboardingCommunicationStatus: OptionalString;
    onboardingNote: OptionalString;
    onboardingNoteBy: OptionalString;
    onboardingNoteByName: OptionalString;
    onboardingNoteDate: OptionalDateTime;
    onboardingAssignedUserId: OptionalString;
    onboardingAssignedUserName: OptionalString;
    onboardingAssignedDate: OptionalString;
    onboardingReviewed: OptionalDateTime;
}

export interface IAccountAlias {
    accountId: string;
    alias: string;
    created: DateTime;
}

export interface IFilingLocation {
    filingId: string;
    locationId: string;
    taxDue?: null | number; // fractional pennies
    adjustment?: null | number; // fractional pennies
    discount?: null | number; // fractional pennies
    fees?: null | number; // fractional pennies
    remitted?: null | number; // fractional pennies
    returned?: null | number; // fractional pennies
    directRemittance?: null | number; // fractional pennies
    transfer?: null | number; // fractional pennies
    unfundedRemittance?: null | number; // fractional pennies
    manualSetAsideId?: null | string;
    note?: null | string;
    noteBy?: null | string;
    noteDate?: null | DateTime;
}

export interface ITaxProfileFormData {
    name: string;
    legalName: string;
    state?: null | IJurisdiction;
    frequency?: null | string;
    stateTaxId: string;
    federalTaxId: string;
    credentials: TaxProfileCredentials;
    taxProfile?: TaxProfile;
    reuseExistingCredentials: boolean;
    frequencyChangeAffectsCurrentPeriod: boolean;
    oldFrequency?: null | string;
    nameInUse?: boolean;
    credentialsType: CredentialsType;
    jurisdictionFilingTypeId?: null | string;
    jurisdictionUIFlavorGroupId?: null | string;
    filingMethod?: null | TaxProfileFilingMethod;
    parentTaxProfileId?: null | string;
    showExpandedFields: boolean;
    additionalStateMetadata?: null | string;
    additionalStateMetadata2?: null | string;
    additionalStateMetadata3?: null | string;
    sentToMerchant?: boolean;
}

export interface CloverV1Token {
    posMerchant: string;
}
