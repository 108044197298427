import { FrequencyLabels } from "@davo/types";
import React, { FunctionComponent } from "react";
import { FORM_FIELD_LABELS } from ".";
import { useTaxProfileFormContext } from "../context";
import Select from "../Select";

export const FilingFrequency: FunctionComponent = () => {
    const {
        linked,
        showLinkedTaxProfileDetails,
        isSubmitting,
        filingType,
        shouldBypassDetails,
        filingFrequencyValidationResults,
        selectedFrequencyList,
        frequency,
        setFrequency,
        shouldLimitEdit,
    } = useTaxProfileFormContext();

    if (
        !filingType ||
        shouldBypassDetails ||
        !selectedFrequencyList ||
        shouldLimitEdit ||
        (linked && (!linked.jurisdictionUIAdditionalTaxProfile.showFrequency || !showLinkedTaxProfileDetails))
    ) {
        return null;
    }

    return (
        <Select<string>
            validate={() => filingFrequencyValidationResults}
            data-testid={"filingFrequency"}
            inputProps={{
                id: "filingFrequencyInput",
                [`data-testid`]: "filingFrequencyInput",
            }}
            title={FORM_FIELD_LABELS.filingFrequency}
            isRequired
            isDisabled={isSubmitting}
            options={selectedFrequencyList.sort()}
            value={frequency}
            onChange={setFrequency}
            label={(f) => {
                if (f.startsWith("Ops-only: ")) {
                    return "Ops-only: " + FrequencyLabels[f.substring(10)].toString();
                } else {
                    return FrequencyLabels[f];
                }
            }}
        />
    );
};
