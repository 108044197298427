import { DateTime } from "luxon";
import { DATETIME, NUMBER, ResolveObject, STRING, UUID } from "./MetaType";

export enum ACHFundedStatusTypes {
    "funded" = "funded",
}

export type ACHFundedStatus = keyof typeof ACHFundedStatusTypes;

export enum ACHFailedStatusTypes {
    "rejected" = "rejected",
}

export type ACHFailedStatus = keyof typeof ACHFailedStatusTypes;

export enum ACHStoppedVoidedTypes {
    "voided" = "voided",
    "stopped" = "stopped",
}

export type ACHStoppedVoided = keyof typeof ACHStoppedVoidedTypes;
export type ACHTerminalStatus = ACHFundedStatus | ACHFailedStatus | ACHStoppedVoided;

export enum ACHNonTerminalStatusTypes {
    "pending" = "pending",
    "posted" = "posted",
    "settling" = "settling",
    "review" = "review",
    "hold" = "hold",
    "voiding" = "voiding",
}

export type ACHNonTerminalStatus = keyof typeof ACHNonTerminalStatusTypes;
export type ACHTransactionStatus = ACHNonTerminalStatus | ACHTerminalStatus;

export const isACHTerminal = (status: ACHTransactionStatus) =>
    Object.keys(ACHFailedStatusTypes).includes(status) ||
    Object.keys(ACHStoppedVoidedTypes).includes(status) ||
    Object.keys(ACHFundedStatusTypes).includes(status);

export const isACHFailed = (status: ACHTransactionStatus) => Object.keys(ACHFailedStatusTypes).includes(status);

export const isACHStoppedVoided = (status: ACHTransactionStatus) => Object.keys(ACHStoppedVoidedTypes).includes(status);

export const isACHFunded = (status: ACHTransactionStatus) => Object.keys(ACHFundedStatusTypes).includes(status);

export const isACHHeld = (status: ACHTransactionStatus) => status === "hold";

export const isACHStopped = (status: ACHTransactionStatus) => status === "stopped";

export const isACHNachaRejection = (statusNotes: string) => {
    return Object.values(ACHRestrictedNachaRejectionCodes).includes(statusNotes.slice(0, 3));
};

export const ACHRestrictedNachaRejectionCodes: string[] = [
    "R02",
    "R03",
    "R04",
    "R05",
    "R07",
    "R10",
    "R11",
    "R13",
    "R20",
    "R51",
    "U80",
    "U85",
];
export const ACHDisconnectionRejectionCodes: string[] = [...ACHRestrictedNachaRejectionCodes, "R08", "R16", "U02"];

export const ACHRejectionCodes = {
    R01: "INSUFFICIENT FUNDS",
    R02: "ACCOUNT CLOSED",
    R03: "NO ACCOUNT",
    R04: "INVALID ACCOUNT NUMBER",
    R05: "PRENOTE NOT RECEIVED",
    R06: "RETURNED PER ODFI",
    R07: "AUTHORIZATION REVOKED",
    R08: "PAYMENT STOPPED",
    R09: "UNCOLLECTED FUNDS",
    R10: "NO AUTHORIZATION",
    R11: "CHECK SAFEKEEPING RETURN",
    R12: "BRANCH SOLD",
    R13: "RDFI NOT QUALIFIED",
    R14: "DECEASED",
    R15: "BENEFICIARY DECEASED",
    R16: "ACCOUNT FROZEN",
    R20: "NON-TRANSACTION ACCOUNT",
    R23: "PAYMENT REFUSED",
    R24: "DUPLICATE ENTRY",
    R26: "MANDATORY ERROR",
    R28: "INVALID TRN",
    R29: "CORPORATE NOT AUTHORIZED",
    R31: "ODFI PERMITS LATE RETURN",
    R50: "INVALID COMPANY ID",
    R51: "INELIGIBLE / IMPROPER ITEM RELATED TO RCK",
    R56: "INVALID TRANSACTION DATE",
    R57: "STALE DATE",
    R95: "OVER LIMIT",
    R96: "ACCOUNT ON HOLD",
    R97: "RDFI DOES NOT PARTICIPATE",
    R98: "INVALID PASSWORD",
    R99: "DECLINED UNPAID ITEMS",
    U02: "ACCOUNT NOT APPROVED",
    U80: "PREAUTH DECLINE",
    U85: "AUTH ERROR",
} as { [key: string]: string };

export interface IAchTransaction {
    id: string;
    davoAccount: "fbo" | "operations";
    direction: "push" | "pull";
    created: DateTime;
    accountBankAccountId?: null | string;
    partnerBankAccountId?: null | string;
    firstName: string;
    lastName: string;
    amount: number;
    status: ACHTransactionStatus;
    statusNotes: string;
    statusUpdated: DateTime;
    ref?: null | string;
    organization: "old_fbo" | "old_operations" | "fbo" | "operations";
}

export interface IAchTransactionUpdate {
    __id: number;
    id: string;
    achTransactionId: string;
    timestamp: DateTime;
    status: ACHTransactionStatus;
    statusNotes: string;
    ref?: null | string;
    statusUpdatedBy?: null | string;
}

export const MetaACHTransactionSimple = {
    id: UUID(),
    created: DATETIME(),
    direction: STRING(),
    firstName: STRING(),
    status: STRING<ACHTransactionStatus>(),
    statusNotes: STRING(),
    amount: NUMBER(),
    accountId: STRING(),
    partnerName: STRING().optional(),
    createdBy: STRING().optional(),
    statusUpdatedBy: STRING().optional(),
    type: STRING(),
};
export type ACHTransactionSimple = ResolveObject<typeof MetaACHTransactionSimple>;
