import * as Sentry from "@sentry/browser";
import React from "react";
import { createRoot } from "react-dom/client";
import { Preload } from "./Preload";
import "./index.css";

// we only have D30_ENV available locally for the UI...
if (!process.env.D30_ENV) {
    Sentry.init({
        dsn: "https://7c1f6bbb1bab4bf39bae0183479699c0@o408956.ingest.sentry.io/5995747",
        environment: process.env.NODE_ENV,
        ignoreErrors: [/^Authorization Error:.*$/],
    });
}

const container = document.getElementById("root");
const root = createRoot(container!); // the exclaim here comes straight from the docs https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
root.render(<Preload />);
