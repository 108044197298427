import { BankAccount } from "@davo/types";
import { Typography } from "@mui/material";
import React from "react";

interface BankDetailPausedMessageProps {
    bankAccount?: BankAccount | null;
    nachaRestricted?: {
        isNachaRestricted: boolean;
    };
}

export const BankDetailsPausedMessage: React.FC<BankDetailPausedMessageProps> = ({ bankAccount, nachaRestricted }) => {
    if (!bankAccount || !bankAccount.paused) {
        return null;
    }

    const getPausedMessage = () => {
        if (nachaRestricted && nachaRestricted.isNachaRestricted) {
            return `PAUSED: In order to re-enable this account, a new bank account must be provided.`;
        }
        const pausedMessage = bankAccount.pausedMessage ? `: ${bankAccount.pausedMessage}` : "";
        return `PAUSED${pausedMessage}`;
    };

    return (
        <Typography data-testid="bank-detail-paused-message" style={{ color: "red", whiteSpace: "break-spaces" }}>
            {getPausedMessage()}
        </Typography>
    );
};
