import { initialCap, INotice, moneyFromCents, noop, NoticeStatuses, toDisplayDateString } from "@davo/types";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownload";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoteIcon from "@mui/icons-material/Note";
import NoteAddIcon from "@mui/icons-material/NoteOutlined";
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Link, Stack, Tooltip } from "@mui/material";
import { createColumnHelper, Row } from "@tanstack/react-table";
import { ColumnDef } from "@tanstack/table-core/src/types";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { ReactTable8 } from "./ReactTable8";
import { cancelNotice } from "./services";
import { d30Toast, d30ToastError } from "./Toast";
import { downloadNoticeFile } from "./util";

export function NoticesTable({
    notices,
    includeCancelButton,
    includeNotes,
    actionLabel,
    action,
    refresh,
}: {
    notices: (INotice & { downloadReady: boolean })[] | null | undefined;
    includeCancelButton: boolean;
    includeNotes: boolean;
    actionLabel?: string;
    action?: (noticeId: string) => void;
    refresh?: () => void;
}) {
    const cancel = useCallback(
        (rowId: string) => {
            cancelNotice(rowId)
                .then(() => {
                    d30Toast("Notice Cancelled");
                    if (refresh) {
                        refresh();
                    }
                })
                .catch(() => {
                    d30ToastError("Error cancelling notice, please try again later");
                });
        },
        [refresh]
    );

    const getDetailPaneData = useCallback((row: Row<INotice & { downloadReady: boolean }>) => {
        return (
            <div style={{ padding: "32px" }}>
                <Grid container spacing={2} rowSpacing={4}>
                    <Grid item container spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Stack direction="row" spacing={2}>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(row.original.taxDue)}
                                            subheader={"Tax Due"}
                                        />
                                    </Card>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(row.original.penalties)}
                                            subheader={"Penalties"}
                                        />
                                    </Card>
                                    <Card sx={{ flexGrow: 1 }}>
                                        <CardHeader
                                            data-testid={"davo-card"}
                                            className={"truncated-card-header"}
                                            title={moneyFromCents(row.original.interest)}
                                            subheader={"Interest"}
                                        />
                                    </Card>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {row.original.status !== "closed-invalid-file" &&
                                            row.original.downloadReady && (
                                                <Link
                                                    download
                                                    target="_blank"
                                                    onClick={async () => {
                                                        await downloadNoticeFile(row.original);
                                                    }}>
                                                    <Button
                                                        style={{ marginRight: "4px", cursor: "pointer" }}
                                                        variant="outlined"
                                                        startIcon={<CloudDownloadTwoToneIcon color="primary" />}>
                                                        Download File
                                                    </Button>
                                                </Link>
                                            )}
                                        {!row.original.downloadReady && (
                                            <Link
                                                download
                                                target="_blank"
                                                onClick={async () => {
                                                    // no-op, preserving link to match styling
                                                }}>
                                                <Button
                                                    style={{ marginRight: "4px", cursor: "pointer" }}
                                                    variant="outlined"
                                                    disabled={true}
                                                    startIcon={<CloudDownloadTwoToneIcon color="primary" />}>
                                                    File Processing
                                                </Button>
                                            </Link>
                                        )}
                                    </div>
                                    {row.original.noteFromMerchant && (
                                        <Card sx={{ flexGrow: 1 }}>
                                            <CardContent data-testid={"content"}>
                                                Your context for us:{" "}
                                                <Tooltip title={row.original.noteFromMerchant}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="Context"
                                                            onClick={() => {
                                                                noop();
                                                            }}
                                                            size="large">
                                                            <NoteAddIcon color="primary" />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </CardContent>
                                        </Card>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }, []);

    const columnHelper = createColumnHelper<INotice>();

    const columns = useMemo(() => {
        const cols = [];
        cols.push(
            columnHelper.accessor("id", {
                id: "expander", // Make sure expand columns have an ID
                header: "",
                cell: (data) => {
                    return (
                        <span
                            style={{
                                paddingLeft: "8px",
                            }}
                            className={"mui-expand-toggle-container"}
                            onClick={() => data.row.toggleExpanded()}>
                            {data.row.getIsExpanded() ? (
                                <span className={"expandLess"}>
                                    <ExpandLessIcon />
                                </span>
                            ) : (
                                <span className={"expandMore"}>
                                    <ExpandMoreIcon />
                                </span>
                            )}
                        </span>
                    );
                },
                enableSorting: false,
            }),

            columnHelper.accessor("periodStart", {
                header: "Period",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(DateTime.fromSQL(data.row.original.periodStart.toString()))}-
                        {toDisplayDateString(DateTime.fromSQL(data.row.original.periodEnd.toString()))}
                    </div>
                ),
            }),
            columnHelper.accessor("status", {
                header: "Status",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>{NoticeStatuses[data.cell.row.original.status!]}</div>
                ),
            }),
            columnHelper.accessor("type", {
                header: "Type",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{initialCap(data.cell.row.original.type!)}</div>,
            }),
            columnHelper.accessor("updated", {
                header: "Updated Date",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(data.cell.row.original.updated.toSQLDate())}
                    </div>
                ),
            })
        );
        if (includeNotes) {
            cols.push(
                columnHelper.accessor("noteToMerchant", {
                    header: "Note from DAVO",
                    cell: (data: any) => (
                        <span
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginLeft: "10px",
                                marginRight: "10px",
                            }}>
                            {data.cell.row.original.noteToMerchant && (
                                <Tooltip title={data.cell.row.original.noteToMerchant}>
                                    <span>
                                        <IconButton
                                            aria-label="Notes"
                                            onClick={() => {
                                                noop();
                                            }}
                                            size="large">
                                            {data.cell.row.original.noteToMerchant && <NoteAddIcon color="primary" />}
                                            {!data.cell.row.original.noteToMerchant && <NoteIcon color="primary" />}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </span>
                    ),
                })
            );
        }
        if (includeCancelButton) {
            cols.push(
                columnHelper.accessor("id", {
                    id: "cancel",
                    header: "",
                    enableSorting: false,
                    cell: (data) => {
                        if (!data.cell.row.original.status!.includes("closed")) {
                            return (
                                <div style={{ textAlign: "left" }}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => cancel(data.cell.row.original.id)}>
                                        Cancel
                                    </Button>
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    },
                })
            );
        }
        if (actionLabel && action) {
            cols.push(
                columnHelper.accessor("id", {
                    id: "actions",
                    header: "",
                    enableSorting: false,
                    cell: (data) => (
                        <div style={{ textAlign: "left" }}>
                            <Button onClick={() => action(data.cell.row.original.id)}>{actionLabel}</Button>
                        </div>
                    ),
                })
            );
        }
        return cols;
    }, [action, actionLabel, cancel, includeCancelButton, includeNotes]);

    if (!notices) {
        return null;
    }

    return (
        <>
            <div>
                <ReactTable8<INotice & { downloadReady: boolean }>
                    title={"Notices"}
                    columns={columns as ColumnDef<INotice & { downloadReady: boolean }>[]}
                    data={notices ?? []}
                    options={{
                        hideToolbar: false,
                    }}
                    renderRowSubComponent={getDetailPaneData}
                />
            </div>
        </>
    );
}
