import EditIcon from "@mui/icons-material/Edit";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

const useStyles = makeStyles({
    card: {
        minWidth: "500px",
        maxWidth: "500px",
        minHeight: "225px",
        margin: "5px",
    },
});

export function AccountDetailCard({
    title,
    subheader,
    content,
    editingContent,
}: {
    title?: any;
    subheader?: any;
    content: any;
    editingContent?: any;
}) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                subheader={subheader}
                action={
                    editingContent ? (
                        <IconButton aria-label="edit" onClick={() => setIsEditing(!isEditing)} size="large">
                            <EditIcon />
                        </IconButton>
                    ) : (
                        <div />
                    )
                }
            />
            {isEditing ? (
                <CardContent data-testid={"content"}>{editingContent}</CardContent>
            ) : (
                <CardContent data-testid={"content"}>{content}</CardContent>
            )}
        </Card>
    );
}
