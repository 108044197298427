import { IUseTax, IUseTaxAndUser, LocationRecord, moneyFromCents } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { ReactTable8 } from "./ReactTable8";
import { calculateAmountTotal } from "./util";

export type IUseTaxAndMore = IUseTax & {
    locationName: string;
    city: string | null | undefined;
    createdBy: string;
    date: string;
};

export function UseTaxTable({
    useTax,
    filterLocations,
}: {
    useTax?: IUseTaxAndUser[];
    filterLocations?: LocationRecord[];
}) {
    const [canExpandUse, setCanExpandUse] = useState<boolean>(true);
    const [useRemapped, setUseRemapped] = useState<IUseTaxAndMore[] | undefined>(undefined);

    useEffect(() => {
        if (useTax && useTax.length > 0 && filterLocations) {
            const mapLocationName = (val: IUseTaxAndUser[]): IUseTaxAndMore[] => {
                return val.map((tax) => {
                    const location = filterLocations.find((l) => l.id === tax.locationId);
                    const obj: IUseTaxAndMore = {
                        ...tax,
                        locationName: location?.name ?? "",
                        city: location?.city,
                        createdBy: `${tax.user.firstName} ${tax.user.lastName}`,
                        date: DateTime.fromISO(tax.bizday.toString()).toFormat("MMMM"),
                    };
                    return obj;
                });
            };

            setUseRemapped(mapLocationName(useTax));
        }
    }, [useTax, filterLocations]);

    const columnHelper = createColumnHelper<IUseTaxAndMore>();

    const columns = useMemo(() => {
        return [
            columnHelper.accessor("locationName", {
                header: "Location",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>
                        {data.cell.row.original.locationName} - {data.cell.row.original.city}
                    </div>
                ),
            }),
            columnHelper.accessor("createdBy", {
                header: "Updated By",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{data.cell.row.original.createdBy}</div>,
            }),
            columnHelper.accessor("bizday", {
                header: "Period",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{data.cell.row.original.date}</div>,
            }),
            columnHelper.accessor("amount", {
                header: "Total",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>{moneyFromCents(data.cell.row.original.amount)}</div>
                ),
            }),
        ];
    }, []);

    if (!useRemapped || useRemapped.length === 0) {
        return null;
    }
    return (
        <Accordion
            slotProps={{ transition: { unmountOnExit: true } }}
            style={{ border: "none", boxShadow: "none" }}
            expanded={canExpandUse}>
            <AccordionSummary
                onClick={() => setCanExpandUse(!canExpandUse)}
                expandIcon={<ExpandMoreIcon />}
                style={{ fontSize: "20px" }}>
                Use Tax Amounts
            </AccordionSummary>

            <AccordionDetails>
                <ReactTable8<IUseTaxAndMore>
                    columns={columns as ColumnDef<IUseTaxAndMore>[]}
                    data={useRemapped ?? []}
                    options={{
                        hideToolbar: true,
                    }}
                />
            </AccordionDetails>
            <div>
                <div style={{ textAlign: "right", fontWeight: "bold" }}>
                    <span>Total: ${moneyFromCents(calculateAmountTotal(useRemapped))}</span>
                </div>
            </div>
        </Accordion>
    );
}
