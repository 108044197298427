import { LinkButton, Loading, portalUrl, ReactTable, StatusDot, useLoginContext } from "@davo/portal-common";
import { AccountSummary } from "@davo/types";
import { Button } from "@mui/material";
import React, { useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getAllPartnerAccountSummariesForUser } from "./services";

export function AccountsFilter() {
    const loginContext = useLoginContext();

    const [accountSummaries, setAccountSummaries] = useState<AccountSummary[] | undefined>(undefined);

    useAsyncEffect(async () => {
        if (!loginContext.user) {
            return;
        }
        setAccountSummaries(undefined);
        setAccountSummaries(await getAllPartnerAccountSummariesForUser(loginContext.user.id));
    }, [loginContext.user]);

    const renderStatus = (data: AccountSummary) => {
        return <StatusDot level={data.minimumLocationStatus} message={data.minimumLocationStatusMessage} />;
    };

    const renderName = (data: AccountSummary) => {
        return (
            <div style={{ float: "left" }}>
                <LinkButton
                    url={`/accounts/${data.id}`}
                    style={{ textAlign: "left", color: "#78a0cd" }}
                    label={data.name}
                    labelMaxChars={35}
                />
            </div>
        );
    };

    if (!accountSummaries && !loginContext.user) {
        return <Loading />;
    }

    return (
        <div style={{ marginLeft: "5px", width: "100%" }}>
            <ReactTable
                columns={[
                    {
                        Header: "Name",
                        accessor: "name",
                        Cell: (data: any) => {
                            return renderName(data.cell.row.original);
                        },
                    },
                    {
                        Header: "Portal",
                        accessor: "portal",
                        Cell: (data: any) => (
                            <Button
                                size="small"
                                variant="outlined"
                                style={{ color: "#78a0cd", border: "1px solid #78a0cd" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(`${portalUrl}/${data.cell.row.original.id}`, "_blank");
                                }}>
                                Portal
                            </Button>
                        ),
                    },
                    {
                        Header: "Status",
                        accessor: "minimumLocationStatus",
                        Cell: (data: any) => {
                            return renderStatus(data.cell.row.original);
                        },
                    },
                ]}
                data={accountSummaries ?? []}
                options={{
                    pageSize: 10,
                    headerRowStyle: { color: "primary" },
                }}
            />
        </div>
    );
}
