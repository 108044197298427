import {
    dateReviver,
    fromJSON,
    IObjectMeta,
    MissingError,
    UnauthorizedRequestError,
    ValidationError,
} from "@davo/types";
import qs from "query-string";
import { apiUrl } from "../util";
import { auth } from "./auth";

type Method = "PUT" | "POST" | "GET" | "DELETE" | "OPTIONS" | "PATCH";

async function doMethod<T>({
    method,
    path,
    requiresAuthentication,
    body: rawBody,
    params,
    retry = 3,
    headers = {},
    abortController,
    allowRedirectOnLogout,
}: {
    method: Method;
    path: string;
    requiresAuthentication: boolean;
    params?: any;
    body?: any;
    retry?: number;
    headers?: any;
    abortController?: AbortController;
    allowRedirectOnLogout?: boolean;
}) {
    let error;

    const url = `${apiUrl}/${path}${params ? "?" + qs.stringify(params) : ""}`;
    if (process.env.NODE_ENV !== "production") {
        console.debug(`Calling: ${method} ${url}`); // eslint-disable-line no-console
    }

    headers["content-type"] = headers["content-type"] || "application/json";
    const body = rawBody
        ? headers["content-type"] === "application/json"
            ? JSON.stringify(rawBody || "")
            : rawBody
        : undefined;
    while (retry-- > 0) {
        let result;
        try {
            result = await fetch(url, {
                method,
                headers: {
                    ...headers,
                },
                body,
                credentials: requiresAuthentication ? "include" : "omit",
                signal: abortController?.signal,
            });
        } catch (e: any) {
            // "failed to fetch" falls in here, letting it and other internet weather retry...
            error = e;
            continue;
        }
        if (result.ok) {
            if (result.status === 204) {
                return undefined as T;
            }
            try {
                const t = await result.text();
                return JSON.parse(t, dateReviver) as T;
            } catch (e: any) {
                throw new Error(`Error parsing result: ${method}; ${path}; ${e.message}`);
            }
            /***********
             * Any new error type thrown here should also be added to the lists in getArray and getOne
             ***********/
        } else if (result.status === 401) {
            await auth.logout().finally(() => {
                auth.logoutRedirect(allowRedirectOnLogout ?? true);
            });
            const message = (await result.json())?.message ?? (await result.text());
            throw new UnauthorizedRequestError(`Authorization Error: ${message}`);
        } else if (result.status === 404) {
            const message = (await result.json())?.message;
            throw new MissingError(message);
        } else if (result.status.toString().startsWith("4")) {
            const message = (await result.json())?.message ?? (await result.text());
            throw new ValidationError(message);
        } else {
            error = `status: ${result.status} result: ${await result.text()}`;
        }
    }
    throw new Error(`Error resolving path: ${method}; ${path}; ${error?.message}`);
}

export async function del<T = void>(
    path: string,
    requiresAuthentication: boolean,
    params?: any,
    retry = 1,
    allowRedirectOnLogout = true
) {
    return doMethod<T>({
        method: "DELETE",
        path,
        requiresAuthentication,
        params,
        retry,
        allowRedirectOnLogout,
    });
}

export async function get<T = any>(
    path: string,
    requiresAuthentication: boolean,
    params?: any,
    abortController?: AbortController,
    allowRedirectOnLogout = true
) {
    return doMethod<T>({
        method: "GET",
        path,
        requiresAuthentication,
        params,
        abortController,
        allowRedirectOnLogout,
    });
}

export async function patch<T = any>(
    path: string,
    body: any,
    requiresAuthentication: boolean,
    headers?: any,
    abortController?: AbortController,
    retry = 1,
    allowRedirectOnLogout = true
) {
    return doMethod<T>({
        method: "PATCH",
        path,
        body,
        requiresAuthentication,
        headers,
        abortController,
        retry,
        allowRedirectOnLogout,
    });
}

export async function post<T = any>(
    path: string,
    body: any,
    requiresAuthentication: boolean,
    headers?: any,
    abortController?: AbortController,
    retry = 1,
    allowRedirectOnLogout = true
) {
    return doMethod<T>({
        method: "POST",
        path,
        body,
        requiresAuthentication,
        headers,
        abortController,
        retry,
        allowRedirectOnLogout,
    });
}

export async function put<T = any>(
    path: string,
    body: any,
    requiresAuthentication: boolean,
    headers?: any,
    retry = 1,
    allowRedirectOnLogout = true
) {
    return doMethod<T>({
        method: "PUT",
        path,
        body,
        requiresAuthentication,
        headers,
        retry,
        allowRedirectOnLogout,
    });
}

export async function getArray<T extends IObjectMeta>(
    path: string,
    requiresAuthentication: boolean,
    meta: T,
    abortController?: AbortController,
    allowRedirectOnLogout = true
) {
    try {
        return (await get<T[]>(path, requiresAuthentication, undefined, abortController, allowRedirectOnLogout)).map(
            (x) => fromJSON(meta, x)
        );
    } catch (e: any) {
        if (e instanceof UnauthorizedRequestError || e instanceof ValidationError || e instanceof MissingError) {
            throw e;
        }
        throw new Error(`(array); ${e.message}`);
    }
}

export async function getOne<T extends IObjectMeta>(
    path: string,
    requiresAuthentication: boolean,
    meta: T,
    abortController?: AbortController,
    allowRedirectOnLogout = true
) {
    try {
        return fromJSON(
            meta,
            await get<any>(path, requiresAuthentication, undefined, abortController, allowRedirectOnLogout)
        );
    } catch (e: any) {
        if (e instanceof UnauthorizedRequestError || e instanceof ValidationError || e instanceof MissingError) {
            throw e;
        }
        throw new Error(`(one); ${e.message}`);
    }
}
