export interface IDashboardSubscriptionsTotal {
    totalSubs: number;
    totalSubsWithTp: number;
    todaySubs: number;
    bank: number;
    tp: number;
    disconnects: number;
    quickDisconnects: number;
}

export interface IDashboardCleanUpTotal {
    awaitingDisconnect: number;
    cloverLapsed: number;
    noAccountClover: number;
    noBankClover: number;
    noTp: number;
    bankPaused: number;
    tpFailing: number;
}

export interface IDashboardSubscriptionsByPos {
    posType: string;
    totalSubs: number;
    todaySubs: number;
    bank: number;
    tp: number;
    fromDevice: number;
    disconnects: number;
    quickDisconnects: number;
}

export interface IDashboardFilingsByUser {
    user: string;
    filed: number;
    automated: number;
    manual: number;
}

export interface IDashboardFilingsByUserByDay extends IDashboardFilingsByUser {
    day: string;
}

export interface IDashboardFilingsTotal {
    filingDueDate: string;
    unfiled: number;
    filed: number;
    automated: number;
    manual: number;
}

export interface IDashboardFilingsByDueDate extends IDashboardFilingsTotal {
    jurisdiction: string;
}

export interface IBoardingMonthly {
    month: string;
    boardings: number;
    self: number;
    unassigned: number;
    assigned: number;
    helped: number;
}

export interface IBoardingUsers {
    assignee: string;
    assigned: number;
    helped: number;
}
