import { BusinessDay, IUseTaxAndUser } from "@davo/types";
import { post } from "./api";

export const getUseTaxAmounts = async (locationIds: string[], from: BusinessDay, to: BusinessDay) => {
    return post<IUseTaxAndUser[]>(`api/common/account/use-tax`, { from, to, locationIds }, true);
};

export async function addUseTax(accountId: string, locationId: string, bizday: BusinessDay, amount: number) {
    return post(`api/common/account/${accountId}/locations/${locationId}/use-tax/add`, { amount, bizday }, true);
}
