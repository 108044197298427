import { AccountSearchRecord, BusinessDay, LocationRecord, percent, RelatedLocation } from "@davo/types";
import { DateTime } from "luxon";

export const hasPermission = (userPermissions: string[] | undefined, neededPermission: string) => {
    return !!userPermissions?.includes(neededPermission);
};

export const getMonths = (filingMonth?: BusinessDay) => {
    if (!filingMonth) {
        const now = DateTime.local();
        return now.day >= 1 && now.day <= 4
            ? [now.minus({ month: 1 }).toFormat(monthFormat), now.toFormat(monthFormat)]
            : [now.toFormat(monthFormat)];
    } else {
        return [filingMonth.toDateTime().toFormat(monthFormat)];
    }
};

export const monthFormat = "yyyy-MM";

export const validateAmount = (amt: number | undefined) => {
    if (amt && amt < 0) {
        return `Amount cannot be negative`;
    }
    return undefined;
};

export const validateNonNullAndPositive = (value?: number) => {
    if (value === 0) {
        return undefined;
    }
    return value ? validateAmount(value) : "Field required";
};

export const calculateAmountTotal = (val: any[]) => {
    if (val.length > 0) {
        return val.map((item) => item.amount).reduce((prev, next) => Number(prev) + Number(next));
    }
    return undefined;
};

export const calculateSalesTotal = (val: any[]) => {
    if (val.length > 0) {
        return val.map((item) => item.sales).reduce((prev, next) => Number(prev) + Number(next));
    }
    return [];
};

export function renderRate(data: number) {
    return data ? percent(data / 1e7, 3) : "Not Provided";
}

export const monthYear = (period: string) => {
    const month = DateTime.fromFormat(period, monthFormat);
    return `${month.monthLong} ${month.year}`;
};

export const getLocationLabel = (l: LocationRecord) => {
    if (l) {
        return `${l.name} - ${l.address1 ?? ""} ${l.city ?? ""}${!l.active ? " - Inactive" : ""}`;
    } else {
        return "";
    }
};

export function debounce(this: any, func: (...args: any[]) => any, wait: number) {
    let timeout: ReturnType<typeof window.setTimeout> | number;
    return (...args: any[]) => {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => func.apply(this, args), wait);
    };
}

export const formatAccountSearchResults = (x: AccountSearchRecord) => {
    let res = x.name;
    if (x.externalLocationId) {
        if (x.externalLocationId.length > 15) {
            res = `${res} [${x.externalLocationId.substring(0, 14)}...]`;
        } else {
            res = `${res} [${x.externalLocationId}]`;
        }
    }
    if (!x.hasActiveLocations) {
        res = `${res} (no active locations)`;
    }
    return res;
};

export const MIN_SEARCH_CHAR_THRESHOLD = 2;
export const DEFAULT_SEARCH_DELAY = 500;
export const ORPHAN_RELATED_ACCOUNT_ID = "NO-ACCOUNT";
export const ORPHAN_RELATED_ACCOUNT_NAME = "NO ACCOUNT";
export type RelatedAccountListItem = {
    id: string;
    name: string;
    locs: RelatedLocation[];
};
export type PartialAccount = { id: string; name: string; [key: string]: unknown };

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _trim = (s: string | null | undefined) => (s ? s.trim() : "");
