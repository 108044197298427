import {
    EfileCheckData,
    FilingFrequency,
    FilingFrequencyMap,
    getTaxProfileFailureReasonDescription,
    IJurisdiction,
    IJurisdictionFilingType,
    IJurisdictionUIField,
    IJurisdictionUIFieldDefinitionBase,
    ITaxProfileFormData,
    LinkedTaxProfileType,
    LoginCredentialValidationStatus,
    OptionalString,
    PLACEHOLDER_PREFIX,
    PLACEHOLDER_VALUES,
    TaxPeriod,
    TaxProfile,
    TaxProfileCredentials,
    TaxProfileFilingMethod,
    TaxProfileWithCredentials,
} from "@davo/types";
import BlockIcon from "@mui/icons-material/Block";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import {
    AdditionalMetadata,
    AdditionalMetadata2,
    AdditionalMetadata3,
    BypassDetailsToggle,
    FEIN,
    FilingFrequency as FilingFrequencyDropdown,
    FORM_ERROR_LABELS,
    FORM_FIELD_LABELS,
    getReasonInvalid,
    HeaderContent,
    JurisdictionDropdown,
    LegalName,
    StateTaxId,
    TaxProfileName,
    validateFein,
    validateFilingFrequency,
    validateFilingType,
    validateLegalName,
    validateRequiredWithRegex,
    validateTpName,
} from ".";
import { TaxProfileFormContext, useCommonPortalConfigContext, useLoginContext } from "../context";
import { CredentialsEntry } from "../CredentialsEntry";
import { Select } from "../Select";
import {
    _trim,
    calculateFilingPeriod,
    getCheckEFileCredentials,
    getFilingFrequencyByJurisdiction,
    getGroupLoginRequired,
    getJurisdictionByStateCode,
    getJurisdictionFilingType,
    getJurisdictionFilingTypes,
    getJurisdictions,
    getTaxProfile,
    getTaxProfileAndCredentials,
    getTaxProfiles,
} from "../services";
import { TakeGiveBackToggle } from "../TakeGiveBackToggle";
import { d30ToastError } from "../Toast";
import { getCredentialsStatus, hasPermission } from "../util";
import "./TaxProfileForm.css";

export interface ITaxProfileForm {
    accountId?: string;
    target?: string; // taxProfileId passed generically from modal widget
    onSubmit: (
        data: ITaxProfileFormData,
        newTaxProfileData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy">
    ) => Promise<void>;
    saveLabel?: string;
    showCancel?: boolean;
    onCancel?: (data: ITaxProfileFormData) => void;
    headerContent?: any;
    shouldLimitEdit?: boolean;
    value?: ITaxProfileFormData;
    parentErrorMessage?: string;
    linked?: LinkedTaxProfileType;
}

export const TaxProfileForm: FunctionComponent<ITaxProfileForm> = ({
    accountId,
    target,
    onSubmit,
    saveLabel,
    showCancel,
    onCancel,
    headerContent,
    shouldLimitEdit,
    value,
    parentErrorMessage,
    linked,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const { commonPortalConfigInfo: configInfo } = useCommonPortalConfigContext();
    const loginContext = useLoginContext();

    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [filingTypesForState, setFilingTypesForState] = useState<IJurisdictionFilingType[]>([]);
    const [filingMethodForData, setFilingMethodForData] = useState<TaxProfileFilingMethod | undefined | null>();

    const [name, setName] = useState<string>("");
    const [credentials, setCredentials] = useState<TaxProfileCredentials>({});
    const [stateTaxId, setStateTaxId] = useState<string>("");
    const [federalTaxId, setFederalTaxId] = useState<string>("");
    const [additionalStateMetadata, setAdditionalStateMetadata] = useState<string>("");
    const [additionalStateMetadata2, setAdditionalStateMetadata2] = useState<string>("");
    const [additionalStateMetadata3, setAdditionalStateMetadata3] = useState<string>("");
    const [frequency, setFrequency] = useState<OptionalString>();
    const [legalName, setLegalName] = useState<string>("");

    const [invalidStandardFields, setInvalidStandardFields] = useState<string[]>([]);
    const [areAllStandardFieldsValid, setAreAllStandardFieldsValid] = useState<boolean>(false);
    const [invalidCredentialFields, setInvalidCredentialFields] = useState<string[]>([]);
    const [areAllCredentialFieldsValid, setAreAllCredentialFieldsValid] = useState<boolean>(false);
    const [credsValidationStatus, setCredsValidationStatus] = useState<LoginCredentialValidationStatus>();
    const [isNicknameInUse, setIsNicknameInUse] = useState<boolean>(false);

    const [tpNameValidationResults, setTpNameValidationResults] = useState<string>();
    const [usernameValidationResults, setUsernameValidationResults] = useState<string>();
    const [passwordValidationResults, setPasswordValidationResults] = useState<string>();
    const [pinValidationResults, setPinValidationResults] = useState<string>();
    const [stateTaxIdValidationResults, setStateTaxIdValidationResults] = useState<string>();
    const [filingFrequencyValidationResults, setFilingFrequencyValidationResults] = useState<string>();
    const [legalNameValidationResults, setLegalNameValidationResults] = useState<string>();
    const [additionalMetadataValidationResults, setAdditionalMetadataValidationResults] = useState<string>();
    const [additionalMetadata2ValidationResults, setAdditionalMetadata2ValidationResults] = useState<string>();
    const [additionalMetadata3ValidationResults, setAdditionalMetadata3ValidationResults] = useState<string>();
    const [federalTaxIdValidationResults, setFederalTaxIdValidationResults] = useState<string>();
    const [filingTypeValidationResults, setFilingTypeValidationResults] = useState<string>();
    const [isCredsValidationRequired, setIsCredsValidationRequired] = useState<boolean>(false);

    const [message, setMessage] = useState<string | undefined>(undefined);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const submitAttemptCounter = useRef(0);
    const formRef = useRef<HTMLFormElement>();
    const [formLevelValidationMsg, setFormLevelValidationMsg] = useState<string | undefined>(undefined);

    const [jurisdiction, setJurisdiction] = useState<IJurisdiction | undefined | null>();
    const [filingType, setFilingType] = useState<IJurisdictionFilingType>();
    const [legalNameFieldDefinition, setLegalNameFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [stateTaxIdFieldDefinition, setStateTaxIdFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [feinFieldDefinition, setFeinFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [usernameFieldDefinition, setUsernameFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [passwordFieldDefinition, setPasswordFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [pinFieldDefinition, setPinFieldDefinition] = useState<IJurisdictionUIFieldDefinitionBase>();
    const [additionalStateMetadataFieldDefinition, setAdditionalStateMetadataFieldDefinition] =
        useState<IJurisdictionUIFieldDefinitionBase>();
    const [additionalStateMetadata2FieldDefinition, setAdditionalStateMetadata2FieldDefinition] =
        useState<IJurisdictionUIFieldDefinitionBase>();
    const [additionalStateMetadata3FieldDefinition, setAdditionalStateMetadata3FieldDefinition] =
        useState<IJurisdictionUIFieldDefinitionBase>();

    const [isUsernameRequired, setIsUsernameRequired] = useState<boolean>(false);
    const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);
    const [isPinRequired, setIsPinRequired] = useState<boolean>(false);
    const [selectedFlavor, setSelectedFlavor] = useState<IJurisdictionUIField>();
    const [jurisdictionFilingTypeId, setJurisdictionFilingTypeId] = useState<string | null>();
    const [previouslySavedCredentials, setPreviouslySavedCredentials] = useState<TaxProfileCredentials>();
    const [selectedFrequencyList, setSelectedFrequencyList] = useState<string[]>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isCheckingCreds, setIsCheckingCreds] = useState<boolean>(false);
    const [taxProfile, setTaxProfile] = useState<TaxProfile | undefined>(undefined);
    const [allTaxProfiles, setAllTaxProfiles] = useState<TaxProfile[]>([]);

    const [areExistingCredentialsReused, setAreExistingCredentialsReused] = useState<boolean>(false);
    const [showFrequencyConfirm, setShowFrequencyConfirm] = useState<boolean>(false);
    const [linkedTaxProfileQuestionAnswer, setLinkedTaxProfileQuestionAnswer] = useState<string>("");
    const [showLinkedTaxProfileDetails, setShowLinkedTaxProfileDetails] = useState<boolean>(false);
    const [doesFrequencyChangeAffectsCurrentPeriod, setDoesFrequencyChangeAffectsCurrentPeriod] =
        useState<boolean>(false);
    const [oldFrequency, setOldFrequency] = useState<OptionalString>(undefined);
    const [hasFailingCredentials, setHasFailingCredentials] = useState<boolean>(false);
    const [failingCredentialsMessage, setFailingCredentialsMessage] = useState<string>("");
    const [isGroupLoginUsed, setIsGroupLoginUsed] = useState<boolean>(false);
    const [isGroupLoginRequired, setIsGroupLoginRequired] = useState<boolean>(false);
    const [isFilingTypeRequired, setIsFilingTypeRequired] = useState<boolean>(false);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isTakeGiveBack, setIsTakeGiveBack] = useState<boolean>(false);
    const [frequencyData, setFrequencyData] = useState<TaxPeriod | undefined>(undefined);

    const [shouldBypassDetails, setShouldBypassDetails] = useState<boolean>(false);
    const [isDraftForMerchant, setIsDraftForMerchant] = useState<boolean>(false);
    const [parentTaxProfile, setParentTaxProfile] = useState<TaxProfile>();

    const stateTaxIdInputRef = useRef<HTMLInputElement>();
    const controllerRef = useRef<AbortController>();
    const [legalNameLabel, setLegalNameLabel] = useState<string>("");
    const [taxIdLabel, setTaxIdLabel] = useState<string>("");

    const [frequencies, setFrequencies] = useState<FilingFrequencyMap>();
    const [hasBaseAccessPerms, setHasBaseAccessPerms] = useState<boolean>(false);

    const handleCredentialFlavorChange = useCallback((flavor: IJurisdictionUIField) => {
        const usernameField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "USERNAME"
        );
        const passwordField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "PASSWORD"
        );
        const pinField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "PIN"
        );

        const legalNameField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "LEGAL_NAME"
        );
        const stateTaxIdField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "STATE_TAX_ID"
        );
        const feinField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "FEIN"
        );

        const additionalStateMetadataField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) => def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA"
        );
        const additionalStateMetadataTwoField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) =>
                def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA_TWO"
        );
        const additionalStateMetadataThreeField = flavor.definitions.find(
            (def: IJurisdictionUIFieldDefinitionBase) =>
                def.jurisdictionFieldType.tag === "ADDITIONAL_STATE_METADATA_THREE"
        );

        setSelectedFlavor(flavor);

        setIsUsernameRequired(!!usernameField?.isVisible);
        setIsPasswordRequired(!!passwordField?.isVisible);
        setIsPinRequired(!!pinField?.isVisible);

        setUsernameFieldDefinition(usernameField);
        setPasswordFieldDefinition(passwordField);
        setPinFieldDefinition(pinField);

        setLegalNameLabel(legalNameField?.label ?? "");
        setTaxIdLabel(stateTaxIdField?.label ?? "");
        setLegalNameFieldDefinition(legalNameField);
        setStateTaxIdFieldDefinition(stateTaxIdField);
        setFeinFieldDefinition(feinField);

        setAdditionalStateMetadataFieldDefinition(additionalStateMetadataField);
        setAdditionalStateMetadata2FieldDefinition(additionalStateMetadataTwoField);
        setAdditionalStateMetadata3FieldDefinition(additionalStateMetadataThreeField);
    }, []);

    const getAndSetJurisdictionByStateCode = useCallback((stateCode: string) => {
        getJurisdictionByStateCode(stateCode)
            .then((j) => {
                setJurisdiction(j);
            })
            .catch((e) => d30ToastError("Problem getting jurisdiction by state code", e));
    }, []);

    const _setLinkedTpDefaultValues = useCallback(() => {
        if (linked && parentTaxProfile) {
            const linkedTpConfig = linked.jurisdictionUIAdditionalTaxProfile;
            if (linkedTpConfig.copyLegalName) {
                setLegalName(parentTaxProfile.legalName);
            }
            if (linkedTpConfig.copyStateTaxId) {
                parentTaxProfile.stateTaxId && setStateTaxId(parentTaxProfile.stateTaxId);
            }
            if (linkedTpConfig.copyFederalTaxId) {
                parentTaxProfile.federalTaxId && setFederalTaxId(parentTaxProfile.federalTaxId);
            }
            if (linkedTpConfig.copyFrequency) {
                setFrequency(parentTaxProfile.frequency);
            }
            if (linkedTpConfig.copyAdditionalStateMetadata) {
                parentTaxProfile.additionalStateMetadata &&
                    setAdditionalStateMetadata(parentTaxProfile.additionalStateMetadata);
            }
            if (linkedTpConfig.copyAdditionalStateMetadata2) {
                parentTaxProfile.additionalStateMetadata2 &&
                    setAdditionalStateMetadata2(parentTaxProfile.additionalStateMetadata2);
            }
            if (linkedTpConfig.copyAdditionalStateMetadata3) {
                parentTaxProfile.additionalStateMetadata3 &&
                    setAdditionalStateMetadata3(parentTaxProfile.additionalStateMetadata3);
            }
        }
    }, [linked, parentTaxProfile]);

    const _clearFormValues = useCallback(() => {
        setCredsValidationStatus(undefined);
        setIsTakeGiveBack(false);
        setCredentials({});
        setName("");
        setStateTaxId("");
        setFrequency(undefined);
        setLegalName("");
        setFederalTaxId("");
        setAdditionalStateMetadata("");
        setAdditionalStateMetadata2("");
        setAdditionalStateMetadata3("");
    }, []);

    const _setPlaceholderValues = useCallback(() => {
        setIsTakeGiveBack(false);
        setCredentials({});
        setName(`${PLACEHOLDER_PREFIX}: ${filingType?.name}`);
        setStateTaxId(PLACEHOLDER_VALUES.STATE_TAX_ID);
        setFrequency(PLACEHOLDER_VALUES.FILING_FREQUENCY);
        setLegalName(PLACEHOLDER_VALUES.LEGAL_NAME);
        setFederalTaxId(PLACEHOLDER_VALUES.FEIN);
        setAdditionalStateMetadata(PLACEHOLDER_VALUES.ADDITIONAL_METADATA_1);
        setAdditionalStateMetadata2(PLACEHOLDER_VALUES.ADDITIONAL_METADATA_2);
        setAdditionalStateMetadata3(PLACEHOLDER_VALUES.ADDITIONAL_METADATA_3);
    }, [filingType]);

    const abortCredsCheck = useCallback(() => {
        controllerRef.current && controllerRef.current.abort("Expectedly bailing on creds check.");
    }, []);

    const _changeState = useCallback(
        (newState: IJurisdiction | undefined) => {
            if (newState) {
                abortCredsCheck();
                setJurisdiction(newState);
                setIsGroupLoginUsed(false);
                setShouldBypassDetails(false);
            }
        },
        [abortCredsCheck]
    );

    useEffect(() => {
        getJurisdictions()
            .then((result) => setStates(result))
            .catch((e) => {
                d30ToastError("Problem getting jurisdictions", e);
            });
    }, []);

    useEffect(() => {
        if (jurisdiction) {
            // NOTE: frequencies are for the state, regardless of subtype
            getFilingFrequencyByJurisdiction(jurisdiction.abbreviatedName)
                .then((f) => setFrequencies(f))
                .catch((e) => d30ToastError("Problem getting filing frequency by jurisdiction", e));
        }
    }, [jurisdiction]);

    useEffect(() => {
        if (jurisdiction && !linked) {
            getJurisdictionFilingTypes(jurisdiction.id)
                .then((filingTypes) => {
                    const activeFilingTypes = filingTypes.filter((ft) => ft.active);
                    setFilingTypesForState(activeFilingTypes);
                })
                .catch((e) => d30ToastError("Problem getting jurisdiction filing types", e));
        }
    }, [jurisdiction, linked]);

    useEffect(() => {
        if (!taxProfile?.jurisdictionFilingTypeId && filingTypesForState.length) {
            const defaultFilingType = filingTypesForState.find((juris) => juris.isDefault);
            setFilingType(defaultFilingType);
            setJurisdictionFilingTypeId(defaultFilingType?.id);
        }
    }, [filingTypesForState, taxProfile]);

    useEffect(() => {
        if (filingType) {
            let fieldDefinition = filingType.fields[0];

            if (taxProfile && !isEmpty(filingType.fields) && !isNil(taxProfile.jurisdictionUIFlavorGroupId)) {
                const match = filingType.fields.find(
                    (field: IJurisdictionUIField) =>
                        field.jurisdictionFlavorGroupId === taxProfile.jurisdictionUIFlavorGroupId
                );

                fieldDefinition = match ? match : fieldDefinition;
            }
            handleCredentialFlavorChange(fieldDefinition);
        }
    }, [filingType, taxProfile, handleCredentialFlavorChange]);

    useEffect(() => {
        if (jurisdiction) {
            // NOTE: we can reuse credentials so long as we're editing and the state hasn't been changed
            const hasStateChanged = taxProfile !== undefined && taxProfile.state !== jurisdiction.abbreviatedName;
            if (hasStateChanged) {
                setAreExistingCredentialsReused(false);
            }
        }

        if (jurisdiction && taxProfile && taxProfile.frequency) {
            calculateFilingPeriod(jurisdiction.abbreviatedName, taxProfile.frequency)
                .then((data) => {
                    setFrequencyData(data);
                })
                .catch((e) => d30ToastError("Problem calculating filing period.", e));
        }
    }, [jurisdiction, taxProfile]);

    useEffect(() => {
        setIsFilingTypeRequired(hasBaseAccessPerms && filingTypesForState && filingTypesForState.length > 1);
    }, [loginContext.permissions, filingTypesForState, hasBaseAccessPerms]);

    useEffect(() => {
        const hasRequiredConfig =
            !isGroupLoginUsed &&
            !isTakeGiveBack &&
            !shouldBypassDetails &&
            !!filingType &&
            ((configInfo.credsCheck.skyScraperEnabled && filingType.skyscraperCheckCreds) ||
                (configInfo.credsCheck.davoEnabled && filingType.davoCheckCreds));
        setIsCredsValidationRequired(hasRequiredConfig);
    }, [configInfo, filingType, isTakeGiveBack, shouldBypassDetails, isGroupLoginUsed]);

    useEffect(() => {
        const failedFields: string[] = [];

        let stateTaxIdValidation = undefined;
        let legalNameValidation = undefined;
        let additionalStateMetadataValidation = undefined;
        let additionalStateMetadata2Validation = undefined;
        let additionalStateMetadata3Validation = undefined;
        let feinValidation = undefined;
        let filingTypeValidation = undefined;

        const tpNameValidation = filingType ? validateTpName(_trim(name), isNicknameInUse) : undefined;
        tpNameValidation && failedFields.push(FORM_FIELD_LABELS.taxProfileName);
        setTpNameValidationResults(tpNameValidation);

        if (stateTaxIdFieldDefinition && !isTakeGiveBack) {
            stateTaxIdValidation = validateRequiredWithRegex(
                _trim(stateTaxId),
                stateTaxIdFieldDefinition.isVisible,
                taxIdLabel,
                stateTaxIdFieldDefinition.validationRegex
            );
            stateTaxIdValidation && failedFields.push(stateTaxIdFieldDefinition.label);
            setStateTaxIdValidationResults(stateTaxIdValidation);
        }

        if (additionalStateMetadataFieldDefinition && !isTakeGiveBack) {
            additionalStateMetadataValidation = validateRequiredWithRegex(
                _trim(additionalStateMetadata),
                additionalStateMetadataFieldDefinition.isVisible,
                additionalStateMetadataFieldDefinition.label,
                additionalStateMetadataFieldDefinition.validationRegex
            );
            additionalStateMetadataValidation && failedFields.push(additionalStateMetadataFieldDefinition.label);
            setAdditionalMetadataValidationResults(additionalStateMetadataValidation);
        }

        if (additionalStateMetadata2FieldDefinition && !isTakeGiveBack) {
            additionalStateMetadata2Validation = validateRequiredWithRegex(
                _trim(additionalStateMetadata2),
                additionalStateMetadata2FieldDefinition.isVisible,
                additionalStateMetadata2FieldDefinition.label,
                additionalStateMetadata2FieldDefinition.validationRegex
            );
            additionalStateMetadata2Validation && failedFields.push(additionalStateMetadata2FieldDefinition.label);
            setAdditionalMetadata2ValidationResults(additionalStateMetadata2Validation);
        }

        if (additionalStateMetadata3FieldDefinition && !isTakeGiveBack) {
            additionalStateMetadata3Validation = validateRequiredWithRegex(
                _trim(additionalStateMetadata3),
                additionalStateMetadata3FieldDefinition.isVisible,
                additionalStateMetadata3FieldDefinition.label,
                additionalStateMetadata3FieldDefinition.validationRegex
            );
            additionalStateMetadata3Validation && failedFields.push(additionalStateMetadata3FieldDefinition.label);
            setAdditionalMetadata3ValidationResults(additionalStateMetadata3Validation);
        }

        const filingFrequencyValidation = filingType ? validateFilingFrequency(frequency, linked) : undefined;
        filingFrequencyValidation && failedFields.push(FORM_FIELD_LABELS.filingFrequency);
        setFilingFrequencyValidationResults(filingFrequencyValidation);

        if (legalNameFieldDefinition && !isTakeGiveBack && filingType) {
            legalNameValidation = validateLegalName(
                _trim(legalName),
                legalNameFieldDefinition.isVisible,
                legalNameLabel,
                legalNameFieldDefinition.validationRegex,
                linked
            );
            legalNameValidation && failedFields.push(legalNameLabel);
            setLegalNameValidationResults(legalNameValidation);
        }

        if (feinFieldDefinition && !isTakeGiveBack) {
            feinValidation = validateFein(
                _trim(federalTaxId),
                feinFieldDefinition.isVisible,
                feinFieldDefinition.label,
                feinFieldDefinition.validationRegex,
                linked
            );
            feinValidation && failedFields.push(feinFieldDefinition.label);
            setFederalTaxIdValidationResults(feinValidation);
        }

        filingTypeValidation = validateFilingType(filingType?.id, isFilingTypeRequired);
        filingTypeValidation && failedFields.push(FORM_FIELD_LABELS.filingType);
        setFilingTypeValidationResults(filingTypeValidation);

        const areStandardFieldsValid =
            !!linked && !showLinkedTaxProfileDetails
                ? true
                : isTakeGiveBack
                  ? !tpNameValidation && !filingFrequencyValidation
                  : shouldBypassDetails
                    ? !filingTypeValidation
                    : (!tpNameValidation &&
                          !legalNameValidation &&
                          !filingFrequencyValidation &&
                          !stateTaxIdValidation &&
                          !feinValidation &&
                          !additionalStateMetadataValidation &&
                          !additionalStateMetadata2Validation &&
                          !additionalStateMetadata3Validation &&
                          !filingTypeValidation) ||
                      linkedTaxProfileQuestionAnswer === "no";

        setAreAllStandardFieldsValid(areStandardFieldsValid);
        setInvalidStandardFields(areStandardFieldsValid ? [] : failedFields);
    }, [
        showLinkedTaxProfileDetails,
        isNicknameInUse,
        shouldBypassDetails,
        linked,
        legalNameFieldDefinition,
        feinFieldDefinition,
        stateTaxIdFieldDefinition,
        taxIdLabel,
        additionalStateMetadataFieldDefinition,
        additionalStateMetadata2FieldDefinition,
        additionalStateMetadata3FieldDefinition,
        isFilingTypeRequired,
        isTakeGiveBack,
        name,
        legalName,
        frequency,
        stateTaxId,
        federalTaxId,
        additionalStateMetadata,
        additionalStateMetadata2,
        additionalStateMetadata3,
        filingType,
        legalNameLabel,
        linkedTaxProfileQuestionAnswer,
    ]);

    useEffect(() => {
        const failedCredentials: string[] = [];

        let usernameValidation = undefined;
        let passwordValidation = undefined;
        let pinValidation = undefined;

        if (usernameFieldDefinition) {
            usernameValidation = validateRequiredWithRegex(
                credentials.username,
                isUsernameRequired,
                usernameFieldDefinition.label,
                usernameFieldDefinition.validationRegex
            );

            usernameValidation && failedCredentials.push(usernameFieldDefinition.label);
            setUsernameValidationResults(usernameValidation);
        }

        if (passwordFieldDefinition) {
            passwordValidation = validateRequiredWithRegex(
                credentials.password,
                isPasswordRequired,
                passwordFieldDefinition.label,
                passwordFieldDefinition.validationRegex
            );

            passwordValidation && failedCredentials.push(passwordFieldDefinition.label);
            setPasswordValidationResults(passwordValidation);
        }

        if (pinFieldDefinition) {
            pinValidation = validateRequiredWithRegex(
                credentials.pin,
                isPinRequired,
                pinFieldDefinition.label,
                pinFieldDefinition.validationRegex
            );

            pinValidation && failedCredentials.push(pinFieldDefinition.label);
            setPinValidationResults(pinValidation);
        }

        const areCredentialFieldsValid =
            isTakeGiveBack || isGroupLoginUsed || shouldBypassDetails || (linked && !showLinkedTaxProfileDetails)
                ? true
                : !usernameValidation && !passwordValidation && !pinValidation;

        setAreAllCredentialFieldsValid(areCredentialFieldsValid);
        setInvalidCredentialFields(areCredentialFieldsValid ? [] : failedCredentials);
    }, [
        shouldBypassDetails,
        linked,
        credentials,
        isGroupLoginUsed,
        isPasswordRequired,
        isPinRequired,
        isTakeGiveBack,
        isUsernameRequired,
        pinFieldDefinition,
        usernameFieldDefinition,
        passwordFieldDefinition,
        showLinkedTaxProfileDetails,
    ]);

    useEffect(() => {
        const reasonInvalidMsg = getReasonInvalid(
            areAllStandardFieldsValid,
            areAllCredentialFieldsValid,
            isCredsValidationRequired,
            credsValidationStatus
        );

        setFormLevelValidationMsg(reasonInvalidMsg);

        const isValid = areAllStandardFieldsValid && areAllCredentialFieldsValid && !reasonInvalidMsg;
        setIsFormValid(isValid);
    }, [credsValidationStatus, areAllStandardFieldsValid, areAllCredentialFieldsValid, isCredsValidationRequired]);

    useEffect(() => {
        const trimmedNickname = _trim(name);
        const isInUse =
            !!allTaxProfiles.length &&
            !!allTaxProfiles.find((profile) => _trim(profile.name).toLowerCase() === trimmedNickname.toLowerCase());
        const isSameName = name === taxProfile?.name;
        setIsNicknameInUse(!isSameName && isInUse);
    }, [allTaxProfiles, name, taxProfile]);

    useEffect(() => {
        if (parentErrorMessage) {
            setMessage(parentErrorMessage);
        } else {
            setMessage(undefined);
        }
    }, [parentErrorMessage]);

    useEffect(() => {
        if (accountId) {
            getTaxProfiles(accountId)
                .then((existingTaxProfiles) => setAllTaxProfiles(existingTaxProfiles))
                .catch((e) => d30ToastError("Problem getting tax profiles for account.", e));
        }
    }, [accountId]);

    useEffect(() => {
        if (accountId) {
            const tp = allTaxProfiles?.find((t) => t.id === target);
            if (tp && target) {
                getTaxProfileAndCredentials(accountId, tp.id)
                    .then((tpWithCreds) => {
                        setCredentials(tpWithCreds.credentials);
                        setPreviouslySavedCredentials(tpWithCreds.credentials);
                        setTaxProfile(tp);
                        setIsDraftForMerchant(tp.name?.startsWith(PLACEHOLDER_PREFIX));
                    })
                    .catch((e) => d30ToastError("Problem getting tax profile and credentials", e));
            }
        }
    }, [accountId, target, allTaxProfiles]);

    useEffect(() => {
        if (!linked || !accountId) {
            return;
        }
        getTaxProfile(accountId, linked.parentTaxProfileId)
            .then((parentTp) => {
                setParentTaxProfile(parentTp);
            })
            .catch((e) => d30ToastError("Problem getting tax profile.", e));
    }, [accountId, linked]);

    useEffect(() => {
        if (!linked || !accountId || !parentTaxProfile) {
            return;
        }
        getAndSetJurisdictionByStateCode(parentTaxProfile.state);

        getJurisdictionFilingType(linked.jurisdictionUIAdditionalTaxProfile.jurisdictionFilingTypeId)
            .then((linkedFilingType) => {
                setFilingType(linkedFilingType);
                setJurisdictionFilingTypeId(linked.jurisdictionUIAdditionalTaxProfile.jurisdictionFilingTypeId);
                _setLinkedTpDefaultValues();
            })
            .catch((e) => d30ToastError("Problem getting jurisdiction filing type", e));
    }, [getAndSetJurisdictionByStateCode, accountId, linked, _setLinkedTpDefaultValues, parentTaxProfile]);

    // This use effect sets all the basic form data that is part of the tax profile object
    useEffect(() => {
        if (taxProfile) {
            if (isDraftForMerchant) {
                const withoutPrefix = taxProfile.name.replace(`${PLACEHOLDER_PREFIX}: `, "");
                setName(withoutPrefix);
            }

            if (!isDraftForMerchant) {
                setName(taxProfile.name);
                setLegalName(taxProfile.legalName);
                setFrequency(taxProfile.frequency);
                setStateTaxId(taxProfile.stateTaxId ?? "");
                setFederalTaxId(taxProfile.federalTaxId ?? "");
                setAdditionalStateMetadata(taxProfile.additionalStateMetadata ?? "");
                setAdditionalStateMetadata2(taxProfile.additionalStateMetadata2 ?? "");
                setAdditionalStateMetadata3(taxProfile.additionalStateMetadata3 ?? "");
            }

            setMessage(undefined);
            setDoesFrequencyChangeAffectsCurrentPeriod(false);
            setIsEditing(true);
            setIsTakeGiveBack(taxProfile.filingMethod === "take-give-back");
            setJurisdictionFilingTypeId(taxProfile.jurisdictionFilingTypeId);
        }
    }, [taxProfile, isDraftForMerchant]);

    useEffect(() => {
        if (isEditing || !filingType) {
            return;
        }

        if (!linked) {
            shouldBypassDetails && _setPlaceholderValues();
            !shouldBypassDetails && _clearFormValues();
        }

        if (linked) {
            if (shouldBypassDetails) {
                _setLinkedTpDefaultValues();
                setName(`${PLACEHOLDER_PREFIX}: ${filingType.name}`);
                setFrequency(PLACEHOLDER_VALUES.FILING_FREQUENCY);
            }

            if (!shouldBypassDetails) {
                _setLinkedTpDefaultValues();
                setName(`${filingType.name}`);
            }
        }
    }, [
        _clearFormValues,
        _setLinkedTpDefaultValues,
        shouldBypassDetails,
        linked,
        _setPlaceholderValues,
        isEditing,
        filingType,
    ]);

    useEffect(() => {
        if (!isEditing) {
            setIsDraftForMerchant(name.startsWith(PLACEHOLDER_PREFIX));
        }
    }, [name, isEditing]);

    // This use effect gets the group Login Required info, and sets it.
    useEffect(() => {
        if (jurisdiction && accountId) {
            getGroupLoginRequired(accountId, jurisdiction.abbreviatedName)
                .then((isGroupLoginRequiredForState) => setIsGroupLoginRequired(isGroupLoginRequiredForState))
                .catch((e) => d30ToastError("Problem getting group login", e));
        }
    }, [jurisdiction, accountId]);

    useEffect(() => {
        setAreExistingCredentialsReused(isEqual(previouslySavedCredentials, credentials));
    }, [previouslySavedCredentials, credentials]);

    useEffect(() => {
        if (taxProfile) {
            setIsGroupLoginUsed(
                taxProfile.credentialsType === "INTERNAL_GROUP" || taxProfile.credentialsType === "PARTNER_GROUP"
            );

            const hasFailingCreds = taxProfile.credentialsFailing === "failing";
            setHasFailingCredentials(hasFailingCreds);

            const reason = taxProfile.failingReason
                ? getTaxProfileFailureReasonDescription(taxProfile.failingReason, false)
                : "";

            setFailingCredentialsMessage(reason);

            getAndSetJurisdictionByStateCode(taxProfile.state);

            if (taxProfile.jurisdictionFilingTypeId) {
                getJurisdictionFilingType(taxProfile.jurisdictionFilingTypeId)
                    .then((filingTypeResponse) => {
                        setFilingType(filingTypeResponse);
                    })
                    .catch((e) => d30ToastError("Problem getting jurisdiction filing type", e));
            }
        }
    }, [taxProfile, getAndSetJurisdictionByStateCode]);

    useEffect(() => {
        if (taxProfile) {
            setAreExistingCredentialsReused(
                !hasFailingCredentials && (isUsernameRequired || isPasswordRequired || isPinRequired)
            );
        }
    }, [taxProfile, isUsernameRequired, isPasswordRequired, isPinRequired, hasFailingCredentials]);

    useEffect(() => {
        if (frequency) {
            const sub = frequency.startsWith("Ops-only: ") ? frequency.substring(10) : frequency;
            // NOTE: we don't have to confirm frequency so long as we're editing and the frequency hasn't been changed
            if (taxProfile !== undefined) {
                const hasFrequencyChanged = taxProfile.frequency !== sub;
                if (hasFrequencyChanged) {
                    setOldFrequency(taxProfile.frequency);
                }
                setShowFrequencyConfirm(hasFrequencyChanged);
            }
        }
    }, [frequency, taxProfile]);

    useEffect(() => {
        if (value) {
            setIsEditing(true);
            setName(value.name);
            setLegalName(value.legalName);
            setJurisdiction(value.state);
            setFrequency(value.frequency);
            setStateTaxId(value.stateTaxId);
            setFederalTaxId(value.federalTaxId);
            setCredentials(value.credentials);
            setTaxProfile(undefined);
            setAreExistingCredentialsReused(false);
            setDoesFrequencyChangeAffectsCurrentPeriod(value.frequencyChangeAffectsCurrentPeriod);
            setOldFrequency(value.oldFrequency);
            setIsGroupLoginUsed(value.credentialsType !== "SIMPLE");
            setAdditionalStateMetadata(value.additionalStateMetadata ?? "");
            setAdditionalStateMetadata2(value.additionalStateMetadata2 ?? "");
            setAdditionalStateMetadata3(value.additionalStateMetadata3 ?? "");
        }
    }, [value]);

    useEffect(() => {
        if (frequencies) {
            const frFiltered: string[] = [];
            let count = 0;
            if (loginContext.user && ["Admin", "Superuser"].includes(loginContext.user.type)) {
                for (const z of Object.keys(frequencies)) {
                    if (frequencies[z]?.legacy) {
                        frFiltered.push("Ops-only: " + z);
                    } else {
                        frFiltered.push(z);
                    }
                    count++;
                }
            } else {
                for (const z of Object.keys(frequencies)) {
                    if (!frequencies[z]?.legacy) {
                        frFiltered.push(z);
                        count++;
                    }
                }
                if (frequency && !frFiltered.includes(frequency)) {
                    frFiltered.push(frequency);
                }
            }
            if (count > 0) {
                if (frequency && !frFiltered.includes(frequency) && frFiltered.includes("Ops-only: " + frequency)) {
                    setFrequency("Ops-only: " + frequency);
                }
                setMessage(undefined);
                setSelectedFrequencyList(frFiltered);
            } else {
                setMessage(
                    "The selected state may not have statewide sales tax, for help setting up a local rate, contact support"
                );
                setSelectedFrequencyList(undefined);
            }
        } else {
            // no frequencies found, reset list
            setMessage(
                "The selected state may not have statewide sales tax, for help setting up a local rate, contact support"
            );
            setSelectedFrequencyList(undefined);
        }
    }, [frequencies, loginContext.user, frequency]);

    useEffect(() => {
        setHasBaseAccessPerms(hasPermission(loginContext.permissions, "base_access"));
    }, [loginContext]);

    const _doSubmit = (event?: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
        event?.preventDefault();
        event?.stopPropagation();

        submitAttemptCounter.current = submitAttemptCounter.current + 1;

        if (isSubmitting || isCheckingCreds) return;

        if (!isFormValid) {
            if (formLevelValidationMsg?.includes(FORM_ERROR_LABELS.failingTpCreds)) {
                document.getElementById("validateTaxCredsBtn")?.focus();
            } else {
                formRef.current?.reportValidity();
            }
            return;
        }

        setMessage(undefined);
        setIsSubmitting(true);

        if (linked && !showLinkedTaxProfileDetails) {
            // When the user selects No to creation of an additional tax profile, we skip creating a new tax profile
            _doCancel();
        } else {
            const data = _getData();

            const newTaxProfileData: Omit<TaxProfileWithCredentials, "id" | "createdBy" | "updatedBy"> = {
                name: data.name,
                legalName: data.legalName,
                state: data.state ? data.state.abbreviatedName : "",
                stateTaxId: data.stateTaxId,
                federalTaxId: data.federalTaxId,
                frequency: data.frequency as FilingFrequency,
                credentialsFailing: getCredentialsStatus(data),
                credentials: data.credentials,
                hidden: false,
                manualFiling: false,
                requireAdditionalSalesData: false,
                requireUseData: false,
                earlyFilingRequired: false,
                manualReconcileRequired: false,
                ratesVerified: undefined,
                ratesVerifiedBy: undefined,
                typeVerified: undefined,
                typeVerifiedBy: undefined,
                taxRateType: "overlapping",
                jurisdictionFilingTypeId: data.jurisdictionFilingTypeId,
                jurisdictionUIFlavorGroupId: data.jurisdictionUIFlavorGroupId,
                filingMethod: data.filingMethod,
                credentialsType: data.credentialsType ?? "SIMPLE",
                showExpandedFields: false,
                additionalStateMetadata: data.additionalStateMetadata,
                additionalStateMetadata2: data.additionalStateMetadata2,
                additionalStateMetadata3: data.additionalStateMetadata3,
            };
            onSubmit(data, newTaxProfileData).catch((e: any) => {
                setIsSubmitting(false);
                d30ToastError("Problem adding tax profile", e);
                setMessage("Problem adding tax profile");
            });
        }
    };

    const _doCancel = () => {
        abortCredsCheck();

        if (onCancel) {
            onCancel(_getData());
        }
    };

    const _getData = useCallback((): ITaxProfileFormData => {
        const freq = frequency?.startsWith("Ops-only: ")
            ? (frequency.substring(10) as FilingFrequency)
            : (frequency as FilingFrequency);
        return {
            name,
            legalName: isTakeGiveBack ? "take-give-back" : legalName,
            state: jurisdiction,
            frequency: frequency ? freq : undefined,
            stateTaxId: stateTaxId,
            federalTaxId: federalTaxId,
            credentials: credentials,
            taxProfile,
            reuseExistingCredentials: areExistingCredentialsReused,
            frequencyChangeAffectsCurrentPeriod: doesFrequencyChangeAffectsCurrentPeriod,
            oldFrequency,
            credentialsType: isGroupLoginUsed ? (taxProfile?.credentialsType ?? "INTERNAL_GROUP") : "SIMPLE",
            jurisdictionFilingTypeId: jurisdictionFilingTypeId ?? filingType?.id,
            jurisdictionUIFlavorGroupId: selectedFlavor?.jurisdictionFlavorGroupId,
            filingMethod: filingMethodForData,
            showExpandedFields: false,
            additionalStateMetadata: additionalStateMetadata,
            additionalStateMetadata2: additionalStateMetadata2,
            additionalStateMetadata3: additionalStateMetadata3,
            sentToMerchant: shouldBypassDetails,
        };
    }, [
        filingMethodForData,
        additionalStateMetadata,
        additionalStateMetadata2,
        additionalStateMetadata3,
        areExistingCredentialsReused,
        credentials,
        doesFrequencyChangeAffectsCurrentPeriod,
        federalTaxId,
        filingType,
        frequency,
        isGroupLoginUsed,
        isTakeGiveBack,
        jurisdiction,
        jurisdictionFilingTypeId,
        legalName,
        name,
        oldFrequency,
        selectedFlavor?.jurisdictionFlavorGroupId,
        shouldBypassDetails,
        stateTaxId,
        taxProfile,
    ]);

    const _handleAdditionalTaxProfileAnswerChange = (event: any) => {
        if (event.target.value !== linkedTaxProfileQuestionAnswer) {
            setLinkedTaxProfileQuestionAnswer(event.target.value);
            setShowLinkedTaxProfileDetails(event.target.value === "yes");
        }
    };

    const _renderPageDescription = () => {
        return (
            !linked &&
            !isEditing && (
                <Typography>
                    We will automatically file your returns and transfer the sales tax you collect directly to tax
                    authorities.
                </Typography>
            )
        );
    };

    useEffect(() => {
        if (isTakeGiveBack) {
            setCredentials({});
            setStateTaxId("");
            setFederalTaxId("");
            setLegalName("");
            setAdditionalStateMetadata("");
            setAdditionalStateMetadata2("");
            setAdditionalStateMetadata3("");
            // if the slider is checked it is always take-give-back
            // return "take-give-back" as TaxProfileFilingMethod;
            setFilingMethodForData("take-give-back");
        } else {
            if (taxProfile && taxProfile.filingMethod === "take-give-back") {
                // if the type was previously take-give-back and the slider is unchecked set it back to no type
                setFilingMethodForData(undefined);
            } else if (taxProfile) {
                // otherwise set the type back to whatever it was previously
                setFilingMethodForData(taxProfile.filingMethod);
            } else {
                // we don't have anything to fall back to, and the slider is unchecked, so no type
                setFilingMethodForData(undefined);
            }
        }
    }, [isTakeGiveBack, taxProfile]);

    useEffect(() => {
        abortCredsCheck();
    }, [isTakeGiveBack, abortCredsCheck]);

    useEffect(() => {
        abortCredsCheck();
    }, [shouldBypassDetails, abortCredsCheck]);

    const _renderTakeGiveBackToggle = () => {
        return (
            !shouldLimitEdit &&
            !linked &&
            hasBaseAccessPerms && (
                <TakeGiveBackToggle isTakeGiveBack={isTakeGiveBack} setIsTakeGiveBack={setIsTakeGiveBack} />
            )
        );
    };

    const _renderFilingType = () => {
        return (
            !isTakeGiveBack &&
            isFilingTypeRequired &&
            !linked && (
                <Select<IJurisdictionFilingType>
                    title={FORM_FIELD_LABELS.filingType}
                    validate={() => filingTypeValidationResults}
                    data-testid={"filingType"}
                    isDisabled={isSubmitting}
                    options={filingTypesForState}
                    value={filingType}
                    onChange={(x) => {
                        if (x) {
                            abortCredsCheck();
                            setCredsValidationStatus(undefined);
                            setFilingType(x);
                            setJurisdictionFilingTypeId(x.id);
                        }
                    }}
                    label={(s) => s?.name}
                    isRequired
                />
            )
        );
    };

    const checkCreds = () => {
        if (filingType?.id) {
            setCredsValidationStatus("validating");
            setIsCheckingCreds(true);

            controllerRef.current = new AbortController();

            const postData: EfileCheckData = {
                jurisdictionFilingTypeId: filingType.id,
                profileLoginCreds: {
                    username: _trim(credentials.username),
                    password: _trim(credentials.password),
                },
                abortController: controllerRef.current,
            };

            if (filingType?.davoCheckCreds) {
                postData.profileLoginCreds.pin = _trim(credentials.pin);
            } else {
                if (_trim(credentials.pin)) {
                    // Pin Provided in Additional Options for Skyscrapper integration
                    postData.additionalOptions = {
                        [`${pinFieldDefinition?.jurisdictionFieldType.id}`]: _trim(credentials.pin),
                    };
                }
            }

            getCheckEFileCredentials(postData)
                .then((response) => {
                    if (response.errorMessage?.includes("Did not receive a response after")) {
                        setCredsValidationStatus("timeout");
                    } else if (response.error) {
                        setCredsValidationStatus("error");
                    } else if (response.isPasswordChangeRequired) {
                        setCredsValidationStatus("isPasswordChangeRequired");
                    } else if (response.isValid) {
                        setCredsValidationStatus("valid");
                    } else {
                        setCredsValidationStatus("invalid");
                    }
                })
                .catch((e) => {
                    if (!controllerRef.current?.signal.aborted) {
                        setCredsValidationStatus("error");
                        d30ToastError("There was a problem checking credentials.", e);
                    } else {
                        setCredsValidationStatus(undefined);
                        controllerRef.current = undefined;
                    }
                })
                .finally(() => {
                    setIsCheckingCreds(false);
                });

            stateTaxIdInputRef.current && stateTaxIdInputRef.current.focus();
        }
    };

    const _renderFrequencyChange = () => {
        return (
            !shouldLimitEdit &&
            showFrequencyConfirm &&
            frequency &&
            (!linked || showLinkedTaxProfileDetails) && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }} data-testid={"frequencyChangeConfirmation"}>
                    <FormControl>
                        <RadioGroup row aria-label="frequencyChangeRadioGroup" value={linkedTaxProfileQuestionAnswer}>
                            <FormControlLabel
                                value="no"
                                control={
                                    <Radio
                                        checked={!doesFrequencyChangeAffectsCurrentPeriod}
                                        color="primary"
                                        data-testid={"shouldImpactNextPeriodOnly"}
                                        onClick={() => {
                                            setDoesFrequencyChangeAffectsCurrentPeriod(false);
                                        }}
                                    />
                                }
                                label="Only the next filing will be updated to match the new frequency."
                            />
                            <FormControlLabel
                                value="yes"
                                control={
                                    <Radio
                                        checked={doesFrequencyChangeAffectsCurrentPeriod}
                                        color="primary"
                                        data-testid={"shouldImpactCurrentAndNextPeriod"}
                                        onClick={() => {
                                            setDoesFrequencyChangeAffectsCurrentPeriod(true);
                                        }}
                                    />
                                }
                                label="Both the current and next filings should be updated to match the new frequency."
                            />
                        </RadioGroup>
                    </FormControl>

                    <br />
                    {frequencyData && (
                        <div>
                            {`Current period started on ${frequencyData.start}`}
                            <br />
                            {`Next period starts after ${frequencyData.end}`}
                        </div>
                    )}
                </div>
            )
        );
    };

    const CancelButton = () => (
        <Button
            data-testid={"cancelTpFormBtn"}
            disabled={isSubmitting || isCheckingCreds}
            onClick={_doCancel}
            variant="outlined"
            color="primary"
            style={{ marginRight: "10px" }}>
            Cancel
        </Button>
    );

    const _renderAdditionalInfoHeading = () => {
        return (
            (!linked || showLinkedTaxProfileDetails) && (
                <Typography className={"da-tp-form-heading"} component="h3">
                    Additional Filing Information
                </Typography>
            )
        );
    };

    const _renderCompanyInformationHeading = () => {
        return (
            (!linked || showLinkedTaxProfileDetails) && (
                <Typography className={"da-tp-form-heading"} component="h3">
                    Company Information
                </Typography>
            )
        );
    };

    const _renderFrequencyChangePreventionNotice = () => {
        return (
            shouldLimitEdit && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <span>NOTE: Filing frequency cannot be changed from the filing screen</span>
                </div>
            )
        );
    };

    const _renderUsingGroupLoginNotice = () => {
        return (
            isGroupLoginUsed &&
            !isTakeGiveBack &&
            (!linked || showLinkedTaxProfileDetails) && (
                <div style={{ padding: "10px 0", textAlign: "left" }}>
                    DAVO has established secure connectivity to the tax agency on your behalf using the information you
                    provided.
                </div>
            )
        );
    };

    const _renderNeedSupportNotice = () => {
        return (
            <Typography data-testid={"needSupportMsg"} className={"needSupportMsg"} gutterBottom={true}>
                <LightbulbOutlinedIcon />
                Need more support? Give us a call at <a href="tel:+18886598432">888.659.8432</a>
            </Typography>
        );
    };

    const _renderMessageNotice = () => {
        return (
            message && (
                <Typography data-testid={"messagesNoticeContainer"} style={{ color: "red" }}>
                    {message}
                </Typography>
            )
        );
    };

    const _renderAlerts = () => {
        return (
            !isFormValid && (
                <Alert
                    data-testid={"tpValidationAlert"}
                    icon={<BlockIcon />}
                    color={"error"}
                    variant={isDarkMode ? "outlined" : "standard"}
                    style={{ marginBottom: "6px" }}>
                    <AlertTitle data-testid={"formLevelValidationMsg"}>{formLevelValidationMsg}</AlertTitle>
                    {invalidStandardFields.concat(invalidCredentialFields).length > 0 && (
                        <span>{invalidStandardFields.concat(invalidCredentialFields).join(", ")}</span>
                    )}
                </Alert>
            )
        );
    };

    return (
        <TaxProfileFormContext.Provider
            value={{
                filingType,
                setFilingType,
                shouldBypassDetails,
                setShouldBypassDetails,
                isTakeGiveBack,
                legalNameFieldDefinition,
                legalNameValidationResults,
                legalNameLabel,
                setLegalNameLabel,
                isSubmitting,
                isCheckingCreds,
                showLinkedTaxProfileDetails,
                legalName,
                setLegalName,
                tpNameValidationResults,
                name,
                setName,
                filingFrequencyValidationResults,
                selectedFrequencyList,
                frequency,
                setFrequency,
                shouldLimitEdit,
                isGroupLoginUsed,
                isCredsValidationRequired,
                isUsernameRequired,
                formLevelValidationMsg,
                isPasswordRequired,
                isPinRequired,
                linked,
                nameString: filingType?.name ? `${filingType?.name} site` : "State site",
                siteUrl: filingType?.url ?? "site",
                hasFailingCredentials,
                failingCredentialsMessage,
                failingReason: taxProfile?.failingReason,
                isGroupLoginRequired,
                selectedFlavor,
                handleCredentialFlavorChange,
                usernameFieldDefinition,
                usernameValidationResults,
                passwordFieldDefinition,
                passwordValidationResults,
                pinFieldDefinition,
                pinValidationResults,
                credentials,
                setCredentials,
                credsValidationStatus,
                areAllCredentialFieldsValid,
                abortCredsCheck,
                checkCreds,
                areExistingCredentialsReused,
                stateTaxIdInputRef,
                stateTaxIdFieldDefinition,
                stateTaxIdValidationResults,
                taxIdLabel,
                stateTaxId,
                setStateTaxId,
                feinFieldDefinition,
                federalTaxIdValidationResults,
                federalTaxId,
                setFederalTaxId,
                additionalStateMetadataFieldDefinition,
                additionalMetadataValidationResults,
                additionalStateMetadata,
                setAdditionalStateMetadata,
                additionalStateMetadata2FieldDefinition,
                additionalMetadata2ValidationResults,
                additionalStateMetadata2,
                setAdditionalStateMetadata2,
                additionalStateMetadata3FieldDefinition,
                additionalMetadata3ValidationResults,
                additionalStateMetadata3,
                setAdditionalStateMetadata3,
                jurisdiction,
                isEditing,
                states,
                changeState: _changeState,
                linkedTaxProfileQuestionAnswer,
                handleAdditionalTaxProfileAnswerChange: _handleAdditionalTaxProfileAnswerChange,
                hasBaseAccessPerms,
            }}>
            <Box
                component={"form"}
                noValidate
                onSubmit={_doSubmit}
                ref={formRef}
                id={"taxProfileFormElement"}
                data-testid={"taxProfileForm"}
                className={[isDarkMode ? "isDarkMode" : "", "taxProfileForm"].filter(Boolean).join(" ")}>
                <HeaderContent headerContent={headerContent} />
                {_renderPageDescription()}

                {!linked && (
                    <>
                        <Typography
                            data-testid={"tp-form-heading"}
                            className={["da-tp-form-heading", isEditing && "first-of-type"].filter(Boolean).join(" ")}
                            component={"h3"}>
                            State Tax Return
                        </Typography>

                        <Typography variant="body1">
                            Refer to your state business registration to find this information.
                        </Typography>
                    </>
                )}

                <JurisdictionDropdown />
                <BypassDetailsToggle />
                {filingType && !shouldBypassDetails && _renderTakeGiveBackToggle()}
                {jurisdiction && _renderFilingType()}

                <TaxProfileName />
                <CredentialsEntry />
                {filingType && !shouldBypassDetails && _renderAdditionalInfoHeading()}
                <StateTaxId />
                <AdditionalMetadata />
                <AdditionalMetadata2 />
                <AdditionalMetadata3 />
                <FilingFrequencyDropdown />
                {filingType && !shouldBypassDetails && _renderFrequencyChange()}
                {filingType && !shouldBypassDetails && _renderCompanyInformationHeading()}
                <LegalName />
                <FEIN />
                {filingType && !shouldBypassDetails && _renderFrequencyChangePreventionNotice()}
                {filingType && !shouldBypassDetails && _renderUsingGroupLoginNotice()}
                {filingType && !shouldBypassDetails && _renderNeedSupportNotice()}
                {filingType && _renderMessageNotice()}
                {_renderAlerts()}

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
                    {showCancel && <CancelButton />}
                    {jurisdiction && (
                        <Button
                            data-testid={"saveBtn"}
                            disabled={isSubmitting || isCheckingCreds}
                            startIcon={
                                (isSubmitting || isCheckingCreds) && (
                                    <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                                )
                            }
                            type={"submit"}
                            variant={"contained"}
                            color="primary">
                            {saveLabel ? saveLabel : "Save"}
                        </Button>
                    )}
                </div>
            </Box>
        </TaxProfileFormContext.Provider>
    );
};
