import {
    IJurisdiction,
    IJurisdictionFilingType,
    IJurisdictionUIField,
    IJurisdictionUIFieldDefinitionBase,
    LinkedTaxProfileType,
    LoginCredentialValidationStatus,
    OptionalString,
    TaxProfileCredentials,
    TaxProfileFailureReasons,
} from "@davo/types";
import React, { MutableRefObject } from "react";

export interface ITaxProfileFormContext {
    // general
    filingType: IJurisdictionFilingType | undefined;
    setFilingType: (v: IJurisdictionFilingType) => void;
    shouldBypassDetails: boolean;
    setShouldBypassDetails: (v: boolean) => void;
    isTakeGiveBack: boolean;
    linkedTaxProfileQuestionAnswer: string;
    // legalName
    legalNameFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    linked: LinkedTaxProfileType | undefined;
    showLinkedTaxProfileDetails: boolean;
    legalNameValidationResults: string | undefined;
    legalNameLabel: string;
    setLegalNameLabel: (v: string) => void;
    isSubmitting: boolean;
    isCheckingCreds: boolean;
    legalName: string;
    setLegalName: (v: string) => void;
    // tpName
    tpNameValidationResults: string | undefined;
    name: string;
    setName: (v: string) => void;
    // filingFrequency
    filingFrequencyValidationResults: string | undefined;
    selectedFrequencyList: string[] | undefined;
    frequency: OptionalString;
    setFrequency: (v: OptionalString) => void;
    shouldLimitEdit?: boolean | undefined;
    // credentialsEntry
    isCredsValidationRequired: boolean;
    isGroupLoginUsed: boolean;
    isUsernameRequired: boolean;
    isPasswordRequired: boolean;
    isPinRequired: boolean;
    siteUrl: string;
    nameString: string;
    hasFailingCredentials: boolean;
    failingCredentialsMessage: string;
    failingReason: keyof typeof TaxProfileFailureReasons | undefined | null;
    isGroupLoginRequired: boolean;
    selectedFlavor: IJurisdictionUIField | undefined;
    handleCredentialFlavorChange: (v: IJurisdictionUIField) => void;
    usernameFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    usernameValidationResults: string | undefined;
    passwordFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    passwordValidationResults: string | undefined;
    pinFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    pinValidationResults: string | undefined;
    credentials: TaxProfileCredentials;
    setCredentials(v: TaxProfileCredentials): void;
    credsValidationStatus: LoginCredentialValidationStatus;
    areAllCredentialFieldsValid: boolean;
    abortCredsCheck: () => void;
    checkCreds: () => void;
    areExistingCredentialsReused: boolean;
    formLevelValidationMsg: string | undefined;
    // stateTaxId
    stateTaxIdInputRef: MutableRefObject<HTMLInputElement | undefined> | undefined;
    stateTaxIdFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    stateTaxIdValidationResults: string | undefined;
    taxIdLabel: string;
    stateTaxId: string;
    setStateTaxId: (v: string) => void;
    // fein
    feinFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    federalTaxIdValidationResults: string | undefined;
    federalTaxId: string;
    setFederalTaxId: (v: string) => void;
    // additionalMetadata
    additionalStateMetadataFieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    additionalMetadataValidationResults: string | undefined;
    additionalStateMetadata: string;
    setAdditionalStateMetadata: (v: string) => void;
    additionalStateMetadata2FieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    additionalMetadata2ValidationResults: string | undefined;
    additionalStateMetadata2: string;
    setAdditionalStateMetadata2: (v: string) => void;
    additionalStateMetadata3FieldDefinition: IJurisdictionUIFieldDefinitionBase | undefined;
    additionalMetadata3ValidationResults: string | undefined;
    additionalStateMetadata3: string;
    setAdditionalStateMetadata3: (v: string) => void;
    // stateDropdown
    jurisdiction: IJurisdiction | null | undefined;
    isEditing: boolean;
    states: IJurisdiction[];
    changeState: (v: IJurisdiction | undefined) => void;
    // header content
    handleAdditionalTaxProfileAnswerChange: (event: any) => void;
    // bypassDetailsToggle
    hasBaseAccessPerms: boolean;
}

export const TaxProfileFormContextDefaultValue: ITaxProfileFormContext = {
    filingType: undefined,
    setFilingType: () => undefined,
    shouldBypassDetails: false,
    setShouldBypassDetails: () => undefined,
    isTakeGiveBack: false,
    legalNameFieldDefinition: undefined,
    showLinkedTaxProfileDetails: false,
    legalNameValidationResults: undefined,
    linked: undefined,
    legalNameLabel: "",
    setLegalNameLabel: () => undefined,
    isSubmitting: false,
    isCheckingCreds: false,
    legalName: "",
    setLegalName: () => undefined,
    tpNameValidationResults: undefined,
    name: "",
    setName: () => undefined,
    filingFrequencyValidationResults: undefined,
    selectedFrequencyList: undefined,
    frequency: undefined,
    setFrequency: () => undefined,
    shouldLimitEdit: false,
    isCredsValidationRequired: false,
    isGroupLoginUsed: false,
    isUsernameRequired: false,
    isPasswordRequired: false,
    isPinRequired: false,
    siteUrl: "",
    nameString: "",
    hasFailingCredentials: false,
    failingCredentialsMessage: "",
    failingReason: undefined,
    isGroupLoginRequired: false,
    selectedFlavor: undefined,
    handleCredentialFlavorChange: () => undefined,
    usernameFieldDefinition: undefined,
    usernameValidationResults: undefined,
    passwordFieldDefinition: undefined,
    passwordValidationResults: undefined,
    pinFieldDefinition: undefined,
    pinValidationResults: undefined,
    credentials: {},
    setCredentials: () => undefined,
    credsValidationStatus: undefined,
    areAllCredentialFieldsValid: false,
    abortCredsCheck: () => undefined,
    checkCreds: () => undefined,
    areExistingCredentialsReused: false,
    formLevelValidationMsg: undefined,
    stateTaxIdInputRef: undefined,
    stateTaxIdFieldDefinition: undefined,
    stateTaxIdValidationResults: undefined,
    taxIdLabel: "",
    stateTaxId: "",
    setStateTaxId: () => undefined,
    feinFieldDefinition: undefined,
    federalTaxIdValidationResults: undefined,
    federalTaxId: "",
    setFederalTaxId: () => undefined,
    additionalStateMetadataFieldDefinition: undefined,
    additionalMetadataValidationResults: undefined,
    additionalStateMetadata: "",
    setAdditionalStateMetadata: () => undefined,
    additionalStateMetadata2FieldDefinition: undefined,
    additionalMetadata2ValidationResults: undefined,
    additionalStateMetadata2: "",
    setAdditionalStateMetadata2: () => undefined,
    additionalStateMetadata3FieldDefinition: undefined,
    additionalMetadata3ValidationResults: undefined,
    additionalStateMetadata3: "",
    setAdditionalStateMetadata3: () => undefined,
    jurisdiction: undefined,
    isEditing: false,
    states: [],
    changeState: () => undefined,
    linkedTaxProfileQuestionAnswer: "",
    handleAdditionalTaxProfileAnswerChange: () => undefined,
    hasBaseAccessPerms: false,
};

export const TaxProfileFormContext = React.createContext(TaxProfileFormContextDefaultValue);
export const useTaxProfileFormContext = () => React.useContext(TaxProfileFormContext);
