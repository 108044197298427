import {
    BankAccount,
    BankAccountNachaRestrictedMessage,
    BankDetails,
    MetaBankAccount,
    MetaBankAccountPausedMessage,
    toJSON,
} from "@davo/types";
import { DateTime } from "luxon";
import { del, get, getArray, post, put } from "./api";

export const getBankAccount = async (accountId: string, bankAccountId: string) => {
    return get(`api/common/account/${accountId}/bank-accounts/${bankAccountId}`, true, MetaBankAccount);
};

export const getBankAccounts = async (accountId: string) => {
    return getArray(`api/common/account/${accountId}/bank-accounts`, true, MetaBankAccount);
};

export const addBankAccount = (accountId: string, bankAccount: BankDetails): Promise<BankAccount> => {
    return post<BankAccount>(`api/common/account/${accountId}/bank-accounts`, bankAccount, true);
};

export async function pauseBankAccount(
    accountId: string,
    bankAccountId: string,
    pausedMessage: string,
    unpauseDate?: DateTime
) {
    await put(
        `api/common/account/${accountId}/bank-accounts/${bankAccountId}/paused`,
        toJSON(MetaBankAccountPausedMessage, {
            pausedMessage,
            unpauseDate,
        }),
        true
    );
}

export async function markBankAccountHidden(accountId: string, bankAccountId: string) {
    await put(`api/common/account/${accountId}/bank-accounts/${bankAccountId}/hide`, {}, true);
}

export async function updateBankAccountNickname(accountId: string, bankAccountId: string, newNickname: string) {
    await put(
        `api/common/account/${accountId}/bank-accounts/${bankAccountId}/nickname`,
        {
            newNickname,
        },
        true
    );
}

export async function unpauseBankAccount(accountId: string, bankAccountId: string) {
    await del(`api/common/account/${accountId}/bank-accounts/${bankAccountId}/paused`, true);
}

export async function getBankAccountNachaRestricted(accountId: string, bankAccountId: string) {
    return get<BankAccountNachaRestrictedMessage>(
        `api/common/account/${accountId}/bank-accounts/${bankAccountId}/nachaRestricted`,
        true
    );
}
