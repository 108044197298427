import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { TextField } from "../TextField";
import { useTaxProfileFormContext } from "../context";

export const AdditionalMetadata: FunctionComponent = () => {
    const {
        isSubmitting,
        filingType,
        shouldBypassDetails,
        isTakeGiveBack,
        linked,
        showLinkedTaxProfileDetails,
        additionalStateMetadataFieldDefinition,
        additionalMetadataValidationResults,
        additionalStateMetadata,
        setAdditionalStateMetadata,
    } = useTaxProfileFormContext();

    if (
        !filingType ||
        shouldBypassDetails ||
        isTakeGiveBack ||
        !additionalStateMetadataFieldDefinition ||
        !additionalStateMetadataFieldDefinition.isVisible ||
        (linked &&
            (!linked.jurisdictionUIAdditionalTaxProfile.showAdditionalStateMetadata || !showLinkedTaxProfileDetails))
    ) {
        return null;
    }

    return (
        <TextField
            data-testid={"additionalStateMetadata"}
            validate={() => additionalMetadataValidationResults}
            inputProps={{
                [`data-testid`]: "additionalStateMetadataInput",
                pattern: additionalStateMetadataFieldDefinition.validationRegex,
            }}
            InputProps={{
                endAdornment: additionalStateMetadataFieldDefinition.toolTip && (
                    <Tooltip title={additionalStateMetadataFieldDefinition.toolTip}>
                        <InfoIcon fontSize={"small"} color="primary" />
                    </Tooltip>
                ),
            }}
            className="fs-exclude"
            label={`${additionalStateMetadataFieldDefinition.label} ${
                additionalStateMetadataFieldDefinition.helpText
                    ? ` (e.g. ${additionalStateMetadataFieldDefinition.helpText})`
                    : ""
            }`}
            isDisabled={isSubmitting}
            value={additionalStateMetadata}
            onChange={setAdditionalStateMetadata}
            isRequired
        />
    );
};
