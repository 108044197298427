import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FunctionComponent } from "react";
import { TextField } from "./TextField";

export interface IResetPasswordModal {
    closeDialog: (target?: string) => void;
    username: string | undefined;
    setUsername(v: string): void;
    usernameValidationResults: string | undefined;
    doReset(): void;
    isResettingPassword: boolean;
    setIsResettingPassword(v: boolean): void;
}

export const ResetPasswordModal: FunctionComponent<IResetPasswordModal> = ({
    closeDialog,
    username,
    setUsername,
    usernameValidationResults,
    doReset,
    isResettingPassword,
    setIsResettingPassword,
}) => {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog
            data-testid={"resetPasswordDialog"}
            fullScreen={makeFullScreen}
            open={true}
            onClose={() => closeDialog()}
            PaperProps={{
                component: "form",
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    if (isResettingPassword) return;
                    setIsResettingPassword(true);

                    if (!username || !!usernameValidationResults) {
                        return;
                    }

                    doReset();
                },
            }}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <DialogContentText gutterBottom>
                    Enter your email address and we will send a reset link to your account.
                </DialogContentText>
                <TextField
                    data-testid={"resetEmailInputContainer"}
                    inputProps={{
                        [`data-testid`]: "resetEmailInput",
                        autoComplete: "off",
                    }}
                    className="fs-exclude"
                    label="Email Address"
                    value={username}
                    isDisabled={isResettingPassword}
                    helperText={" "}
                    onChange={setUsername}
                    validate={() => usernameValidationResults}
                    isAutoFocus
                    margin={"dense"}
                    isRequired
                    variant={"standard"}
                    type={"email"}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    data-testid={"cancelPwdResetBtn"}
                    onClick={() => closeDialog()}
                    variant="outlined"
                    style={{ marginRight: "10px" }}>
                    Cancel
                </Button>
                <Button
                    data-testid={"pwdResetBtn"}
                    type={"submit"}
                    disabled={isResettingPassword}
                    startIcon={
                        isResettingPassword && (
                            <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                        )
                    }
                    variant="contained">
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
};
