import { d30ToastError, ReactTable, useRefresh } from "@davo/portal-common";
import { AccountLogHistory, toDisplayDateTimeString } from "@davo/types";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import { getAccountHistory } from "./services";

export function HistoryTable({ accountId }: { accountId?: string }) {
    const [logEvents, setLogEvents] = useState<AccountLogHistory[]>();

    const [refresh] = useRefresh(() => {
        if (!accountId) {
            return;
        }
        setLogEvents(undefined);
        getAccountHistory(accountId)
            .then((results) => {
                setLogEvents(results);
            })
            .catch((e) => d30ToastError("Problem getting account history.", e));
    });

    if (!accountId || !logEvents) {
        return null;
    }

    const dateFormat = (data: any) => <>{toDisplayDateTimeString(data.value)}</>;

    const renderDescription = (data: any) => {
        return (
            <Typography
                style={{
                    maxWidth: "270px",
                    textOverflow: "ellipsis",
                    whiteSpace: "break-spaces",
                    overflow: "hidden",
                }}
                variant="body1">
                {data.value}
            </Typography>
        );
    };

    const renderLabel = (data: any) => renderDescription(data);

    return (
        <div style={{ width: "100%" }}>
            <ReactTable
                columns={[
                    {
                        Header: "Date",
                        accessor: "timestamp",
                        Cell: dateFormat,
                    },
                    {
                        Header: "Updated By",
                        accessor: (data: any) => (
                            <>
                                {data.userType &&
                                    ["Admin", "Superuser", "automation"].includes(data.userType) &&
                                    "DAVO: "}
                                {data.userType && ["Partner"].includes(data.userType) && "Partner: "}
                                {data.updatedBy}
                            </>
                        ),
                    },
                    {
                        Header: "Updated",
                        accessor: "label",
                        Cell: renderLabel,
                    },
                    { Header: "Event", accessor: "event" },
                    {
                        Header: "Description",
                        accessor: "description",
                        Cell: renderDescription,
                    },
                ]}
                data={logEvents}
                options={{
                    pageSize: 10,
                }}
                refreshData={refresh}
            />
        </div>
    );
}
