import { IJurisdiction } from "@davo/types";
import React, { FunctionComponent } from "react";
import Select from "../Select";
import { TextField } from "../TextField";
import { useTaxProfileFormContext } from "../context";

export const JurisdictionDropdown: FunctionComponent = () => {
    const { isSubmitting, jurisdiction, isEditing, states, changeState, linked } = useTaxProfileFormContext();

    if (linked) {
        return null;
    }

    return (
        <>
            {isEditing ? (
                <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    inputProps={{
                        [`data-testid`]: "selectedStateInput",
                    }}
                    label={"State:"}
                    value={jurisdiction?.fullName ?? ""}
                />
            ) : (
                <Select<IJurisdiction>
                    data-testid={"stateDropdown"}
                    inputProps={{
                        id: "stateDropdownInput",
                        ["data-testid"]: "stateDropdownInput",
                    }}
                    title="State"
                    noneLabel={`Select a state...`}
                    isDisabled={isSubmitting}
                    options={states}
                    value={jurisdiction}
                    onChange={changeState}
                    label={(s) => s?.fullName}
                />
            )}
        </>
    );
};
