import { ConnectionStatusLevelMessageOverride, IConnectionStatus, StatusLevel, toDisplayDateString } from "@davo/types";
import React, { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { StatusDot } from "./StatusDot";
import { getAccountLocationsStatus } from "./services";
import {
    getConnectionStatusLevelMessage,
    mapIAccountLocationsStatusToIConnectionStatus,
    reduceToSingleAccountLocationStatus,
} from "./util";

export interface IConnectionStatusProps {
    accountId?: string;
    locationId?: string;
    connectionStatusProvided?: IConnectionStatus;
    missingBankLink?: string;
    pausedBankInUseLink?: string;
    missingTaxProfileLink?: string;
    failingTaxProfileLink?: string;
    hideMessage?: boolean;
    restrictedBankLink?: string;
    tooltipMessage?: string;
    statusLevelOverride?: ConnectionStatusLevelMessageOverride;
}

export function ConnectionStatus({
    accountId,
    locationId,
    connectionStatusProvided,
    missingBankLink,
    pausedBankInUseLink,
    missingTaxProfileLink,
    failingTaxProfileLink,
    hideMessage,
    restrictedBankLink,
    tooltipMessage,
    statusLevelOverride,
}: IConnectionStatusProps) {
    const [status, setStatus] = useState<IConnectionStatus | undefined>(connectionStatusProvided);
    const [level, setLevel] = useState<StatusLevel>();
    const [message, setMessage] = useState<string>();
    const [destination, setDestination] = useState<string | undefined>(undefined);
    const [tooltip, setTooltip] = useState<string | undefined>(tooltipMessage);

    useAsyncEffect(async () => {
        if (!accountId || connectionStatusProvided) {
            return;
        }

        async function fetchAccountLocationsStatus(accId: string, locId?: string) {
            const accountLocationsStatus = await getAccountLocationsStatus(accId);
            if (!locId) {
                return mapIAccountLocationsStatusToIConnectionStatus(
                    reduceToSingleAccountLocationStatus(accountLocationsStatus)
                );
            }
            const currentAccountLocationStatus = accountLocationsStatus.find((loc) => loc.locationId === locationId);
            if (!currentAccountLocationStatus) {
                return;
            }
            return mapIAccountLocationsStatusToIConnectionStatus(currentAccountLocationStatus);
        }

        const st = await fetchAccountLocationsStatus(accountId, locationId);
        setStatus(st);
    }, [accountId, locationId, connectionStatusProvided]);

    useEffect(() => {
        if (!accountId) {
            setLevel("error");
            setMessage("No account");
            return;
        }
        if (!status) {
            return;
        }

        const { statusLevel, statusMessage, statusDestination } = getConnectionStatusLevelMessage(
            locationId,
            status,
            {
                missingBankLink,
                restrictedBankLink,
                pausedBankInUseLink,
                missingTaxProfileLink,
                failingTaxProfileLink,
            },
            statusLevelOverride
        );

        setLevel(statusLevel);
        setMessage(statusMessage);
        setDestination(statusDestination);

        if (status.pausedWithUnpauseDate) {
            setTooltip(
                () => `Your daily set-asides are scheduled to resume pulling funds on ${toDisplayDateString(
                    status.pausedWithUnpauseDate!
                )} for
                    ${
                        status.pausedWithUnpauseDate
                            ? toDisplayDateString(status.pausedWithUnpauseDate.minus({ days: 1 }))
                            : ""
                    } sales tax amounts.`
            );
        } else if (status.hasUntestedTaxCredentials) {
            setTooltip(
                "The DAVO team will verify the tax credential " +
                    "information you provided and will contact you via email " +
                    "if any further action is required on your part."
            );
        }
    }, [status, failingTaxProfileLink, missingBankLink, missingTaxProfileLink, pausedBankInUseLink]);

    useEffect(() => {
        if (hideMessage && message) {
            // If the message isn't displayed, use the message as the tooltip
            setTooltip(message);
        }
    }, [hideMessage, message]);

    if (!level || !message) {
        return null;
    }

    return (
        <StatusDot
            toolTip={tooltip}
            level={level}
            message={hideMessage ? undefined : message}
            destination={destination}
        />
    );
}
