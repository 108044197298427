import { Donut, INavMenuItem } from "@davo/portal-common";
import { Partner } from "@davo/types";
import { Hidden } from "@mui/material";
import React from "react";
import { Accounts } from "./Accounts";
import { AddPartnerLocation } from "./AddPartnerLocation";
import { Dashboard } from "./Dashboard";
import { ManageInvitations } from "./ManageInvitations";
import { hasPermission } from "./util";

export function PartnerDonut({ routes }: { routes: INavMenuItem[] }) {
    return (
        <Donut
            primary={routes}
            header={
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        marginRight: "20px",
                    }}>
                    <Hidden mdDown>
                        <div style={{ flex: 1 }}>DAVO Partner Portal</div>
                    </Hidden>
                </div>
            }
        />
    );
}

export const getPartnerDonutRoutes = ({
    partners,
    permissionsInfo,
}: {
    partners: Partner[];
    permissionsInfo: string[] | undefined;
}) => {
    const routes: INavMenuItem[] = [];

    if (partners.some((x) => !x.restrictShowDashboard)) {
        routes.push({
            name: "Dashboard",
            slug: "dashboard",
            destination: "dashboard",
            element: <Dashboard />,
        });
    }

    routes.push({
        name: "Accounts",
        slug: "accounts",
        destination: "accounts",
        element: <Accounts />,
    });

    if (hasPermission(permissionsInfo, "manage_partner_portal_users")) {
        routes.push({
            name: "Users",
            slug: "users",
            destination: "users",
            element: <ManageInvitations />,
        });
    }

    if (partners.some((x) => !x.restrictAddLocation)) {
        routes.push({
            name: "Add Account",
            slug: "add-location",
            destination: "add-location",
            element: <AddPartnerLocation addType={"account"} />,
        });
    }

    return routes;
};
