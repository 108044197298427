import {
    FormControl,
    FormHelperText,
    InputBaseComponentProps,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from "@mui/material";
import isEqual from "lodash/isEqual";
import React, { MutableRefObject, ReactElement, useState } from "react";

export interface ISelectProps<T> {
    value?: T | null;
    options: T[];
    onChange: (value: T | undefined) => void;
    validate?: (v?: T | null) => string | undefined;
    onBlur?: () => void;
    label?: (value: T) => string | ReactElement;
    style?: React.CSSProperties;
    noneLabel?: string;
    input?: React.ReactElement;
    isDisabled?: boolean;
    title?: string;
    showFullWidth?: boolean;
    margin?: "none" | "dense" | "normal";
    isRequired?: boolean;
    helperText?: string;
    inputRef?: MutableRefObject<HTMLInputElement | undefined>;
    inputProps?: InputBaseComponentProps;
}

export function Select<T extends string | number | object>({
    value,
    options,
    onChange,
    label,
    style,
    onBlur,
    validate,
    noneLabel,
    input,
    isDisabled,
    title,
    showFullWidth = true,
    margin = "normal",
    isRequired = false,
    helperText,
    inputRef,
    inputProps,
    ...rest
}: ISelectProps<T>) {
    const [error, setError] = useState<string | null>();

    const index = value !== null ? options.findIndex((x: any) => isEqual(x, value)) + 1 : 0;
    const handleBlur = () => {
        const e = validate && validate(value);
        setError(e);

        if (onBlur) {
            onBlur();
        }
    };

    return (
        <FormControl fullWidth={showFullWidth} margin={margin} required={isRequired} {...rest}>
            {title && <InputLabel htmlFor={inputProps?.id}>{title}</InputLabel>}
            <MuiSelect
                inputRef={inputRef}
                inputProps={inputProps}
                error={!!error}
                style={style}
                disabled={isDisabled}
                value={String(index !== 0 ? index : noneLabel ? 0 : "")}
                onChange={(e) => onChange(options[+String(e.target.value) - 1])}
                input={input}
                onBlur={() => {
                    validate && handleBlur();
                }}
                label={title}
                required={isRequired}>
                {noneLabel && (
                    <MenuItem key={0} value={0}>
                        {noneLabel}
                    </MenuItem>
                )}
                {options &&
                    options.map((t: any, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                            {label ? label(t) : t.label || t.name || String(t)}
                        </MenuItem>
                    ))}
            </MuiSelect>
            {error && (
                <FormHelperText data-testid={`${inputProps?.id}-helper-text`} style={{ color: "#d32f2f" }}>
                    {error}
                </FormHelperText>
            )}
            {!error && helperText && (
                <FormHelperText data-testid={`${inputProps?.id}-helper-text`}>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
}

export default Select;
