import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";
import { useTaxProfileFormContext } from "../context";

export interface IHeaderContent {
    headerContent?: ReactNode;
}

export const HeaderContent: FunctionComponent<IHeaderContent> = ({ headerContent }) => {
    const { linked, linkedTaxProfileQuestionAnswer, handleAdditionalTaxProfileAnswerChange } =
        useTaxProfileFormContext();

    if (!linked?.jurisdictionUIAdditionalTaxProfile.pageTitle) {
        return <>{headerContent}</>;
    }

    return (
        <div style={{ display: "inline-block" }}>
            <FormControl>
                <Typography data-testid={"additionalTaxProfilePageTitle"} style={{ textAlign: "left" }}>
                    {linked.jurisdictionUIAdditionalTaxProfile.pageTitle}
                </Typography>
                <RadioGroup row value={linkedTaxProfileQuestionAnswer}>
                    <FormControlLabel
                        value="yes"
                        control={
                            <Radio
                                aria-label="Add additional tax profile"
                                color="primary"
                                data-testid={"additionalTaxProfileYes"}
                                onClick={handleAdditionalTaxProfileAnswerChange}
                            />
                        }
                        label="Yes"
                    />
                    <FormControlLabel
                        value="no"
                        control={
                            <Radio
                                aria-label="Don't add additional tax profile"
                                color="primary"
                                data-testid={"additionalTaxProfileNo"}
                                onClick={handleAdditionalTaxProfileAnswerChange}
                            />
                        }
                        label="No"
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
};
