import { del, get, getArray, getOne, post, put } from "@davo/portal-common";
import {
    AccountLogHistory,
    AccountSummary,
    IPartnerLocationData,
    LocationRecord,
    MetaAccountLogHistory,
    MetaAccountRecord,
    POSType,
    Partner,
} from "@davo/types";

export async function getAccountHistory(accountId: string): Promise<AccountLogHistory[]> {
    return getArray(`api/partner/accounts/history/${accountId}`, true, MetaAccountLogHistory);
}

export async function getAccount(accountId: string) {
    return getOne(`api/partner/accounts/${accountId}/`, true, MetaAccountRecord);
}

export const sendPartnerUserInvitation = async (partnerId: string, email: string) => {
    return post<any>(`api/partner/accounts/invite`, { partnerId, email }, true);
};

export const getUserInvitationsForPartner = async (partnerId: string) => {
    return get<any>(`api/partner/accounts/invites/${partnerId}`, true, { partnerId });
};

export const getPartnerUsers = async (partnerId: string) => {
    return get<any>(`api/partner/accounts/users/${partnerId}`, true, { partnerId });
};

export const expirePartnerUserInvitation = async (invitationId: string) => {
    await put(`api/partner/accounts/invitation/expire/${invitationId}`, {}, true);
};

export const removePartnerInvitation = async (invitationId: string) => {
    await del(`api/partner/accounts/invitation/remove/${invitationId}`, true, {});
};

export const removePartnerUser = async (userId: string) => {
    return post<any>(`api/partner/accounts/user/remove/${userId}`, {}, true);
};

export const getPartnersAssociatedToUser = async (userId: string) => {
    return get<Partner[]>(`api/partner/accounts/user/${userId}`, true);
};

export async function addPartnerLocation(data: IPartnerLocationData) {
    return post<LocationRecord>(`api/partner/accounts/add-location`, data, true);
}

export async function getPartnerPosTypes(partnerId: string) {
    return get<POSType[]>(`api/partner/accounts/${partnerId}/pos-types`, true);
}

export async function getAllAccountsForSearchTermPartner(params: { searchTerm?: string; other?: string }) {
    return post(`api/partner/accounts/search`, { searchTerm: params.searchTerm }, true);
}

export async function getAllPartnerAccountSummariesForUser(userId: string) {
    return get<AccountSummary[]>(`api/partner/accounts/summaries/user/${userId}`, true);
}

export async function getRecentPartnerAccounts() {
    return get(`api/partner/accounts/recent`, true, {});
}
