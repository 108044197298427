import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { TextField } from "../TextField";
import { useTaxProfileFormContext } from "../context";

export const LegalName: FunctionComponent = () => {
    const {
        isTakeGiveBack,
        legalNameFieldDefinition,
        linked,
        showLinkedTaxProfileDetails,
        legalNameValidationResults,
        legalNameLabel,
        legalName,
        setLegalName,
        isSubmitting,
        filingType,
        shouldBypassDetails,
    } = useTaxProfileFormContext();

    if (
        !filingType ||
        shouldBypassDetails ||
        isTakeGiveBack ||
        !legalNameFieldDefinition?.isVisible ||
        (linked && (!linked.jurisdictionUIAdditionalTaxProfile.showLegalName || !showLinkedTaxProfileDetails))
    ) {
        return null;
    }

    return (
        <TextField
            data-testid={"legalNameContainer"}
            inputProps={{
                [`data-testid`]: "legalNameInput",
                pattern: legalNameFieldDefinition.validationRegex,
            }}
            InputProps={{
                endAdornment: legalNameFieldDefinition.toolTip && (
                    <Tooltip title={legalNameFieldDefinition.toolTip}>
                        <InfoIcon fontSize={"small"} color="primary" />
                    </Tooltip>
                ),
            }}
            validate={() => legalNameValidationResults}
            className="fs-exclude"
            label={`${legalNameLabel} ${
                legalNameFieldDefinition.helpText ? ` (e.g. ${legalNameFieldDefinition.helpText})` : ""
            }`}
            isDisabled={isSubmitting}
            value={legalName}
            onChange={setLegalName}
            isRequired
        />
    );
};
