import {
    LinkedTaxProfileType,
    LoginCredentialValidationStatus,
    OptionalString,
    PLACEHOLDER_PREFIX,
    validateNotNull,
} from "@davo/types";
import { _trim } from "../util";

export const validateNotPlaceholderPrefixed = (str: string): string | undefined =>
    _trim(str).startsWith(PLACEHOLDER_PREFIX) ? "Name cannot include placeholder prefix" : undefined;

export const validateTpName = (val: string | undefined, isNicknameInUse: boolean) => {
    let validation: string | undefined;

    if (val) {
        validation = isNicknameInUse ? "Nickname in use" : undefined;
        validation = validation ? validation : validateNotPlaceholderPrefixed(val);
    } else {
        validation = validateNotNull(null);
    }
    return validation;
};

export const validateRequiredWithRegex = (
    val: string | null | undefined,
    isRequired: boolean,
    label: string,
    validationRegex?: null | string
) => {
    if (!isRequired) {
        return undefined;
    }

    let validation: string | undefined;

    if (val) {
        const regex = validationRegex && new RegExp(validationRegex, "i");
        const isValid = regex ? regex.test(val) : true;
        validation = isValid ? undefined : `Invalid ${label.toLowerCase()} format`;
    } else {
        validation = validateNotNull(null);
    }
    return validation;
};

export const validateFilingFrequency = (val: OptionalString, linked: LinkedTaxProfileType | undefined) => {
    let validation: string | undefined;

    const isCopyingFromParentTp = linked && !linked.jurisdictionUIAdditionalTaxProfile.showFrequency;

    if (val || isCopyingFromParentTp) {
        validation = undefined;
    } else {
        validation = validateNotNull(null);
    }

    return validation;
};

export const validateLegalName = (
    val: string,
    isRequired: boolean,
    label: string,
    regex: OptionalString,
    linked: LinkedTaxProfileType | undefined
) => {
    let validation: string | undefined;

    const isCopyingFromParentTp = linked && !linked.jurisdictionUIAdditionalTaxProfile.showLegalName;

    if (isCopyingFromParentTp || !!val || !isRequired) {
        validation = validateRequiredWithRegex(val, isRequired, label, regex);
    } else {
        validation = validateNotNull(null);
    }

    return validation;
};

export const validateFein = (
    val: string,
    isRequired: boolean,
    label: string,
    regex: OptionalString,
    linked: LinkedTaxProfileType | undefined
) => {
    let validation: string | undefined;

    const isCopyingFromParentTp = linked && !linked.jurisdictionUIAdditionalTaxProfile.showFederalTaxId;

    if (isCopyingFromParentTp || !!val || !isRequired) {
        validation = validateRequiredWithRegex(val, isRequired, label, regex);
    } else {
        validation = validateNotNull(null);
    }

    return validation;
};

export const validateFilingType = (val: OptionalString, isRequired: boolean) => {
    let validation: string | undefined;

    if (val || !isRequired) {
        validation = undefined;
    } else {
        validation = validateNotNull(null);
    }

    return validation;
};

export const validateCredsCheckResults = (val: LoginCredentialValidationStatus, isRequired: boolean) => {
    let validation: string | undefined;

    if (!isRequired) {
        validation = undefined;
    } else if (isRequired && !!val && val !== "validating" && val !== "invalid") {
        validation = undefined;
    } else {
        validation = `Please test login credentials`;
    }

    return validation;
};

export const getReasonInvalid = (
    areAllStandardFieldsValid: boolean,
    areAllCredentialFieldsValid: boolean,
    isCredsValidationRequired: boolean,
    credsValidationStatus: LoginCredentialValidationStatus
) => {
    if (!areAllStandardFieldsValid || !areAllCredentialFieldsValid) {
        return "Invalid fields";
    }

    const result = validateCredsCheckResults(credsValidationStatus, isCredsValidationRequired);

    if (isCredsValidationRequired && !!result) {
        return result;
    } else {
        return "";
    }
};

export const FORM_FIELD_LABELS = {
    taxProfileName: "Return Nickname",
    filingFrequency: "Filing Frequency",
    filingType: "Filing Type",
};

export const FORM_ERROR_LABELS = {
    failingTpCreds: "Please test login credentials",
};
