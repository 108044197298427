import {
    debounce,
    DEFAULT_SEARCH_DELAY,
    formatAccountSearchResults,
    getSelectTheme,
    MIN_SEARCH_CHAR_THRESHOLD,
    RecentlyViewedAccounts,
} from "@davo/portal-common";
import { AccountSearchRecord } from "@davo/types";
import { Checkbox, FormControlLabel, FormGroup, Theme, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import useAsyncEffect from "use-async-effect";
import { AccountsFilter } from "./AccountsFilter";
import { getAllAccountsForSearchTermPartner, getRecentPartnerAccounts } from "./services";

export function PartnerAccountSearch() {
    const navigate = useNavigate();
    const themeMUI: Theme = useTheme();

    const [showAllAccounts, setShowAllAccounts] = useState<boolean>(false);
    const [recentlyViewed, setRecentlyViewed] = useState<string[]>();

    const formThemeColors = getSelectTheme(themeMUI);

    useAsyncEffect(async () => {
        setRecentlyViewed(await getRecentPartnerAccounts());
    }, []);

    const loadResults = async (input: string, callback: (options: AccountSearchRecord[]) => void) => {
        const trimmedValue = input && input.trim();

        return trimmedValue.length >= MIN_SEARCH_CHAR_THRESHOLD
            ? getAllAccountsForSearchTermPartner({ searchTerm: input }).then((data) => callback(data))
            : callback([]);
    };

    const debouncedLoadResults = debounce(loadResults, DEFAULT_SEARCH_DELAY);

    const handleSelectionChange = (selectedOption: any) => {
        navigate(`/accounts/${selectedOption.id}`);
    };

    const MenuList = (props: any) => {
        return (
            <components.MenuList {...props}>
                {props.children}
                {props.options.length <= 8 ? (
                    <div />
                ) : (
                    <div style={{ textAlign: "center" }}>{`Showing first 8 results, narrow search`}</div>
                )}
            </components.MenuList>
        );
    };

    return (
        <>
            <div>
                <h2>Search For Account</h2>
                {!showAllAccounts && (
                    <AsyncSelect
                        id={"partnerAccountSearchContainer"}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...formThemeColors,
                            },
                        })}
                        components={{ MenuList }}
                        onChange={handleSelectionChange}
                        formatOptionLabel={formatAccountSearchResults}
                        placeholder={"Enter search term..."}
                        defaultOptions={false}
                        cacheOptions={false}
                        autoFocus
                        tabSelectsValue={false}
                        loadOptions={debouncedLoadResults}
                        closeMenuOnSelect={false}
                        noOptionsMessage={() => "No results"}
                    />
                )}
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showAllAccounts}
                                onChange={() => setShowAllAccounts(!showAllAccounts)}
                                data-testid={"show-all-accounts"}
                            />
                        }
                        label="Show All Accounts"
                    />
                </FormGroup>
                {showAllAccounts && <AccountsFilter />}
            </div>
            {recentlyViewed && <RecentlyViewedAccounts recentlyViewed={recentlyViewed} />}
        </>
    );
}
