import React, { FunctionComponent } from "react";
import { FORM_FIELD_LABELS } from ".";
import { TextField } from "../TextField";
import { useTaxProfileFormContext } from "../context";

export const TaxProfileName: FunctionComponent = () => {
    const { linked, isSubmitting, filingType, shouldBypassDetails, tpNameValidationResults, name, setName } =
        useTaxProfileFormContext();

    if (!filingType || shouldBypassDetails || linked) {
        return null;
    }

    return (
        <TextField
            validate={() => tpNameValidationResults}
            data-testid={"tpNameContainer"}
            inputProps={{
                [`data-testid`]: "tpNameInput",
            }}
            label={`${FORM_FIELD_LABELS.taxProfileName} (for your purposes)`}
            isDisabled={isSubmitting}
            value={name}
            onChange={setName}
            isRequired
        />
    );
};
