import {
    ACHTransactionStatus,
    IManualActivity,
    LocationRecord,
    moneyFromCents,
    toDisplayDateString,
} from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { ColumnDef } from "@tanstack/table-core/src/types";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { ACHStatusLozenge } from "./Lozenge";
import { ReactTable8 } from "./ReactTable8";

export function ManualActivityTable({
    additionalSalesManualActivity,
    filterLocations,
}: {
    additionalSalesManualActivity:
        | (IManualActivity & { manualSetAsideStatus: string | undefined })[]
        | null
        | undefined;
    filterLocations?: LocationRecord[];
}) {
    type RemappedManualActivity = IManualActivity & { manualSetAsideStatus?: string; locationName?: string } & {
        city?: string;
    };
    const [canExpandManual, setCanExpandManual] = useState<boolean>(true);
    const [remappedManualActivities, setRemappedManualActivities] = useState<RemappedManualActivity[] | undefined>(
        undefined
    );

    useEffect(() => {
        if (additionalSalesManualActivity && additionalSalesManualActivity.length > 0 && filterLocations) {
            const mapManualActivityLocationName = (val: IManualActivity[]) => {
                return val.map((v: any) => {
                    const location = filterLocations.find((l) => l.id === v.locationId);
                    return {
                        ...v,
                        locationName: location?.name ?? "",
                        city: location?.city,
                    };
                });
            };

            setRemappedManualActivities(mapManualActivityLocationName(additionalSalesManualActivity));
        }
    }, [additionalSalesManualActivity, filterLocations]);

    const columnHelper = createColumnHelper<RemappedManualActivity>();

    const columns = useMemo(() => {
        return [
            columnHelper.accessor("locationName", {
                header: "Location",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left", width: "150px", overflow: "ellipse" }}>
                        {data.cell.row.original.locationName} - {data.cell.row.original.city}
                    </div>
                ),
            }),
            columnHelper.accessor("sales", {
                header: "Sales",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{moneyFromCents(data.cell.row.original.sales)}</div>,
            }),
            columnHelper.accessor("tax", {
                header: "Tax",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{moneyFromCents(data.cell.row.original.tax)}</div>,
            }),
            columnHelper.accessor("periodDay", {
                header: "Period",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>
                        {DateTime.fromSQL(data.cell.row.original.periodDay.toString()).toFormat("LLL  yyyy")}
                    </div>
                ),
            }),
            columnHelper.accessor("manualSetAsideStatus", {
                header: "Set Aside",
                enableSorting: false,
                cell: (data) => ACHStatusLozenge(data.getValue() as ACHTransactionStatus),
            }),
            columnHelper.accessor("created", {
                header: "Updated Date",
                enableSorting: false,
                cell: (data) => (
                    <div style={{ textAlign: "left" }}>
                        {toDisplayDateString(data.cell.row.original.created.toSQLDate())}
                    </div>
                ),
            }),
            columnHelper.accessor("updatedBy", {
                header: "Updated By",
                enableSorting: false,
            }),
            columnHelper.accessor("note", {
                header: "Note",
                enableSorting: false,
                cell: (data) => <div style={{ textAlign: "left" }}>{data.row.original.note}</div>,
            }),
        ];
    }, []);

    if (!remappedManualActivities || remappedManualActivities.length === 0) {
        return null;
    }

    return (
        <Accordion
            slotProps={{ transition: { unmountOnExit: true } }}
            style={{ border: "none", boxShadow: "none" }}
            expanded={canExpandManual}>
            <AccordionSummary
                onClick={() => setCanExpandManual(!canExpandManual)}
                expandIcon={<ExpandMoreIcon />}
                style={{ fontSize: "20px" }}>
                {"Additional Sales Tax Amounts"}
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ width: "100%" }}>
                    <ReactTable8<RemappedManualActivity>
                        columns={columns as ColumnDef<RemappedManualActivity>[]}
                        data={remappedManualActivities ?? []}
                        options={{
                            hideToolbar: true,
                        }}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
