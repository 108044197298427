import { BodyText, Loading, ReactTable, useAsyncRefresh, useLoginContext } from "@davo/portal-common";
import {
    DashboardPartnerFilingsTotal,
    DashboardPartnerSubscriptionSummary,
    DashboardPartnerUnFilingsByDueDate,
} from "@davo/types";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import { Card, CardContent, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { getFiledThisSessionTotal, getSubscriptionsCount, getUnFiledThisSessionByDueDate } from "./services";

export function Dashboard() {
    const theme = useTheme();
    const useStyles = makeStyles(() => ({
        card: {
            height: "375px",
        },
        highlight: theme.palette.mode === "dark" ? { color: "#fff" } : { color: "#000" },
    }));

    const loginContext = useLoginContext();
    const classes = useStyles();
    const [summary, setSummary] = useState<DashboardPartnerSubscriptionSummary>();
    const [filed, setFiled] = useState<DashboardPartnerFilingsTotal[]>();
    const [unFiledThisSessionByDueDate, setUnFiledThisSessionByDueDate] =
        useState<DashboardPartnerUnFilingsByDueDate[]>();

    useAsyncRefresh(async () => {
        if (loginContext.user) {
            setSummary(await getSubscriptionsCount());
            const filedThisSession = await getFiledThisSessionTotal();
            setFiled(filedThisSession);
            if (!filedThisSession) {
                setFiled([{ filingDueDate: "Session", unfiled: 0, filed: 0 }]);
            }
            setUnFiledThisSessionByDueDate(await getUnFiledThisSessionByDueDate());
        }
    });

    const cards = () => {
        return (
            <>
                <Grid container direction="row" spacing={1} style={{ marginTop: "5px" }}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardContent>
                                {summary ? (
                                    <>
                                        <div>
                                            <Tooltip
                                                title="Count of locations that are active. Connects are any active locations added today"
                                                placement="bottom-end">
                                                <HelpTwoToneIcon
                                                    fontSize="small"
                                                    color="primary"
                                                    style={{ verticalAlign: "middle", float: "right" }}
                                                />
                                            </Tooltip>
                                            <BodyText>Active Subscriptions</BodyText>
                                            <Typography variant="h2">{summary.totalSubs}</Typography>
                                        </div>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            style={{ textDecoration: "underline" }}>
                                            Today&apos;s spotlight
                                        </Typography>
                                        <BodyText>Subscriptions:</BodyText>
                                        <Typography variant="h4">{summary.todaySubs}</Typography>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Tooltip
                                    title="By due date and jurisdiction, count that is unfiled.   Each filing is assigned to a DAVO tax compliance analyst."
                                    placement="bottom-end">
                                    <HelpTwoToneIcon
                                        fontSize="small"
                                        color="primary"
                                        style={{ verticalAlign: "middle", float: "right" }}
                                    />
                                </Tooltip>
                                {unFiledThisSessionByDueDate ? (
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Due",
                                                accessor: "filingDueDate",
                                                disableSortBy: true,
                                            },
                                            {
                                                Header: "Jurisdiction",
                                                accessor: "jurisdiction",
                                                disableSortBy: true,
                                            },
                                            {
                                                Header: "Unfiled",
                                                accessor: "unfiled",
                                                disableSortBy: true,
                                            },
                                        ]}
                                        data={unFiledThisSessionByDueDate}
                                        options={{
                                            pageSize: 6,
                                            pageSizeOptions: [6],
                                            hideToolbar: true,
                                        }}
                                    />
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Tooltip
                                    title="By date, count of unfiled and filed filings for a given period.  The last row is summary of the count of all unfiled and filed filings for the given period."
                                    placement="bottom-end">
                                    <HelpTwoToneIcon
                                        fontSize="small"
                                        color="primary"
                                        style={{ verticalAlign: "middle", float: "right" }}
                                    />
                                </Tooltip>
                                {filed ? (
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Due",
                                                accessor: "filingDueDate",
                                                disableSortBy: true,
                                            },
                                            {
                                                Header: "Unfiled",
                                                accessor: "unfiled",
                                                disableSortBy: true,
                                            },
                                            {
                                                Header: "Filed",
                                                accessor: "filed",
                                                disableSortBy: true,
                                            },
                                        ]}
                                        data={filed}
                                        options={{
                                            pageSize: 6,
                                            pageSizeOptions: [6],
                                            hideToolbar: true,
                                        }}
                                    />
                                ) : (
                                    <Loading />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        );
    };

    return <div>{cards()}</div>;
}
