import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useTaxProfileFormContext } from "../context";
import { TextField } from "../TextField";

export const StateTaxId: FunctionComponent = () => {
    const {
        isSubmitting,
        stateTaxIdInputRef,
        stateTaxIdFieldDefinition,
        filingType,
        shouldBypassDetails,
        isTakeGiveBack,
        linked,
        showLinkedTaxProfileDetails,
        stateTaxIdValidationResults,
        taxIdLabel,
        stateTaxId,
        setStateTaxId,
    } = useTaxProfileFormContext();

    if (
        !filingType ||
        shouldBypassDetails ||
        isTakeGiveBack ||
        !stateTaxIdFieldDefinition ||
        !stateTaxIdFieldDefinition.isVisible ||
        (linked && (!linked.jurisdictionUIAdditionalTaxProfile.showStateTaxId || !showLinkedTaxProfileDetails))
    ) {
        return null;
    }

    return (
        <div style={{ marginBottom: "8px" }}>
            <TextField
                inputRef={stateTaxIdInputRef}
                data-testid={"stateTaxIdContainer"}
                InputProps={{
                    endAdornment: stateTaxIdFieldDefinition.toolTip && (
                        <Tooltip title={stateTaxIdFieldDefinition.toolTip}>
                            <InfoIcon fontSize={"small"} color="primary" />
                        </Tooltip>
                    ),
                }}
                inputProps={{
                    [`data-testid`]: "stateTaxIdInput",
                    pattern: stateTaxIdFieldDefinition.validationRegex,
                }}
                validate={() => stateTaxIdValidationResults}
                className="fs-exclude"
                label={`${taxIdLabel} ${
                    stateTaxIdFieldDefinition.helpText ? ` (e.g. ${stateTaxIdFieldDefinition.helpText})` : ""
                }`}
                isDisabled={isSubmitting}
                value={stateTaxId}
                onChange={setStateTaxId}
                isRequired
            />
            <a
                href="https://www.davosalestax.com/understanding-your-business-state-tax-id/"
                target="_blank"
                rel="noreferrer">
                Questions about your {taxIdLabel}?
            </a>
        </div>
    );
};
