import { Loading } from "@davo/portal-common";
import { AccountRecord, LocationRecord } from "@davo/types";
import sortBy from "lodash/sortBy";
import React from "react";
import { LocationDetail } from "./LocationDetail";

interface IAccountLocationsDetailType {
    account?: AccountRecord;
    locations?: LocationRecord[];
}

export function AccountLocationsDetail({ account, locations }: IAccountLocationsDetailType) {
    if (!account || !locations) {
        return <Loading />;
    }
    const react = [];
    const sortedLocations = sortBy(locations, ["active", "name", "address1", "id"]);

    let i = 0;
    for (const location of sortedLocations) {
        react.push(<LocationDetail key={i} location={location} />);
        i++;
    }

    return <div>{react}</div>;
}
