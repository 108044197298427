import { auth, get, post } from "@davo/portal-common";
import { IPartnerInvitationInfo } from "@davo/types";

export const getPartnerInvitationInfo = async (code: string) => {
    return get<IPartnerInvitationInfo>(`api/partner/invitation/retrieve/${code}`, false, false);
};

export const redeemPartnerInvitation = async (code: string) => {
    await post(`api/partner/invitation/redeem/${code}`, {}, true);
    await auth.whoAmI();
};
