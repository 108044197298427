import { CssBaseline, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ILoginForm, LoginForm } from "./LoginForm";

const styles = makeStyles((theme: Theme) => ({
    main: {
        width: "auto",
        display: "block", // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
}));

export function LoginPage(props: ILoginForm) {
    const classes = styles();
    return (
        <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
                <LoginForm {...props} />
            </Paper>
        </main>
    );
}
