import { emptyDefaultCommonConfig, ICommonPortalConfigType } from "@davo/types";
import { createContext, useContext } from "react";

export interface ICommonPortalConfigContext {
    commonPortalConfigInfo: ICommonPortalConfigType;
}

export const CommonPortalConfigContextDefaultValue: ICommonPortalConfigContext = {
    commonPortalConfigInfo: emptyDefaultCommonConfig,
};
export const CommonPortalConfigContext = createContext(CommonPortalConfigContextDefaultValue);
export const useCommonPortalConfigContext = () => useContext(CommonPortalConfigContext);
