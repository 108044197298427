import { DateTime } from "luxon";
import { BusinessDay } from "./BusinessDay";
import { User } from "./User";

export interface ISubscriptionType {
    id: string;
    description: string;
    charge: number; // integer pennies
    chargeArr: number; // integer pennies, Annual Recurring Revenue
    duration: number; // integer months
    active: boolean;
    tag: string;
    effectiveDate: BusinessDay;
}

export interface ISubscription {
    id: string;
    locationId: string;
    subscriptionTypeId: string;
    startDate: BusinessDay;
    created: DateTime;
}

export interface ISubscriptionAndType extends ISubscription {
    type: ISubscriptionType;
}

export interface ISubscriptionBilling {
    id: string;
    subscriptionId: string;
    billingEventId: string;
    periodStart: BusinessDay;
}

export enum DiscountTypes {
    "flat" = "flat",
    "percentage" = "percentage",
}

export type DiscountType = keyof typeof DiscountTypes;

export interface ISubscriptionDiscount {
    id: string;
    locationId: string;
    discountedUntil?: null | DateTime; // null means in perpetuity
    discountReason: string;
    type: DiscountType;
    amount: number; // integer pennies if type is flat, otherwise integer >= 1 and <= 99 for percentage
    active: boolean;
    created: DateTime;
    createdBy: string;
    user?: null | User;
}

export interface IAccountDiscount {
    id: string;
    accountId: string;
    discountedUntil?: null | DateTime; // null means in perpetuity
    discountReason: string;
    type: DiscountType;
    amount: number; // integer pennies if type is flat, otherwise integer >= 1 and <= 99 for percentage
    active: boolean;
    created: DateTime;
    createdBy: string;
    user?: null | User;
}
