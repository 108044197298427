import { MetaAddress, MetaFilingDetails, MetaTaxRateInfo } from "./Account";
import { BOOLEAN, DATETIME, JSON_FIELD, NUMBER, OBJECT, ResolveObject, STRING } from "./MetaType";
import { MetaPartner } from "./Partner";

export const MetaEfileInfo = {
    legalName: STRING(),
    stateTaxId: STRING().optional(),
    federalTaxId: STRING().optional(),
    credentials: JSON_FIELD(),
    groupLoginUsed: BOOLEAN(),
    groupLoginCreds: JSON_FIELD().optional(),
};

export type EFileInfo = ResolveObject<typeof MetaEfileInfo>;

export const MetaFundingAccountInfo = {
    routingNumber: STRING(),
    accountNumber: STRING(),
    bank: OBJECT({
        name: STRING(),
    }),
};

export type FundingAccountInfo = ResolveObject<typeof MetaFundingAccountInfo>;

export const MetaEfileAddressInfo = {
    ...MetaAddress,
    locationId: STRING(),
    externalStateLocationId: STRING().optional(),
};
export type EFileAddressInfo = ResolveObject<typeof MetaEfileAddressInfo>;

export const MetaFilingInfo = {
    filingId: STRING(),
    jurisdiction: STRING(),
    filingFrequency: STRING(),
    periodStart: DATETIME(),
    periodEnd: DATETIME(),
    filingDueDate: DATETIME(),
    grossSales: NUMBER(), // dollars with decimal pennies
    grossReturns: NUMBER(), // dollars with decimal pennies
    grossNonTaxableSales: NUMBER(), // dollars with decimal pennies - optional for D30 to force calculation from taxes
    grossTaxableSales: NUMBER(), // dollars with decimal pennies - optional for D30 to force calculation from taxes
    grossSalesTax: NUMBER(), // dollars with decimal pennies
    taxes: OBJECT(MetaTaxRateInfo).array([]),
    otherRemittances: OBJECT({
        locationId: STRING(),
        nestedTaxRemitted: NUMBER(), // dollars with decimal pennies
        nestedAdjustment: NUMBER(), // dollars with decimal pennies
    }).array([]),
    funding: OBJECT({
        settled: NUMBER(), // dollars with decimal pennies
        pending: NUMBER(), // dollars with decimal pennies
        remitted: NUMBER(), // dollars with decimal pennies
        rejected: NUMBER(), // dollars with decimal pennies
        prepaid: NUMBER(), // dollars with decimal pennies
    }),
    excessRemittanceMaximum: NUMBER(),
    tenderCash: NUMBER().optional(),
    tenderCredit: NUMBER().optional(),
    addresses: OBJECT(MetaEfileAddressInfo).array([]),
    fundingAccount: OBJECT(MetaFundingAccountInfo).optional(),
    efile: OBJECT(MetaEfileInfo),
    posType: STRING(),
    partner: OBJECT(MetaPartner).optional(),
};
export type FilingInfo = Omit<ResolveObject<typeof MetaFilingDetails>, "filingDueDate" | "periodStart" | "periodEnd"> &
    ResolveObject<typeof MetaFilingInfo>;

export const AF_OPEN = "10 open";
export const AF_PROCESSING = "20 processing";
export const AF_BULK_QUEUED = "25 bulk queued";
export const AF_BULK_PROCESSING = "25 bulk processing";
export const AF_TESTED = "50 tested";
export const AF_ERRORED = "60 errored";
export const AF_ERRORED_VALIDATION = "61 validation errored";
export const AF_FILED = "70 filed";
export const AF_COMPLETED = "90 completed";
export const AF_CANCELLED = "91 cancelled";
export const AF_UNTESTED = "900 untested";

export const afTerminalState = (state: FilingQueueState) =>
    state === AF_TESTED ||
    state === AF_ERRORED ||
    state === AF_ERRORED_VALIDATION ||
    state === AF_FILED ||
    state === AF_COMPLETED ||
    state === AF_CANCELLED;

export type FilingQueueState =
    | typeof AF_OPEN
    | typeof AF_PROCESSING
    | typeof AF_BULK_QUEUED
    | typeof AF_BULK_PROCESSING
    | typeof AF_TESTED
    | typeof AF_ERRORED
    | typeof AF_ERRORED_VALIDATION
    | typeof AF_FILED
    | typeof AF_COMPLETED
    | typeof AF_CANCELLED;

export const AutofilerStatusFilterDisplayNames = {
    "10 open": "Queued",
    "25 bulk processing": "Bulk processing",
    "50 tested": "Tested",
    "60 errored": "Error",
    "61 validation errored": "Validation",
    "900 untested": "Untested",
} as { [key: string]: string };

export const MetaFilingResult = {
    taxDue: NUMBER().optional(),
    discount: NUMBER().optional(),
    fees: NUMBER().optional(),
    remittance: NUMBER().optional(),
    remittancePart2: NUMBER().optional(),
    remittancePart3: NUMBER().optional(),
    remittancePart4: NUMBER().optional(),
    remittancePart5: NUMBER().optional(),
    adjustment: NUMBER().optional(),
    outstanding: NUMBER().optional(),
    errors: STRING().array().optional(),
    warnings: STRING().array().optional(),
    pdfs: STRING().array().optional(),
    errorPDFs: STRING().array().optional(),
    detail: JSON_FIELD().optional(),
    filed: BOOLEAN().optional(),
    bulkTaskId: STRING().optional(),
    bulkTaskStarted: NUMBER().optional(),
};

export type FilingResult = ResolveObject<typeof MetaFilingResult>;

export const MetaFilingQueueEntry = {
    filingId: STRING(),
    jurisdiction: STRING(),
    test: BOOLEAN(),
    state: STRING<FilingQueueState>(),
    message: STRING(),
    result: OBJECT(MetaFilingResult).optional(),
    autofiledById: STRING().optional(),
    filingStarted: NUMBER().optional(),
    lastModified: NUMBER().optional(),
};

export type AutofilerQueueEntry = ResolveObject<typeof MetaFilingQueueEntry>;

export interface AuthCodes {
    [key: string]: string;
}

export enum BulkStatuses {
    "SUCCESS",
    "ERROR",
    "RETRY",
}

export type BulkStatus = keyof typeof BulkStatuses;

export type BulkStatusCheck = { status: BulkStatus; errorMessage?: string };
