import { TaxProfileCredentials, TaxProfileFailureReasonsDescriptions } from "@davo/types";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert, AlertTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { FunctionComponent, MouseEvent, useCallback, useState } from "react";
import { CredentialFlavorSelect } from "./CredentialFlavorSelect";
import { TextField } from "./TextField";
import { ValidateCredentialsStatus } from "./ValidateCredentialsStatus";
import { ValidateTaxCredentialsButton } from "./ValidateTaxCredentialsButton";

import { useTaxProfileFormContext } from "./context";

export const CredentialsEntry: FunctionComponent = () => {
    const {
        isTakeGiveBack,
        linked,
        showLinkedTaxProfileDetails,
        isSubmitting,
        isCheckingCreds,
        filingType,
        shouldBypassDetails,
        isCredsValidationRequired,
        isGroupLoginUsed,
        isUsernameRequired,
        isPasswordRequired,
        isPinRequired,
        siteUrl,
        nameString,
        hasFailingCredentials,
        failingCredentialsMessage,
        failingReason,
        isGroupLoginRequired,
        selectedFlavor,
        handleCredentialFlavorChange,
        usernameFieldDefinition,
        usernameValidationResults,
        passwordFieldDefinition,
        passwordValidationResults,
        pinFieldDefinition,
        pinValidationResults,
        credentials,
        setCredentials,
        credsValidationStatus,
        areAllCredentialFieldsValid,
        abortCredsCheck,
        checkCreds,
        areExistingCredentialsReused,
        formLevelValidationMsg,
    } = useTaxProfileFormContext();

    const [hidePassword, setHidePassword] = useState<boolean>(true);
    const handleClickHidePassword = () => setHidePassword((hide) => !hide);
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => event.preventDefault();

    const credentialUpdateHandler = useCallback(
        (v: TaxProfileCredentials) => {
            setCredentials(v);
        },
        [setCredentials]
    );

    if (
        !filingType ||
        shouldBypassDetails ||
        isTakeGiveBack ||
        isGroupLoginUsed ||
        (!isUsernameRequired && !isPasswordRequired && !isPinRequired) ||
        (!!linked && !showLinkedTaxProfileDetails)
    ) {
        return null;
    }

    return (
        <div data-testid={"credentialsEntryFields"}>
            {!linked && (
                <div>
                    <Typography className={"da-tp-form-heading"} component="h3">
                        State Login
                    </Typography>

                    {filingType.url && (isUsernameRequired || isPasswordRequired || isPinRequired) && (
                        <Typography gutterBottom={true}>
                            Enter your {filingType.siteName ?? "site"} login details for the{" "}
                            {isGroupLoginRequired ? (
                                <Tooltip
                                    placement={"top"}
                                    title={
                                        "The information provided will be used to add your business to DAVO's group account with this site"
                                    }>
                                    <a href={siteUrl} target="_blank" rel="noreferrer">
                                        {nameString}
                                    </a>
                                </Tooltip>
                            ) : (
                                <a href={siteUrl} target="_blank" rel="noreferrer">
                                    {nameString}
                                </a>
                            )}{" "}
                            so DAVO can file for you.
                        </Typography>
                    )}
                </div>
            )}

            <CredentialFlavorSelect
                selectedFlavor={selectedFlavor}
                handleCredentialFlavorChange={handleCredentialFlavorChange}
                filingType={filingType}
            />

            {isUsernameRequired && usernameFieldDefinition && (
                <TextField
                    margin={"dense"}
                    data-testid={"usernameContainer"}
                    inputProps={{
                        [`data-testid`]: "usernameInput",
                        pattern: usernameFieldDefinition.validationRegex,
                    }}
                    InputProps={{
                        endAdornment: usernameFieldDefinition.toolTip && (
                            <Tooltip title={usernameFieldDefinition.toolTip}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </Tooltip>
                        ),
                    }}
                    value={credentials.username ?? ""}
                    validate={() => usernameValidationResults}
                    isDisabled={isSubmitting || isCheckingCreds}
                    className="fs-exclude"
                    onChange={(v) => credentialUpdateHandler({ ...credentials, username: v })}
                    label={`${usernameFieldDefinition.label} ${
                        usernameFieldDefinition.helpText ? ` (e.g. ${usernameFieldDefinition.helpText})` : ""
                    }`}
                    isRequired
                />
            )}

            {isPasswordRequired && passwordFieldDefinition && (
                <TextField
                    margin={"dense"}
                    data-testid={"passwordContainer"}
                    inputProps={{
                        [`data-testid`]: "passwordInput",
                        pattern: passwordFieldDefinition.validationRegex,
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                {passwordFieldDefinition.toolTip && (
                                    <Tooltip title={passwordFieldDefinition.toolTip}>
                                        <InfoIcon fontSize={"small"} color="primary" />
                                    </Tooltip>
                                )}
                                <IconButton
                                    data-testid={"togglePwdVisibility"}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickHidePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </>
                        ),
                    }}
                    value={credentials.password ?? ""}
                    validate={() => passwordValidationResults}
                    className="fs-exclude"
                    isDisabled={isSubmitting || isCheckingCreds}
                    isPassword={hidePassword}
                    onChange={(v) => credentialUpdateHandler({ ...credentials, password: v })}
                    label={`${passwordFieldDefinition.label} ${
                        passwordFieldDefinition.helpText ? ` (e.g. ${passwordFieldDefinition.helpText})` : ""
                    }`}
                    isRequired
                />
            )}

            {isPinRequired && pinFieldDefinition && (
                <TextField
                    margin={"dense"}
                    data-testid={"pinContainer"}
                    inputProps={{
                        [`data-testid`]: "pinInput",
                        pattern: pinFieldDefinition.validationRegex,
                    }}
                    InputProps={{
                        endAdornment: pinFieldDefinition.toolTip && (
                            <Tooltip title={pinFieldDefinition.toolTip}>
                                <InfoIcon fontSize={"small"} color="primary" />
                            </Tooltip>
                        ),
                    }}
                    value={credentials.pin ?? ""}
                    validate={() => pinValidationResults}
                    className="fs-exclude"
                    onChange={(v) => credentialUpdateHandler({ ...credentials, pin: v })}
                    label={`${pinFieldDefinition.label} ${
                        pinFieldDefinition.helpText ? ` (e.g. ${pinFieldDefinition.helpText})` : ""
                    }`}
                    isRequired
                    isDisabled={isSubmitting || isCheckingCreds}
                />
            )}

            {filingType.twoFactorRequired && (
                <Alert
                    data-testid={"twoFactorRequiredMsg"}
                    style={{ margin: "8px 0" }}
                    icon={<PhonelinkLockIcon />}
                    variant={"filled"}
                    color={"info"}>
                    If your state account requires 2-factor authentication, you may need to provide DAVO support with
                    the code for first-time setup
                </Alert>
            )}

            {isCredsValidationRequired && (
                <Stack justifyContent={"space-between"} direction="column" spacing={1}>
                    <ValidateTaxCredentialsButton
                        formLevelValidationMsg={formLevelValidationMsg}
                        areExistingCredentialsReused={areExistingCredentialsReused}
                        credsValidationStatus={credsValidationStatus}
                        jurisdictionFilingTypeId={filingType.id}
                        areAllCredentialFieldsValid={areAllCredentialFieldsValid}
                        validateCredentials={checkCreds}
                    />

                    {credsValidationStatus && (
                        <ValidateCredentialsStatus
                            credsValidationStatus={credsValidationStatus}
                            abortCredsCheck={abortCredsCheck}
                        />
                    )}
                </Stack>
            )}

            {hasFailingCredentials && credsValidationStatus === undefined && (
                <Alert
                    icon={<ErrorIcon />}
                    variant={"outlined"}
                    severity={"error"}
                    style={{ margin: "6px 0" }}
                    data-testid={"failingMsgContainer"}>
                    {failingReason && (
                        <AlertTitle data-testid={"failingReasonSummary"}>
                            {TaxProfileFailureReasonsDescriptions[failingReason]}
                        </AlertTitle>
                    )}
                    {failingCredentialsMessage && (
                        <em data-testid={"failingCredentialsMessage"}>{failingCredentialsMessage}</em>
                    )}
                </Alert>
            )}
        </div>
    );
};
