export enum POSTypes {
    "acadia",
    "bookkeep",
    "cake",
    "clover",
    "epos-now",
    "heartland-restaurant",
    "heartland-retail",
    "integrator",
    "lavu",
    "lightspeed-e-series",
    "lightspeed-k-series",
    "lightspeed-l-series",
    "lightspeed-r-series",
    "lightspeed-u-series",
    "lightspeed-x-series",
    "mindbody",
    "ovvi",
    "par-brink",
    "pay-anywhere",
    "poynt",
    "quickbooks",
    "rasi",
    "restaurant365",
    "revel",
    "shift4",
    "spoton-restaurant",
    "spoton-retail",
    "square",
    "table-needs",
    "toast",
    "touchbistro",
}

export type POSType = keyof typeof POSTypes;

/**
 * pos providers that manage the subscriptions.
 */
// if this list changes, there ARE reports that will also need to change!!!
export const subscriptionManagedByPOS: string[] = ["clover", "poynt", "table-needs", "cake", "pay-anywhere"];

export const notDavoBilledPOSes: string[] = [...subscriptionManagedByPOS, "integrator", "bookkeep"];
/**
 * pos providers in subscriptionManagedByPOS that can still be waived
 */
export const waiveFeeManagedByPOS: string[] = ["clover"];
/**
 * pos providers in subscriptionManagedByPOS and we still send an invoice
 */
export const completeBillingManagedByPOS: string[] = [];

export const waiveTermsAndConditionsByPOS: string[] = ["clover", "pay-anywhere"];
export const meteredBilling: string[] = ["clover"];
export const disconnectedByPOS: string[] = ["poynt", "cake", "pay-anywhere"];
export const cloverActiveBillingStatuses: string[] = ["ACTIVE", "SUPPRESSED"];
export const cloverInvalidPlans: string[] = ["PAYMENTS", "PAYMENTS_PLUS", "NO_HARDWARE"];

export interface INewLocationType {
    bankAccountId?: null | string;
    businessName?: null | string;
    businessEmail?: null | string;
    businessPhone?: null | string;
    name: string;
    posType: POSType;
    posMerchant: string;
    posLocation: string;
    posMid?: null | string;
    address1?: null | string;
    address2?: null | string;
    city?: null | string;
    state?: null | string;
    zip?: null | string;
    timezone?: null | string;
    closeoutOffset: string;
    token?: null | string;
    fromDevice?: null | boolean;
    integratorId?: null | string;
    accountId?: null | string;
    deviceOS?: null | string;
    preAccount: boolean;
    externalLocationId?: null | string;
    externalPosType?: null | string;
    queryIntervalHours?: null | number;
}

export interface IBottleneckOptions {
    minTime?: number;
    maxConcurrent?: number;
}
