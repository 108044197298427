import {
    d30ToastError,
    DavoLink,
    getAccount,
    getLocationsAll,
    getPartner,
    Loading,
    PartnerLogo,
    portalUrl,
} from "@davo/portal-common";
import { AccountRecord, LocationRecord, Partner } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Button, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { AccountLocationsDetail } from "./AccountLocationsDetail";
import { AddPartnerLocation } from "./AddPartnerLocation";
import { HistoryTable } from "./HistoryTable";

export function AccountDetailPane({ accountId }: { accountId: string }) {
    const navigate = useNavigate();

    const [account, setAccount] = useState<AccountRecord>();
    const [partner, setPartner] = useState<Partner | undefined>(undefined);
    const [activeLocations, setActiveLocations] = useState<LocationRecord[]>();
    const [inactiveLocations, setInactiveLocations] = useState<LocationRecord[]>();

    useAsyncEffect(async () => {
        if (!accountId) {
            return;
        }
        // navigating directly from one account to another accesses the state before it's reloaded, forcing a reset on change
        if (accountId !== account?.id) {
            setActiveLocations(undefined);
            setInactiveLocations(undefined);
        }
        const acc = await getAccount(accountId);
        if (acc.name === "<account issue - try logging in again>") {
            setAccount(undefined);
            d30ToastError("Account not found.");
            navigate("/accounts");
            return;
        } else {
            setAccount(acc);
            if (acc.partnerId) {
                setPartner(await getPartner(acc.partnerId));
            }

            // If we're looking at an alias, then redirect to the actual account id
            if (acc.id !== accountId) {
                navigate(`/accounts/${acc.id}`);
            }
        }
        const locations = await getLocationsAll(accountId);
        setActiveLocations(locations.filter((l) => l.active));
        setActiveLocations(locations.filter((l) => l.active));
        setInactiveLocations(locations.filter((l) => !l.active));
    }, [accountId]);

    const accountHistory = () => {
        return (
            <div style={{ marginTop: "5px" }}>
                <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                        <Typography>History</Typography>
                        <Tooltip
                            title="History of systems actions that happened with this account.  You can keyword search in history.​"
                            placement="right">
                            <HelpTwoToneIcon
                                fontSize="small"
                                color="primary"
                                style={{ verticalAlign: "middle", margin: "5px" }}
                            />
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex" }}>
                        <HistoryTable accountId={account?.id} />
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    return (
        <div>
            {account ? (
                <div style={{ display: "inline-flex", width: "100%", justifyContent: "space-between" }}>
                    <Breadcrumbs
                        style={{ marginBottom: 10, marginLeft: 10 }}
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb">
                        <DavoLink to="/accounts">Accounts</DavoLink>
                        <Typography color="textPrimary">{account.name}</Typography>
                        {partner && (
                            <PartnerLogo
                                partnerTag={partner.tag}
                                partnerName={partner.name}
                                style={{ width: "80px", margin: "-10px 10px -10px 10px" }}
                            />
                        )}
                    </Breadcrumbs>
                    <div style={{ marginRight: 56, display: "inline-block" }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(`${portalUrl}/${account.id}?opsLogin=required`, "_blank");
                            }}>
                            <Typography>Portal</Typography>
                        </Button>
                        <Tooltip
                            title="Access the customer's portal here, a new tab will open into the customer portal view."
                            placement="bottom-end">
                            <HelpTwoToneIcon
                                fontSize="small"
                                color="primary"
                                style={{ verticalAlign: "middle", margin: "12px" }}
                            />
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <Loading />
            )}

            <AccountLocationsDetail account={account} locations={activeLocations} />

            {accountHistory()}

            {inactiveLocations && inactiveLocations.length > 0 && activeLocations && (
                <div style={{ marginTop: "5px" }}>
                    <Accordion
                        slotProps={{ transition: { unmountOnExit: true } }}
                        defaultExpanded={activeLocations.length === 0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                            <Typography>Inactive Locations</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <AccountLocationsDetail account={account} locations={inactiveLocations} />
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}

            {partner && !partner.restrictAddLocation && (
                <div style={{ marginTop: "5px" }}>
                    <Accordion slotProps={{ transition: { unmountOnExit: true } }} defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: "20px" }}>
                            <Typography>Add Location</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "flex" }}>
                            <AddPartnerLocation
                                addType={"location"}
                                accountId={accountId}
                                onSubmit={() => {
                                    window.location.replace(`/accounts/${accountId}`);
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    );
}
