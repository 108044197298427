import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";
import { TextField } from "../TextField";
import { useTaxProfileFormContext } from "../context";

export const FEIN: FunctionComponent = () => {
    const {
        isSubmitting,
        feinFieldDefinition,
        federalTaxId,
        federalTaxIdValidationResults,
        setFederalTaxId,
        filingType,
        shouldBypassDetails,
        isTakeGiveBack,
        linked,
        showLinkedTaxProfileDetails,
    } = useTaxProfileFormContext();

    if (
        !filingType ||
        shouldBypassDetails ||
        isTakeGiveBack ||
        !feinFieldDefinition ||
        !feinFieldDefinition.isVisible ||
        (linked && (!linked.jurisdictionUIAdditionalTaxProfile.showFederalTaxId || !showLinkedTaxProfileDetails))
    ) {
        return null;
    }

    return (
        <TextField
            data-testid={"feinContainer"}
            inputProps={{
                [`data-testid`]: "feinInput",
                pattern: feinFieldDefinition.validationRegex,
            }}
            InputProps={{
                endAdornment: feinFieldDefinition.toolTip && (
                    <Tooltip title={feinFieldDefinition.toolTip}>
                        <InfoIcon fontSize={"small"} color="primary" />
                    </Tooltip>
                ),
            }}
            validate={() => federalTaxIdValidationResults}
            className="fs-exclude feinContainer"
            label={`${feinFieldDefinition.label} ${
                feinFieldDefinition.helpText ? ` (e.g. ${feinFieldDefinition.helpText})` : ""
            }`}
            isDisabled={isSubmitting}
            value={federalTaxId}
            onChange={setFederalTaxId}
            isRequired
        />
    );
};
