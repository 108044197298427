import { darkTheme, lightTheme, LoginContext, LoginContextProvider } from "@davo/portal-common";
import "@davo/portal-common/lib/toast.css";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PartnerPortalConfigContextProvider } from "./context";
import { PartnerRouter } from "./Routes";

export function Preload() {
    return (
        <PartnerPortalConfigContextProvider>
            <LoginContextProvider>
                <LoginContext.Consumer>
                    {(login) => (
                        <ThemeProvider theme={login.isDarkMode ? darkTheme : lightTheme}>
                            <PartnerRouter />
                            <ToastContainer />
                        </ThemeProvider>
                    )}
                </LoginContext.Consumer>
            </LoginContextProvider>
        </PartnerPortalConfigContextProvider>
    );
}
