import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useTaxProfileFormContext } from "../context";

export const BypassDetailsToggle: FunctionComponent = () => {
    const { linked, isEditing, filingType, shouldBypassDetails, setShouldBypassDetails, hasBaseAccessPerms } =
        useTaxProfileFormContext();

    if (!filingType || isEditing || !hasBaseAccessPerms || !!linked) {
        return null;
    }

    return (
        <FormGroup data-testid={"bypassDetailsContainer"}>
            <FormControlLabel
                control={
                    <Switch
                        data-testid={"bypassDetailsToggle"}
                        checked={shouldBypassDetails}
                        onChange={(event, isChecked) => setShouldBypassDetails(isChecked)}
                    />
                }
                label="Send this Tax Profile to the merchant to enter the detail"
            />
        </FormGroup>
    );
};
