import React from "react";

import { UserScreenMode } from "@davo/types";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, lighten, Theme, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { ReactTableFilter } from "./ReactTableFilter";

const useToolbarStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.mode === ("light" as UserScreenMode)
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

export function ReactTableToolbar(props: {
    numSelected: number;
    title?: string;
    preGlobalFilteredRows: any;
    setGlobalFilter: any;
    globalFilter: any;
    refresh?: any;
}) {
    const classes = useToolbarStyles();
    const { numSelected, preGlobalFilteredRows, setGlobalFilter, globalFilter, title, refresh } = props;
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <></>
            )}
            {numSelected === 0 && title ? (
                <Typography className={classes.title} variant="h6" id="tableTitle">
                    {title}
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle"></Typography>
            )}
            {numSelected > 0 ? (
                <div />
            ) : (
                <ReactTableFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            )}
            {refresh && (
                <IconButton size="small" color="inherit" disabled={false} onClick={refresh}>
                    <RefreshIcon />
                </IconButton>
            )}
        </Toolbar>
    );
}
