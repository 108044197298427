import { CommonPortalConfigContext, LoadingWithLogo } from "@davo/portal-common";
import { emptyDefaultPartnerPortalConfig, ICommonPortalConfigType, IPartnerPortalConfigType } from "@davo/types";
import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { getPartnerConfig } from "../services";

export interface IPartnerPortalConfigContext {
    partnerPortalConfigInfo: IPartnerPortalConfigType;
}

export const PartnerPortalConfigContextDefaultValue: IPartnerPortalConfigContext = {
    partnerPortalConfigInfo: emptyDefaultPartnerPortalConfig,
};
export const PartnerPortalConfigContext = React.createContext(PartnerPortalConfigContextDefaultValue);
export const usePartnerPortalConfigContext = () => React.useContext(PartnerPortalConfigContext);

export const PartnerPortalConfigContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [partnerPortalConfigInfo, setPartnerPortalConfigInfo] = useState<IPartnerPortalConfigType>();
    const [commonPortalConfigInfo, setCommonPortalConfigInfo] = useState<ICommonPortalConfigType>();

    useAsyncEffect(async () => {
        const config = await getPartnerConfig();
        setPartnerPortalConfigInfo(config);
        setCommonPortalConfigInfo(config);
    }, []);

    if (!commonPortalConfigInfo || !partnerPortalConfigInfo) {
        return <LoadingWithLogo />;
    }

    return (
        <PartnerPortalConfigContext.Provider
            value={{
                partnerPortalConfigInfo,
            }}>
            <CommonPortalConfigContext.Provider
                value={{
                    commonPortalConfigInfo,
                }}>
                {children}
            </CommonPortalConfigContext.Provider>
        </PartnerPortalConfigContext.Provider>
    );
};
