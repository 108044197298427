import {
    AppType,
    isProduction,
    POSType,
    validateEmail,
    validateNotNull,
    validatePassword,
    validatePhone,
} from "@davo/types";
import { Box, Button, Theme, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React, { FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCommonPortalConfigContext, useLoginContext } from "./context";
import { FormError } from "./FormError";
import { ResetPasswordModal } from "./ResetPasswordModal";
import avalaraLogo from "./resources/avalara.svg";
import computerTaxLogo from "./resources/comp_tax.png";
import davoLogo from "./resources/davo_logo.png";
import oktaLogo from "./resources/okta-logo.png";
import { auth } from "./services";
import { TextField } from "./TextField";
import { d30Toast, d30ToastError } from "./Toast";
import { useModalEditor } from "./useModal";
import { apiUrl } from "./util";

const styles = makeStyles((theme: Theme) => ({
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        width: "300px",
    },
    avalaraButton: {
        borderWidth: "2px",
        boxShadow: "0 10px 15px 0 rgb(0 0 0 / 15%)",
        minWidth: "150px",
        cursor: "pointer",
        fontFamily: "source-sans-pro,Arial,sans-serif",
        fontSize: "18px",
        lineHeight: "27px",
        display: "inline-block",
        marginBottom: "0",
        textAlign: "center",
        verticalAlign: "middle",
        touchAction: "manipulation",
        whiteSpace: "normal",
        fontWeight: "600",
        borderRadius: "3px",
        minHeight: "40px",
        padding: "10px 20px",
        transition: "all .2s ease-in-out",
        backgroundColor: "#fff",
        color: "#1a1a1a",
    },
}));

export interface ILoginForm {
    appType: AppType;
    showCreate?: boolean;
    useAlternateLogo?: boolean;
    shouldCreateOnly?: boolean;
    email?: string | null;
    doesExist?: boolean;
    name?: string;
    appName?: string;
    googleOAuthClientId?: string;
    disableInternalLogin?: boolean;
    merchantInvitationId?: string | null;
    userInvitationId?: string | null;
    partnerInvitationId?: string | null;
    isAvalaraIdentityEnabled?: boolean | null;
    isOpsLoginEnabled?: boolean | null;
    posType?: POSType | null;
}

const validatePasswordField = (pw: string | undefined) => {
    return pw ? validatePassword(pw) : validateNotNull(null);
};

const validateUsernameField = (email: string | undefined | null) => {
    return email ? validateEmail(email) : validateNotNull(null);
};

export function LoginForm({
    showCreate,
    useAlternateLogo,
    shouldCreateOnly,
    email,
    doesExist,
    name,
    appName = "Portal",
    appType,
    googleOAuthClientId,
    disableInternalLogin = false,
    merchantInvitationId,
    userInvitationId,
    partnerInvitationId,
    isAvalaraIdentityEnabled = false,
    isOpsLoginEnabled = false,
    posType,
}: ILoginForm) {
    const classes = styles();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const { commonPortalConfigInfo: configInfo } = useCommonPortalConfigContext();
    const loginContext = useLoginContext();

    const [areCreating, setAreCreating] = useState<boolean>(shouldCreateOnly || showCreate || false);
    const [username, setUsername] = useState<string>(email || "");
    const [usernameValidationResults, setUsernameValidationResults] = useState<string>();
    const [password, setPassword] = useState<string>("");
    const [pwdValidationResults, setPwdValidationResults] = useState<string>();
    const [fullname, setFullname] = useState<string>(name || "");
    // NOTE: invitation phone is purposefully passed in here as of 10/21/21; was too often the store's phone
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);
    const [showResetPasswordModal, resetPasswordModalProps] = useModalEditor<string>();

    if (params.get("opsLogin") === "required") {
        isOpsLoginEnabled = true;
    }

    // check existing and switch to login automatically
    useEffect(() => {
        if (email && doesExist) {
            setAreCreating(false);
        }
    }, [email, doesExist]);

    useEffect(() => {
        const pwdValidation = validatePasswordField(password);
        setPwdValidationResults(pwdValidation);
    }, [password]);

    useEffect(() => {
        const usernameValidation = validateUsernameField(username);
        setUsernameValidationResults(usernameValidation);
    }, [username]);

    const doReset = () => {
        auth.resetPassword(username, appType)
            .then(() => {
                resetPasswordModalProps.closeDialog();
                d30Toast("If that email corresponds to an account, a password reset email was sent!");
            })
            .catch((e) => d30ToastError(e.message))
            .finally(() => setIsResettingPassword(false));
    };

    const authenticate = async () => {
        if (areCreating && (merchantInvitationId || userInvitationId || partnerInvitationId || posType)) {
            try {
                const data = await auth.create(
                    fullname,
                    username,
                    password,
                    phoneNumber,
                    merchantInvitationId,
                    userInvitationId,
                    partnerInvitationId,
                    false,
                    posType
                );
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.error(e); // eslint-disable-line no-console
                setMessage(e.message || "Problem creating account.");
                setIsDisabled(false);
            }
        } else {
            try {
                const data = await auth.authenticate(username, password, appType);
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(auth.getAuthorizationError(e));
                setIsDisabled(false);
                return null;
            }
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isDisabled) return;

        setIsDisabled(true);

        if (
            !username ||
            !!usernameValidationResults ||
            !!pwdValidationResults ||
            (areCreating && !fullname) ||
            (areCreating && !phoneNumber)
        ) {
            setMessage("Please correct form fields");
            setIsDisabled(false);
            return;
        } else {
            setMessage(undefined);
        }

        await authenticate();
    };

    const handleGoogleLogin = async (response: CredentialResponse) => {
        setMessage(undefined);
        setIsDisabled(true);

        if (areCreating) {
            try {
                const data = await auth.createUserFromGoogle(response.credential!, appType, false, posType);
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(e.message ?? "Unable to create user with Google");
                setIsDisabled(false);
            }
        } else {
            try {
                const data = await auth.authenticateGoogle(response.credential!, appType);
                loginContext.setUser(data.user);
            } catch (e: any) {
                console.log(e); // eslint-disable-line no-console
                setMessage(auth.getAuthorizationError(e));
                setIsDisabled(false);
            }
        }
    };

    const handleGoogleLoginError = () => {
        setMessage("Unable to login with Google");
    };

    const handleAvalaraIdentityLogin = () => {
        if (areCreating && (merchantInvitationId || userInvitationId || partnerInvitationId)) {
            const queryParams = [];

            if (merchantInvitationId) {
                queryParams.push(`merchantInvitationId=${merchantInvitationId}`);
            }

            if (userInvitationId) {
                queryParams.push(`userInvitationId=${userInvitationId}`);
            }

            if (partnerInvitationId) {
                queryParams.push(`userInvitationId=${partnerInvitationId}`);
            }

            window.location.assign(`${apiUrl}/ai/login/create?${queryParams.join("&")}`);
        } else {
            window.location.assign(`${apiUrl}/ai/login/auth`);
        }
    };

    const handleOpsLogin = () => {
        const url = location.pathname.slice(1);
        window.location.assign(`${apiUrl}/opslogin/login?path=${url}&appType=${appType}`);
    };

    return (
        <Box
            onSubmit={handleSubmit}
            component={"form"}
            className={classes.container}
            data-testid={"loginForm"}
            autoComplete="off">
            {!isProduction(configInfo.d30Env) && (
                <Typography
                    data-testid={"devEnvNotice"}
                    variant={"subtitle1"}
                    sx={{
                        color: "#D92B2B",
                        marginTop: "12px",
                    }}>
                    ({configInfo.d30Env})
                </Typography>
            )}

            <img
                data-testid={"logoImg"}
                src={useAlternateLogo ? computerTaxLogo : davoLogo}
                alt={
                    useAlternateLogo
                        ? "Generic logomark of computer and tax document"
                        : "Logotype for Davo (by Avalara)"
                }
                style={{ width: "150px" }}
            />

            <Typography
                data-testid={"primaryHeading"}
                variant="h5"
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                }}>
                {appName}
            </Typography>

            {googleOAuthClientId && (
                <>
                    <div
                        data-testid={"googleOAuthClientContainer"}
                        style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                        <GoogleOAuthProvider clientId={googleOAuthClientId}>
                            <GoogleLogin
                                onSuccess={async (credentialResponse) => {
                                    await handleGoogleLogin(credentialResponse);
                                }}
                                onError={handleGoogleLoginError}
                            />
                        </GoogleOAuthProvider>
                    </div>

                    {(!disableInternalLogin || isAvalaraIdentityEnabled) && (
                        <Typography variant="subtitle2" component={"div"}>
                            OR
                        </Typography>
                    )}
                </>
            )}

            {isAvalaraIdentityEnabled && (
                <>
                    <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                        <Button
                            data-testid={"avalaraIdentityLoginBtn"}
                            disabled={isDisabled}
                            variant="outlined"
                            className={classes.avalaraButton}
                            endIcon={
                                <img
                                    style={{ padding: "4px 2px", maxWidth: "80px" }}
                                    src={avalaraLogo}
                                    alt={"Avalara logomark"}
                                />
                            }
                            onClick={handleAvalaraIdentityLogin}>
                            <span style={{ marginBottom: "-2px" }}>Log in to</span>
                        </Button>
                    </div>

                    {!disableInternalLogin && (
                        <Typography variant="subtitle2" component={"div"}>
                            OR
                        </Typography>
                    )}
                </>
            )}

            {isOpsLoginEnabled && (
                <>
                    <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                        <Button
                            data-testid={"oktaLoginBtn"}
                            variant="contained"
                            className={classes.avalaraButton}
                            endIcon={
                                <img
                                    style={{ padding: "4px", maxWidth: "80px" }}
                                    src={oktaLogo}
                                    alt={"Okta logomark"}
                                />
                            }
                            onClick={handleOpsLogin}>
                            <span style={{ marginBottom: "-2px" }}>Log in to</span>
                        </Button>
                    </div>

                    {!disableInternalLogin && (
                        <Typography variant="subtitle2" component={"div"}>
                            OR
                        </Typography>
                    )}
                </>
            )}

            {!disableInternalLogin && (
                <>
                    {areCreating && (
                        <TextField
                            data-testid={"nameInputContainer"}
                            inputProps={{
                                [`data-testid`]: "nameInput",
                            }}
                            label="Your Name"
                            className="fs-exclude"
                            value={fullname}
                            isDisabled={isDisabled}
                            onChange={setFullname}
                            isAutoFocus
                            isRequired
                            validate={validateNotNull}
                        />
                    )}
                    {areCreating && (
                        <TextField
                            data-testid={"phoneInputContainer"}
                            inputProps={{
                                [`data-testid`]: "phoneInput",
                            }}
                            label="Your mobile phone"
                            className="fs-exclude"
                            isDisabled={isDisabled}
                            value={phoneNumber}
                            isRequired
                            validate={(v) => (v ? validatePhone(v) : validateNotNull(null))}
                            onChange={setPhoneNumber}
                        />
                    )}
                    {areCreating && (
                        <span
                            data-testid={"smsNotice"}
                            style={{ fontSize: "9px", color: "darkGray", lineHeight: "12px", marginBottom: "5px" }}>
                            By providing my mobile number, I agree to receive text messages from DAVO by Avalara
                            regarding my service.{" "}
                        </span>
                    )}

                    <TextField
                        inputProps={{
                            [`data-testid`]: "emailUsernameInput",
                        }}
                        name="email"
                        type="email"
                        isDisabled={isDisabled}
                        className="fs-exclude"
                        label="Email Address/Username"
                        value={username}
                        onChange={setUsername}
                        validate={() => usernameValidationResults}
                    />

                    <TextField
                        inputProps={{
                            [`data-testid`]: "pwdInput",
                        }}
                        name="password"
                        helperText={"Minimum of 8 characters"}
                        className="fs-exclude"
                        label="Password"
                        isDisabled={isDisabled}
                        isPassword
                        value={password}
                        onChange={setPassword}
                        validate={() => pwdValidationResults}
                    />
                </>
            )}

            <FormError message={message} />

            {params.get("errorState") && <Typography variant="subtitle2">{params.get("errorState")}&nbsp;</Typography>}

            {!disableInternalLogin && (
                <>
                    <Button
                        data-testid={"primarySubmitButton"}
                        type="submit"
                        disabled={isDisabled}
                        startIcon={
                            isDisabled && <CircularProgress disableShrink size={"1rem"} style={{ color: "inherit" }} />
                        }
                        fullWidth
                        variant="contained"
                        sx={{ marginTop: "8px" }}>
                        {areCreating ? "Create account" : "Sign in"}
                    </Button>

                    {!areCreating && (
                        <Button
                            data-testid={"launchResetPwdDialogBtn"}
                            variant="text"
                            disabled={isDisabled}
                            style={{
                                margin: "24px auto 12px",
                                padding: "12px",
                                cursor: "pointer",
                                textTransform: "none",
                            }}
                            onClick={() => showResetPasswordModal()}>
                            Reset password
                        </Button>
                    )}
                </>
            )}

            {!shouldCreateOnly && showCreate && (
                <Button
                    variant={"text"}
                    data-testid={"switchLoginMethodBtn"}
                    style={{ marginTop: "20px", cursor: "pointer" }}
                    disabled={isDisabled}
                    onClick={() => {
                        setAreCreating(!areCreating);
                        setMessage(undefined);
                    }}>
                    {areCreating ? "Login to existing account" : "Create Account"}
                </Button>
            )}

            {resetPasswordModalProps.isDialogOpen && (
                <ResetPasswordModal
                    doReset={doReset}
                    usernameValidationResults={usernameValidationResults}
                    username={username}
                    setUsername={setUsername}
                    isResettingPassword={isResettingPassword}
                    setIsResettingPassword={setIsResettingPassword}
                    {...resetPasswordModalProps}
                />
            )}
        </Box>
    );
}
