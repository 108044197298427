export enum OnboardCommunicationStatuses {
    "contacted" = "Contacted",
    "new" = "New",
}

export type OnboardCommunicationStatus = keyof typeof OnboardCommunicationStatuses;

export const OnboardCommunicationStatusTypes = [
    ...(Object.keys(OnboardCommunicationStatuses) as OnboardCommunicationStatus[]),
];
