import { isProduction, UserScreenMode } from "@davo/types";
import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    CssBaseline,
    Drawer,
    IconButton,
    InputBase,
    List,
    Menu,
    MenuItem,
    PopoverVirtualElement,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useCommonPortalConfigContext, useLoginContext } from "./context";
import "./Donut.css";
import { DonutSubMenu, INavSubMenu } from "./DonutSubMenu";
import { INavItem, NavItem } from "./NavItem";
import davoLogo from "./resources/davo_logo.png";
import davoLogoWhite from "./resources/davo_logo_white.png";
import { auth } from "./services";
import { d30ToastError } from "./Toast";
import { UserAvatar } from "./UserAvatar";

const drawerWidth = 220;

const useStyles = makeStyles((theme: any) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    header: {
        width: "100%",
    },
    h1: {
        marginLeft: 0,
        paddingLeft: 0,
        [theme.breakpoints.up("md")]: {
            paddingLeft: drawerWidth - 20,
        },
    },
    appBar: {
        [theme.breakpoints.up("xs")]: {
            left: 0,
            width: `100%`,
        },
        boxShadow: "none",
        borderBottom: "1px solid #e8ecef",
    },
    menuButton: {
        display: "inline-flex",
    },
    toolbar: {
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        width: drawerWidth,
        paddingLeft: "5px",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    buildInfo: {
        fontSize: "10px",
        color: "gray",
        padding: `0px 0px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
        display: "flex",
        flexGrow: 4,
        flexBasis: 0,
        alignItems: "flex-end",
        justifyContent: "start",
    },
}));

withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        border: "none",
        boxShadow: "none",
        padding: "5px",
        marginRight: "8px",
        paddingLeft: "6px",
        position: "relative",
        fontSize: "20px",
    },
}))(InputBase);

export interface INavMenuItem extends INavItem {
    element: ReactNode;
}

export interface Props {
    primary: INavMenuItem[];
    subMenus?: INavSubMenu[];
    header: ReactNode;
}

export const Donut: FunctionComponent<PropsWithChildren<Props>> = ({ children, primary, subMenus, header }) => {
    const classes = useStyles();
    const theme: Theme = useTheme();
    const [isMobileOpen, setIsMobileOpen] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<
        Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined
    >(null);
    const navigate = useNavigate();
    const { commonPortalConfigInfo: configInfo } = useCommonPortalConfigContext();
    const loginContext = useLoginContext();

    // NOTE: accountId will be in the url for the merchant portal, but not the ops or partner portals
    const { accountId } = useParams();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const logout = () => {
        auth.logout()
            .catch(() => {
                d30ToastError("Unable to logout due to Donut error!");
            })
            .finally(() => {
                loginContext.setUser(undefined);
            });

        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClicked = () => {
        setAnchorEl(null);
        dismissMobile();

        if (accountId) {
            navigate(`/${accountId}/profile`);
        } else {
            navigate(`/profile`);
        }
    };

    const handleDrawerToggle = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const dismissMobile = () => {
        setIsMobileOpen(false);
    };

    const drawer = (
        <div>
            <div
                className={classes.toolbar}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {!isProduction(configInfo.d30Env) && (
                    <div style={{ position: "absolute", top: "10px", left: "10px", color: "red" }}>
                        ({configInfo.d30Env})
                    </div>
                )}
                {theme.palette.mode === ("dark" as UserScreenMode) && (
                    <img src={davoLogoWhite} style={{ width: "120px" }} alt="DAVO Logo" />
                )}
                {theme.palette.mode !== ("dark" as UserScreenMode) && (
                    <img src={davoLogo} style={{ width: "120px" }} alt="DAVO Logo" />
                )}
            </div>
            <List style={{ paddingTop: "0px" }}>
                {primary.map((item) => (
                    <NavItem
                        key={`donut-nav-${item.slug}`}
                        slug={item.slug}
                        name={item.name}
                        icon={item.icon}
                        badge={item.badge}
                        destination={item.destination}
                        onClick={dismissMobile}
                    />
                ))}
                {subMenus &&
                    subMenus.map((subMenu) => (
                        <DonutSubMenu
                            name={subMenu.name}
                            slug={subMenu.slug}
                            destination={subMenu.destination}
                            key={`donut-sub-menu-${subMenu.slug}`}
                            icon={subMenu.icon}
                            items={subMenu.items}
                            badge={subMenu.badge}
                            onClick={dismissMobile}
                        />
                    ))}
            </List>
        </div>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ maxWidth: "100%" }} className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} color="inherit">
                    <Toolbar className={classes.header}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h1" className={classes.h1} noWrap style={{ flex: 1, fontSize: "20px" }}>
                            {header}
                        </Typography>
                        <Tooltip title={loginContext.user?.email} placement="top">
                            <div>
                                <UserAvatar size={35} onClick={handleClick} />
                            </div>
                        </Tooltip>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            <MenuItem onClick={handleProfileClicked}>Profile</MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    {/*show sm and down*/}
                    <Drawer
                        sx={{ display: { xs: "block", md: "none" } }}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={isMobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        {drawer}
                    </Drawer>
                    {/*show md and up*/}
                    <Drawer
                        sx={{ display: { xs: "none", md: "block" } }}
                        classes={{ paper: classes.drawerPaper }}
                        variant="permanent"
                        open>
                        {drawer}
                        <div className={classes.buildInfo}>DAVO_BUILD_STAMP</div>
                    </Drawer>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {children}
                    {/* This is to push the content down on the page so the header doesn't overlap... */}

                    {/* used by react-router v6 as a way to define where nested content goes */}
                    <Outlet />
                </main>
            </div>
        </LocalizationProvider>
    );
};
