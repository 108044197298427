import { ITaxProfileFormData } from "./Account";
import { BOOLEAN, ResolveObject, STRING, UUID } from "./MetaType";

export const MetaPartner = {
    id: UUID(),
    name: STRING(),
    restrictFileOnly: BOOLEAN(),
    restrictBankPause: BOOLEAN(),
    restrictMerchantOnly: BOOLEAN(),
    restrictOpsFailingCreds: BOOLEAN(),
    restrictBankSharing: BOOLEAN(),
    firstMonthMerchantPay: BOOLEAN(),
    restrictPartialPay: BOOLEAN(),
    restrictAddLocation: BOOLEAN(),
    restrictShowDashboard: BOOLEAN(),
    restrictWelcomeEmail: BOOLEAN(),
    restrictEditBankInfo: BOOLEAN(),
    restrictBoardingFlow: BOOLEAN(),
    restrictSalesData: BOOLEAN(),
    restrictUseData: BOOLEAN(),
    enableConsolidatedBilling: BOOLEAN(),
    phone: STRING().optional(),
    email: STRING().optional(),
    urlLink: STRING().optional(),
    urlLabel: STRING().optional(),
    firstLineSupport: BOOLEAN(),
    tag: STRING(),
    acceptsTermsOfUseForAccounts: BOOLEAN().optional(),
    enableAutomaticallyFailTaxCredentials: BOOLEAN(),
};

export type Partner = ResolveObject<typeof MetaPartner>;

export interface IPartnerLocationData {
    state: string;
    name: string;
    externalLocationId: string;
    address1: string;
    address2?: null | string;
    city: string;
    postalCode: string;
    externalPosType: string;
    invitationEmail?: null | string;
    invitationPhone?: null | string;
    invitationFirstName?: null | string;
    invitationLastName?: null | string;
    bankAccountNumber: string;
    routingNumber: string;
    nickname?: string;
    tp1?: ITaxProfileFormData;
    tp2?: ITaxProfileFormData;
    tp3?: ITaxProfileFormData;
    tp4?: ITaxProfileFormData;
    accountId?: null | string;
    partnerId: string;
    existingTaxProfileIds?: null | string[];
    notes?: null | string;
    queryIntervalHours?: null | number;
}
