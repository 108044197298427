export type D30Environment = "dev" | "qa" | "sbx" | "stg" | "prd" | "rr" | "not-set-yet";

export interface ICommonPortalConfigType {
    d30Env: D30Environment;
    features: { [key: string]: boolean };
    credsCheck: {
        davoEnabled: boolean;
        skyScraperEnabled: boolean;
    };
}

export const emptyDefaultCommonConfig = {
    d30Env: "not-set-yet" as D30Environment,
    features: {},
    credsCheck: {
        davoEnabled: false,
        skyScraperEnabled: false,
    },
};

export interface IMerchantPortalConfigType extends ICommonPortalConfigType {
    demoVideo: string;
    revelAppId: string;
    revelInvitationURL: string;
    heartlandRestaurantURL: string;
    heartlandRetailRedirectURL: string;
    heartlandRetailBaseURL: string;
    heartlandRetailClientId: string;
    parBrinkURL: string;
    squareAppId: string;
    squareBaseURL: string;
    poyntBaseURL: string;
    poyntAppId: string;
    poyntCloudAppId: string;
    poyntRedirectURL: string;
    quickBooksConnectLink: string;
    cloverRedirectURL: string;
    cloverBaseURL: string;
    cloverV2BaseURL: string;
    googleOAuthClientId: string;
    spotOnRestaurantInvitationURL: string;
    spotOnRetailInvitationURL: string;
    avalaraIdentityEnabled?: null | boolean;
    shift4AuthUri: string;
    lightspeedESeriesAuthUri: string;
    lightspeedKSeriesAuthURL: string;
    lightspeedLSeriesBaseURL: string;
    lightspeedLSeriesChallenge: string;
    lightspeedLSeriesKey: string;
    lightspeedRSeriesClientId: string;
    lightspeedRSeriesUrl: string;
    lightspeedUSeriesInvitationURL: string;
    lightspeedXSeriesClientId: string;
    lightspeedXSeriesRedirectURL: string;
    lightspeedXSeriesUrl: string;
    fullStoryEnabled: boolean;
    touchBistroInvitationURL: string;
}

export const emptyDefaultMerchantPortalConfig = {
    ...emptyDefaultCommonConfig,
    demoVideo: "",
    revelAppId: "",
    revelInvitationURL: "",
    heartlandRestaurantURL: "",
    heartlandRetailRedirectURL: "",
    heartlandRetailBaseURL: "",
    heartlandRetailClientId: "",
    parBrinkURL: "",
    squareAppId: "",
    squareBaseURL: "",
    poyntBaseURL: "",
    poyntAppId: "",
    poyntCloudAppId: "",
    poyntRedirectURL: "",
    quickBooksConnectLink: "",
    cloverRedirectURL: "",
    cloverBaseURL: "",
    cloverV2BaseURL: "",
    googleOAuthClientId: "",
    spotOnRestaurantInvitationURL: "",
    spotOnRetailInvitationURL: "",
    avalaraIdentityEnabled: null,
    shift4AuthUri: "",
    lightspeedESeriesAuthUri: "",
    lightspeedKSeriesAuthURL: "",
    lightspeedLSeriesBaseURL: "",
    lightspeedLSeriesChallenge: "",
    lightspeedLSeriesKey: "",
    lightspeedRSeriesClientId: "",
    lightspeedRSeriesUrl: "",
    lightspeedUSeriesInvitationURL: "",
    lightspeedXSeriesClientId: "",
    lightspeedXSeriesRedirectURL: "",
    lightspeedXSeriesUrl: "",
    fullStoryEnabled: false,
    touchBistroInvitationURL: "",
};

export interface IOpsPortalConfigType extends ICommonPortalConfigType {
    payorName: string;
    payorAddress: string;
    payorTown: string;
    payorStateAndZIP: string;
    bankName: string;
    filingSettings: {
        maxAdjustmentPercentage: number;
    };
    achOverrideRoutingNumbers: string[];
}

export const emptyDefaultOpsPortalConfig = {
    ...emptyDefaultCommonConfig,
    payorName: "",
    payorAddress: "",
    payorTown: "",
    payorStateAndZIP: "",
    bankName: "",
    filingSettings: {
        maxAdjustmentPercentage: 0,
    },
    achOverrideRoutingNumbers: [],
};

export type IPartnerPortalConfigType = ICommonPortalConfigType;

export const emptyDefaultPartnerPortalConfig = {
    ...emptyDefaultCommonConfig,
};
