import { noop } from "@davo/types";
import { useCallback, useEffect, useState } from "react";

// This is a way of handling modals in tsx files. Also see ConfirmationModal.tsx
// NOTE: handler is called with an id on continue and with an undefined value on cancel
export function useModalEditor<T>(
    handler: (edited?: T) => void = noop,
    shouldOpenInitially = false,
    defaultTargetValue?: T
) {
    const [target, setTarget] = useState<T>();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(shouldOpenInitially);

    useEffect(() => {
        if (defaultTargetValue) {
            setTarget(defaultTargetValue);
        }
    }, [defaultTargetValue]);

    const edit = useCallback((thing?: T) => {
        setTarget(thing);
        setIsDialogOpen(true);
    }, []);

    const closeDialog = useCallback(
        (thing?: T) => {
            setIsDialogOpen(false);
            handler(thing);
        },
        [handler]
    );

    return [edit, { target, isDialogOpen, closeDialog }] as const;
}
