import { d30ToastError, Loading, LoginForm, useLoginContext } from "@davo/portal-common";
import { IPartnerInvitationInfo } from "@davo/types";
import { Alert, Button, Grid, Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as Sentry from "@sentry/browser";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import illustrationsData from "./resources/davo_illustrations_data.png";
import { getPartnerInvitationInfo, redeemPartnerInvitation } from "./services";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: "20px",
        height: "100%",
    },
    control: {
        padding: theme.spacing(2),
    },
    panel: {
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    item: {
        maxWidth: "400px",
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: "10px",
        marginBottom: "10px",
        width: "50%",
        cursor: "pointer",
    },
}));

export function PartnerInvitationLogin() {
    const classes = useStyles();
    const navigate = useNavigate();
    const loginContext = useLoginContext();

    const { code } = useParams();
    const [invitationInfo, setInvitationInfo] = useState<IPartnerInvitationInfo>();
    const [isUnavailable, setIsUnavailable] = useState<boolean>(false);

    useAsyncEffect(async () => {
        if (!code) {
            return;
        }
        try {
            const invite = await getPartnerInvitationInfo(code);
            if (invite?.status === "redeemed") {
                navigate("/");
            } else if (invite?.status === "expired") {
                setIsUnavailable(true);
            } else {
                setInvitationInfo(invite);
            }
        } catch (e: any) {
            d30ToastError("Failed to load", e);
            setIsUnavailable(true);
        }
    }, [code]);

    useAsyncEffect(async () => {
        if (!code || !loginContext.user) {
            return;
        }
        try {
            await redeemPartnerInvitation(code);
        } catch (e: any) {
            console.error(e); // eslint-disable-line no-console
            Sentry.captureException(e);
            setIsUnavailable(true);
            return;
        }
        navigate("/");
    }, [loginContext.user, code]);

    if (!code) {
        return null;
    }

    return (
        <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            style={{ padding: "10px" }}
            data-testid={"partnerInvitationLogin"}>
            <Grid item xs={12} md={6} lg={6} className={classes.item}>
                <Paper className={classes.paper}>
                    <div className={classes.panel}>
                        <img style={{ width: "200px" }} alt="DAVO" src={illustrationsData} />
                        <div className={classes.panel}>
                            <Typography style={{ fontSize: 20, fontWeight: "bold", margin: "20px" }}>
                                {invitationInfo?.name}
                            </Typography>
                            <Typography style={{ marginBottom: "20px" }}>
                                You&apos;ve been invited to join the DAVO Partner portal.
                            </Typography>
                            <Typography>Please create an account or login to complete the process.</Typography>
                        </div>
                    </div>
                </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={6} className={classes.item}>
                <Paper className={classes.paper}>
                    <div style={{ background: "gray", marginTop: "10px", marginBottom: "10px", width: "1px" }} />
                    <div className={classes.panel}>
                        {isUnavailable && (
                            <div>
                                <div style={{ textAlign: "center" }}>
                                    <Alert severity="error">This invitation is no longer available.</Alert>
                                    <br />
                                    <span>Please contact the account owner to receive another invitation.</span>
                                    <div>
                                        <Button
                                            className={classes.button}
                                            style={{ marginTop: "20px" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate("/")}>
                                            Return
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isUnavailable && !invitationInfo && <Loading />}
                        {invitationInfo && !loginContext.user && (
                            <LoginForm
                                shouldCreateOnly={true}
                                partnerInvitationId={invitationInfo?.id}
                                appType="partnerPortal"
                            />
                        )}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}
