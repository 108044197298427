import {
    d30Toast,
    d30ToastError,
    getJurisdictions,
    getPartner,
    getTaxProfiles,
    Select,
    TaxProfileForm,
    TextField,
    useLoginContext,
} from "@davo/portal-common";
import {
    IJurisdiction,
    initialCap,
    ITaxProfileFormData,
    Partner,
    TaxProfile,
    validateAccountLength,
    validateEmail,
    validatePhone,
    validateRoutingLength,
    validateStringLength,
    validateZip,
} from "@davo/types";
import {
    Autocomplete,
    Button,
    Checkbox,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    TextField as MUITextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { addPartnerLocation, getAccount, getPartnerPosTypes, getPartnersAssociatedToUser } from "./services";

export interface IAddPartnerLocationProps {
    addType: "account" | "location";
    accountId?: string;
    onSubmit?: () => void;
}

export function AddPartnerLocation({ accountId, onSubmit, addType }: IAddPartnerLocationProps) {
    const loginContext = useLoginContext();

    const [partners, setPartners] = useState<Partner[]>([]);
    const [partner, setPartner] = useState<Partner | undefined>();
    const [states, setStates] = useState<IJurisdiction[]>([]);
    const [state, setState] = useState<IJurisdiction | undefined>();
    const [name, setName] = useState<string | undefined>();
    const [externalLocationId, setExternalLocationId] = useState<string | undefined>();
    const [address1, setAddress1] = useState<string | undefined>();
    const [address2, setAddress2] = useState<string | undefined>();
    const [city, setCity] = useState<string | undefined>();
    const [postalCode, setPostalCode] = useState<string | undefined>();
    const [posTypes, setPosTypes] = useState<string[]>([]);
    const [externalPosType, setExternalPosType] = useState<string | null>(null);
    const [invitationPhone, setInvitationPhone] = useState<string | undefined>();
    const [invitationEmail, setInvitationEmail] = useState<string | undefined>();
    const [invitationFirstName, setInvitationFirstName] = useState<string | undefined>();
    const [invitationLastName, setInvitationLastName] = useState<string | undefined>();
    const [bankAccountNumber, setBankAccountNumber] = useState<string | undefined>();
    const [routingNumber, setRoutingNumber] = useState<string | undefined>();
    const [tp1, setTp1] = useState<ITaxProfileFormData>();
    const [tp2, setTp2] = useState<ITaxProfileFormData>();
    const [tp3, setTp3] = useState<ITaxProfileFormData>();
    const [tp4, setTp4] = useState<ITaxProfileFormData>();
    const [notes, setNotes] = useState<string | undefined>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [existingTaxProfiles, setExistingTaxProfiles] = useState<TaxProfile[]>();
    const [selectedExistingTaxProfiles, setSelectedExistingTaxProfiles] = useState<{ [key: string]: boolean }>({});
    const maxNotesLength = 255;
    const navigate = useNavigate();

    useAsyncEffect(async () => {
        setStates(await getJurisdictions());

        if (accountId) {
            const acct = await getAccount(accountId);
            const taxProfiles = await getTaxProfiles(accountId);
            if (acct.partnerId) {
                setPartner(await getPartner(acct.partnerId));
            }

            const theExistingTaxProfiles = taxProfiles.filter((t: TaxProfile) => !t.hidden);
            setExistingTaxProfiles(theExistingTaxProfiles);
            setSelectedExistingTaxProfiles({});
        } else {
            if (loginContext.user) {
                const p = (await getPartnersAssociatedToUser(loginContext.user.id)).filter(
                    (t) => !t.restrictAddLocation
                );
                if (p && p.length === 1) {
                    setPartner(p[0]);
                }
                setPartners(p.sort((a, b) => a.name.localeCompare(b.name)));
            }
        }
    }, []);

    useAsyncEffect(async () => {
        setPosTypes(partner ? await getPartnerPosTypes(partner.id) : []);
    }, [partner]);

    const isValid = () => {
        return (
            !!partner &&
            !!name &&
            !!address1 &&
            !!city &&
            !!postalCode &&
            !!state &&
            !!externalPosType &&
            !!externalLocationId &&
            (accountId ||
                (!accountId &&
                    !!invitationPhone &&
                    !validatePhone(invitationPhone) &&
                    !!invitationEmail &&
                    !validateEmail(invitationEmail) &&
                    !!invitationFirstName &&
                    !!invitationLastName)) &&
            !!postalCode &&
            !validateZip(postalCode) &&
            !!bankAccountNumber &&
            !validateAccountLength(bankAccountNumber) &&
            !!routingNumber &&
            !validateRoutingLength(routingNumber) &&
            (!isEmpty(existingTaxProfiles) || tp1 || tp2 || tp3 || tp4) &&
            !validateStringLength(notes, maxNotesLength)
        );
    };

    const doSubmit = () => {
        if (
            !externalLocationId ||
            !partner ||
            !name ||
            !address1 ||
            !city ||
            !state ||
            !postalCode ||
            !externalPosType ||
            !bankAccountNumber ||
            !routingNumber
        ) {
            return;
        }

        const existingTaxProfileIds = Object.keys(selectedExistingTaxProfiles);
        setIsBusy(true);

        addPartnerLocation({
            partnerId: partner.id,
            state: state.abbreviatedName,
            name,
            externalLocationId,
            address1: address1,
            address2,
            city: city,
            postalCode: postalCode,
            externalPosType,
            invitationEmail,
            invitationPhone,
            invitationFirstName,
            invitationLastName,
            bankAccountNumber,
            routingNumber,
            tp1,
            tp2,
            tp3,
            tp4,
            accountId,
            existingTaxProfileIds,
            notes,
        })
            .then((location) => {
                d30Toast("Successfully added location");
                clearForm();
                onSubmit ? onSubmit() : navigate(`/accounts/${location.accountId}`);
            })
            .catch((e) => {
                d30ToastError("An error occurred while saving.", e);
            })
            .finally(() => setIsBusy(false));
    };

    const clearForm = () => {
        setState(undefined);
        setName(undefined);
        setExternalLocationId(undefined);
        setAddress1(undefined);
        setAddress2(undefined);
        setCity(undefined);
        setPostalCode(undefined);
        setExternalPosType(null);
        setInvitationPhone(undefined);
        setInvitationEmail(undefined);
        setInvitationFirstName(undefined);
        setInvitationLastName(undefined);
        setBankAccountNumber(undefined);
        setRoutingNumber(undefined);
        setTp1(undefined);
        setTp2(undefined);
        setTp3(undefined);
        setTp4(undefined);
        setNotes(undefined);
        setSelectedExistingTaxProfiles({});
        setPartner(undefined);
    };

    const nicerValidatePhone = (v: string) => {
        // An empty value isn't invalid (and doesn't show a msg)
        return v ? validatePhone(v) : undefined;
    };

    const nicerValidateEmail = (v: string) => {
        // An empty value isn't invalid (and doesn't show a msg)
        return v ? validateEmail(v) : undefined;
    };

    const renderHeadingText = () => (addType === "location" ? <h3>Add New Location</h3> : <h2>Add an Account</h2>);

    return (
        <div>
            {renderHeadingText()}
            {!accountId && partners && partners.length > 1 && (
                <Select<Partner>
                    title="Partner"
                    noneLabel={`Select a partner...`}
                    options={partners}
                    value={partner}
                    onChange={setPartner}
                    label={(s) => s.name}
                    isRequired
                />
            )}
            <TextField
                value={name ?? ""}
                onChange={setName}
                label="Name"
                isRequired
                inputProps={{
                    maxLength: 255,
                }}
            />
            <TextField
                value={externalLocationId ?? ""}
                onChange={setExternalLocationId}
                label="External Location ID"
                isRequired
                inputProps={{
                    maxLength: 255,
                }}
            />
            <TextField
                value={address1 ?? ""}
                onChange={setAddress1}
                label="Address1"
                isRequired
                inputProps={{
                    maxLength: 255,
                }}
            />
            <TextField
                value={address2 ?? ""}
                onChange={setAddress2}
                label="Address2"
                inputProps={{
                    maxLength: 255,
                }}
            />
            <TextField
                value={city ?? ""}
                onChange={setCity}
                label="City"
                isRequired
                inputProps={{
                    maxLength: 255,
                }}
            />
            <TextField
                value={postalCode ?? ""}
                onChange={setPostalCode}
                label="Postal Code"
                isRequired
                inputProps={{
                    maxLength: 10,
                }}
                validate={validateZip}
            />
            <Select<IJurisdiction>
                title="State"
                options={states}
                value={state}
                onChange={setState}
                label={(s) => s?.fullName}
                isRequired
            />

            <Autocomplete
                title="POS"
                options={posTypes}
                value={externalPosType}
                onChange={(event, value) => {
                    setExternalPosType(value);
                }}
                renderInput={(params) => <MUITextField {...params} label="Pos Type" />}
                getOptionLabel={(option) => initialCap(option)}
                freeSolo={true}
                filterOptions={(options, params) => {
                    const filter = createFilterOptions<string>();
                    const filtered: string[] = filter(options, params);

                    if (params.inputValue !== "") {
                        filtered.push(params.inputValue);
                    }

                    return filtered;
                }}
            />

            {!accountId && (
                <fieldset style={{ margin: "10px" }}>
                    <legend>Invitation Information</legend>
                    <TextField
                        value={invitationPhone ?? ""}
                        onChange={setInvitationPhone}
                        label="Phone"
                        isRequired
                        inputProps={{
                            maxLength: 255,
                        }}
                        validate={nicerValidatePhone}
                    />
                    <TextField
                        value={invitationEmail ?? ""}
                        onChange={setInvitationEmail}
                        label="Email"
                        isRequired
                        inputProps={{
                            maxLength: 255,
                        }}
                        validate={nicerValidateEmail}
                    />
                    <TextField
                        value={invitationFirstName ?? ""}
                        onChange={setInvitationFirstName}
                        label="First Name"
                        isRequired
                        inputProps={{
                            maxLength: 255,
                        }}
                    />
                    <TextField
                        value={invitationLastName ?? ""}
                        onChange={setInvitationLastName}
                        label="Last Name"
                        isRequired
                        inputProps={{
                            maxLength: 255,
                        }}
                    />
                </fieldset>
            )}
            <fieldset style={{ margin: "10px" }}>
                <legend>Bank Information</legend>
                <TextField
                    value={bankAccountNumber ?? ""}
                    onChange={setBankAccountNumber}
                    label="Bank Account#"
                    isRequired
                    inputProps={{
                        maxLength: 17,
                    }}
                    validate={validateAccountLength}
                />
                <TextField
                    value={routingNumber ?? ""}
                    onChange={setRoutingNumber}
                    label="Routing#"
                    isRequired
                    inputProps={{
                        maxLength: 9,
                    }}
                    validate={validateRoutingLength}
                />
            </fieldset>
            {existingTaxProfiles && !isEmpty(existingTaxProfiles) && (
                <fieldset style={{ margin: "10px" }}>
                    <legend>Existing Tax Profiles</legend>
                    <FormGroup>
                        <ul>
                            {existingTaxProfiles.map((t) => (
                                <li key={t.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedExistingTaxProfiles[t.id] ?? false}
                                                onChange={(_, isChecked) => {
                                                    const updated = { ...selectedExistingTaxProfiles };
                                                    if (isChecked) {
                                                        updated[t.id] = true;
                                                    } else {
                                                        delete updated[t.id];
                                                    }

                                                    setSelectedExistingTaxProfiles(updated);
                                                }}
                                            />
                                        }
                                        label={`${t.name} -  ${t.state}`}
                                    />
                                </li>
                            ))}
                        </ul>
                    </FormGroup>
                </fieldset>
            )}
            <fieldset style={{ margin: "10px" }}>
                <legend>Add New Tax Profiles</legend>
                <ul>
                    <li>
                        <AddEditTaxProfileDialog value={tp1} onChange={setTp1} />
                    </li>
                    <li>
                        <AddEditTaxProfileDialog value={tp2} onChange={setTp2} />
                    </li>
                    <li>
                        <AddEditTaxProfileDialog value={tp3} onChange={setTp3} />
                    </li>
                    <li>
                        <AddEditTaxProfileDialog value={tp4} onChange={setTp4} />
                    </li>
                </ul>

                {isEmpty(existingTaxProfiles) && !(tp1 || tp2 || tp3 || tp4) && (
                    <div style={{ marginBottom: "10px", color: "#D32F2F" }}>Please enter at least one tax profile</div>
                )}
            </fieldset>

            <fieldset>
                <legend>Notes</legend>
                <TextField
                    value={notes ?? ""}
                    onChange={setNotes}
                    label="Notes"
                    validate={(n) => validateStringLength(n, maxNotesLength)}
                    isMultiline
                    rows={3}
                />
            </fieldset>

            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Button variant="contained" color="primary" disabled={!isValid() || isBusy} onClick={doSubmit}>
                    Submit
                </Button>
            </div>
        </div>
    );
}

export function AddEditTaxProfileDialog({
    value,
    onChange,
}: {
    value?: ITaxProfileFormData;
    onChange: (data?: ITaxProfileFormData) => void;
}) {
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [parentErrorMessage, setParentErrorMessage] = useState<string>("");

    const onSubmit = async (formData: ITaxProfileFormData) => {
        try {
            onChange(formData);
            setIsDialogOpen(false);
        } catch (e: any) {
            setParentErrorMessage(e.message);
        }
    };

    const onRemove = () => {
        onChange(undefined);
        setIsDialogOpen(false);
    };

    return (
        <>
            <div style={{ margin: "10px" }}>
                {value && (
                    <span style={{ marginRight: "10px" }}>
                        {value.name} - {value.state?.fullName}
                    </span>
                )}

                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsDialogOpen(true)}
                    style={{ marginRight: "10px" }}>
                    {value ? "Edit" : "Add"}
                </Button>

                {value && (
                    <Button variant="outlined" color="primary" onClick={onRemove}>
                        Remove
                    </Button>
                )}
            </div>

            {isDialogOpen && (
                <Dialog
                    fullScreen={makeFullScreen}
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                    style={{ boxShadow: "none", padding: "10px" }}
                    onClose={() => {
                        setIsDialogOpen(false);
                    }}>
                    <DialogTitle id="responsive-dialog-title">{value ? "Edit" : "Add"} Tax Profile</DialogTitle>
                    <DialogContent>
                        <TaxProfileForm
                            accountId={undefined}
                            onSubmit={onSubmit}
                            onCancel={() => setIsDialogOpen(false)}
                            showCancel={true}
                            shouldLimitEdit={false}
                            value={value}
                            parentErrorMessage={parentErrorMessage}
                        />
                    </DialogContent>
                    <DialogActions />
                </Dialog>
            )}
        </>
    );
}
