import { IPermission, User, UserScreenMode } from "@davo/types";
import React, {
    createContext,
    FunctionComponent,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import useAsyncEffect from "use-async-effect";
import { LoadingWithLogo } from "../LoadingWithLogo";
import { auth, getPermissionsForCurrentUser } from "../services";

export interface ILoginContext {
    user: User | undefined;
    setUser: React.Dispatch<User | undefined>;
    permissions: string[] | undefined;
    firebaseToken?: string;
    setFirebaseToken?: React.Dispatch<string | undefined>;
    isDarkMode: boolean;
}

export const LoginContextDefaultValue: ILoginContext = {
    user: undefined,
    setUser: () => {},
    permissions: undefined,
    isDarkMode: false,
};
export const LoginContext = createContext(LoginContextDefaultValue);
export const useLoginContext = () => useContext(LoginContext);

export const LoginContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [user, setUser] = useState<User>();
    const [userPermissions, setUserPermissions] = useState<string[]>();
    const [firebaseToken, setFirebaseToken] = useState<string>();

    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    const [, setIsTrackingChange] = useState<boolean>(false);

    useAsyncEffect(async () => {
        try {
            const { user: theUser, firebaseToken: theFirebaseToken } = await auth.whoAmI();
            if (!theUser) {
                setUser(undefined);
            } else {
                setFirebaseToken(theFirebaseToken);
                setUser(theUser);
            }
        } catch (e: any) {
            setUser(undefined);
        }
        setIsInitializing(false);
    }, []);

    useAsyncEffect(async () => {
        if (!user) {
            setUserPermissions(undefined);
            setFirebaseToken(undefined);
            return;
        }
        const permissions: string[] = (await getPermissionsForCurrentUser()).map((p: IPermission) => p.permissionTag);
        setUserPermissions(permissions);
    }, [user]);

    const changeListener = useCallback((e: MediaQueryListEvent) => {
        e.matches ? setIsDarkMode(true) : setIsDarkMode(false);
    }, []);

    useEffect(() => {
        if (user?.screenMode === ("os" as UserScreenMode)) {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                setIsDarkMode(true);
            } else {
                setIsDarkMode(false);
            }
            setIsTrackingChange((isTracking) => {
                if (!isTracking) {
                    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", changeListener);
                }
                return true;
            });
        } else if (user?.screenMode === ("dark" as UserScreenMode)) {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
        if (user?.screenMode !== ("os" as UserScreenMode)) {
            window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", changeListener);
        }
    }, [changeListener, user]);

    if (isInitializing) {
        return <LoadingWithLogo />;
    }

    return (
        <LoginContext.Provider
            value={{
                user: user,
                setUser: setUser,
                permissions: userPermissions,
                firebaseToken: firebaseToken,
                setFirebaseToken: setFirebaseToken,
                isDarkMode,
            }}>
            {children}
        </LoginContext.Provider>
    );
};
