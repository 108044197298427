import { DateTime } from "luxon";
import { BusinessDay } from "./BusinessDay";

export type NoticeStatus =
    | "new"
    | "open-working"
    | "open-waiting-on-state"
    | "open-waiting-on-customer"
    | "closed-customer-action"
    | "closed-amended"
    | "closed-final"
    | "closed-duplicate"
    | "closed-cancelled"
    | "closed-invalid-file";
export const NoticeStatuses = {
    new: "New",
    "open-working": "In progress",
    "open-waiting-on-state": "In progress",
    "open-waiting-on-customer": "Action Required",
    "closed-customer-action": "Closed: Action Required",
    "closed-amended": "Closed",
    "closed-final": "Closed",
    "closed-duplicate": "Closed",
    "closed-cancelled": "Cancelled",
    "closed-invalid-file": "Uploaded file was invalid",
} as { [key: string]: string };

export enum NoticeTypes {
    "discrepancy" = "Discrepancy",
    "other" = "Other",
}

export type NoticeType = keyof typeof NoticeTypes;
export const NoticeStatusKeys = [...(Object.keys(NoticeStatuses) as NoticeStatus[])];
export const NoticeTypeKeys = [...(Object.keys(NoticeTypes) as NoticeType[])];

export interface INotice {
    id: string;
    taxProfileId: string;
    periodStart: BusinessDay;
    periodEnd: BusinessDay;
    taxDue: number;
    penalties: number;
    interest: number;
    type?: null | NoticeType;
    fileId: string;
    noteToMerchant?: null | string;
    noteInternal?: null | string;
    noteFromMerchant?: null | string;
    status?: null | NoticeStatus;
    created: DateTime;
    updated: DateTime;
    createdBy: string;
    updatedBy: string;
}
