import { getArray, getOne } from "@davo/portal-common";
import {
    DashboardPartnerFilingsTotal,
    DashboardPartnerUnFilingsByDueDate,
    MetaPartnerDashboardFilingsTotal,
    MetaPartnerDashboardSubscriptionSummary,
    MetaPartnerDashboardUnFilingsByDueDate,
} from "@davo/types";

export async function getSubscriptionsCount() {
    return getOne(`api/partner/dashboard/subscriptions/total`, true, MetaPartnerDashboardSubscriptionSummary);
}

export async function getFiledThisSessionTotal(): Promise<DashboardPartnerFilingsTotal[]> {
    return getArray(`api/partner/dashboard/filings/session/total`, true, MetaPartnerDashboardFilingsTotal);
}

export async function getUnFiledThisSessionByDueDate(): Promise<DashboardPartnerUnFilingsByDueDate[]> {
    return getArray(`api/partner/dashboard/filings/session/byduedate`, true, MetaPartnerDashboardUnFilingsByDueDate);
}
