import {
    ConfirmationModal,
    d30Toast,
    d30ToastError,
    ReactTable,
    Select,
    TextField,
    useLoginContext,
    useModalEditor,
} from "@davo/portal-common";

import { isEmailValid, Partner, PartnerInvitation, toDisplayDateString, User, validateEmail } from "@davo/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import {
    expirePartnerUserInvitation,
    getPartnersAssociatedToUser,
    getPartnerUsers,
    getUserInvitationsForPartner,
    removePartnerInvitation,
    removePartnerUser,
    sendPartnerUserInvitation,
} from "./services";
import { hasPermission } from "./util";

export function ManageInvitations() {
    const loginContext = useLoginContext();

    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [partner, setPartner] = useState<Partner | undefined>();
    const theme = useTheme();
    const makeFullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [invitations, setInvitations] = useState<PartnerInvitation[]>([]);
    const [inactives, setInactives] = useState<any[]>([]);
    const [showConfirmationModal, confirmationModalProps] = useModalEditor<string>(async (userId?: string) => {
        if (userId && partner) {
            try {
                // partner permissions
                // delete partner user table
                await removePartnerUser(userId);
                d30Toast("User removed from account");
                await fetchInvitesAndUsers();
            } catch (e: any) {
                d30ToastError(e.message);
            }
        }
    });

    useAsyncEffect(async () => {
        if (!loginContext.user) {
            return;
        }
        const p = await getPartnersAssociatedToUser(loginContext.user.id);
        if (p && p.length === 1) {
            setPartner(p[0]);
        }
        setPartners(p.sort((a, b) => a.name.localeCompare(b.name)));
    }, [loginContext.user]);

    useAsyncEffect(async () => {
        if (partner) {
            await fetchInvitesAndUsers();
        }
    }, [partner]);

    const checkDate = (id: string) => {
        const u = invitations.find((i) => i.id === id);
        if (!u) {
            return false;
        }
        const inactive = invitations.filter(
            (i) => i.originalEmail === u.originalEmail && u.id !== i.id && u.expires > DateTime.now()
        );
        return !inactive;
    };

    const resendInvitation = async (id: string) => {
        const u = inactives.find((i) => i.id === id);
        if (!partner || !u || !u.originalEmail || checkDate(id)) {
            return;
        }
        await sendPartnerUserInvitation(partner.id, u.originalEmail);
        await Promise.all(
            inactives.map(async (i) => {
                if (i.id === id && i.expires < DateTime.now()) {
                    await removePartnerInvitation(i.id);
                }
            })
        );
        await fetchInvitesAndUsers();
        setCanAdd(false);
        setEmail(undefined);
        d30Toast("User invite sent!");
    };

    const invite = async () => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const actives = invitations.filter((i) => i.originalEmail === email && i.expires > DateTime.now()).length > 0;
        if (actives) {
            const thisInvite = invitations.find((i) => i.originalEmail === email);
            if (thisInvite?.id && thisInvite?.expires >= DateTime.now()) {
                await expirePartnerUserInvitation(thisInvite.id);
                await removePartnerInvitation(thisInvite.id);
                await fetchInvitesAndUsers();
            }
        }
        if (partner && email) {
            await sendPartnerUserInvitation(partner.id, email);
            await fetchInvitesAndUsers();
            setCanAdd(false);
            setEmail(undefined);
            d30Toast("User invite sent!");
        }
    };

    const removeInvite = async (inviteId: string) => {
        if (partner && inviteId) {
            await removePartnerInvitation(inviteId);
            await fetchInvitesAndUsers();
        }
    };

    const removeUser = (userId: string) => {
        showConfirmationModal(userId);
    };

    const fetchInvitesAndUsers = async () => {
        if (partner) {
            const gi = await getUserInvitationsForPartner(partner.id);
            const u = await getPartnerUsers(partner.id);
            setUsers(u);
            const dupes: PartnerInvitation[] = gi
                .map((e: { originalEmail: any }) => e.originalEmail)
                .map((e: any, i: any, final: string | any[]) => final.indexOf(e) !== i && i)
                .filter((obj: string | number) => gi[obj])
                .map((e: string | number) => gi[e]);
            if (dupes.length > 0) {
                for (const d of dupes) {
                    if (d.expires < DateTime.now()) {
                        return removeInvite(d.id);
                    }
                }
            }

            const invitationArr = await getUserInvitationsForPartner(partner.id);
            const expd: PartnerInvitation[] | ((prevState: never[]) => never[]) = [];
            const unexpired: any[] | ((prevState: PartnerInvitation[]) => PartnerInvitation[]) = [];
            for (const i of invitationArr) {
                if (i.expires > DateTime.now()) {
                    unexpired.push(i);
                } else {
                    expd.push(i);
                }
            }

            setInactives(expd);
            setInvitations(unexpired);
        }
    };

    return (
        <>
            {hasPermission(loginContext.permissions, "manage_partner_portal_users") && (
                <>
                    {partners && partners.length > 1 && (
                        <Select<Partner>
                            title="Partner"
                            options={partners}
                            value={partner}
                            onChange={setPartner}
                            label={(s) => s.name}
                            isRequired
                        />
                    )}
                    {partner && (
                        <div
                            style={{
                                textAlign: "right",
                                paddingBottom: "8px",
                                cursor: "pointer",
                            }}>
                            <Link
                                data-testid={"addPartnerUserInvitation"}
                                to="/users"
                                onClick={() => setCanAdd(true)}
                                style={{ color: theme.palette.mode === "dark" ? "#29b6f6" : "primary" }}>
                                <Icon>add_circle_outline</Icon>
                                <Typography>Add</Typography>
                            </Link>
                        </div>
                    )}
                </>
            )}

            <Accordion
                slotProps={{ transition: { unmountOnExit: true } }}
                style={{ boxShadow: "none" }}
                defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                    }}>
                    Partner Users
                </AccordionSummary>
                <ReactTable
                    data={users}
                    columns={[
                        {
                            accessor: "firstName",
                            Cell: (u: any) => (
                                <>
                                    {u.cell.row.original.firstName} {u.cell.row.original.lastName}
                                    <br />
                                    <span style={{ fontSize: ".9em" }}>{u.cell.row.original.email}</span>
                                </>
                            ),
                        },
                        {
                            Header: "",
                            accessor: "id",
                            Cell: (u: any) => (
                                <Button
                                    disabled={loginContext.user && u.cell.row.original.id === loginContext.user?.id}
                                    onClick={() => removeUser(u.cell.row.original.id)}
                                    variant="contained"
                                    color="primary"
                                    size="small">
                                    Remove
                                </Button>
                            ),
                        },
                        {
                            Header: "",
                            accessor: "lastName",
                        },
                        {
                            Header: "",
                            accessor: "email",
                        },
                    ]}
                    options={{
                        hiddenColumns: ["lastName", "email"],
                    }}
                />
            </Accordion>
            {invitations.length > 0 && (
                <>
                    <Accordion
                        data-testid={"pendingInvitationsAccordion"}
                        slotProps={{ transition: { unmountOnExit: true } }}
                        style={{ boxShadow: "none" }}
                        defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                            }}>
                            Pending Invitations
                        </AccordionSummary>
                        <ReactTable
                            data={invitations}
                            columns={[
                                { Header: "Invitation Email", accessor: "originalEmail" },
                                {
                                    Header: "Expiration Date",
                                    accessor: "expires",
                                    Cell: (t: any) => (
                                        <span
                                            style={t.value < DateTime.now() ? { color: "red" } : { color: "primary" }}>
                                            {toDisplayDateString(t.value)}
                                        </span>
                                    ),
                                },
                                {
                                    Header: "",
                                    accessor: "id",
                                    Cell: (t: any) => (
                                        <Button
                                            onClick={() => removeInvite(t.value)}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            Remove
                                        </Button>
                                    ),
                                },
                            ]}
                        />
                    </Accordion>
                </>
            )}

            {inactives.length > 0 && (
                <>
                    <Accordion
                        slotProps={{ transition: { unmountOnExit: true } }}
                        style={{ boxShadow: "none" }}
                        defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                            }}>
                            Expired Invitations
                            <Tooltip
                                title={"Invitations that have expired in the last 30 days will appear here. "}
                                placement="right-end">
                                <HelpTwoToneIcon
                                    fontSize="small"
                                    color="info"
                                    style={{ marginTop: "5px", marginLeft: "10px" }}
                                />
                            </Tooltip>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ReactTable
                                title={"Expired Invitations"}
                                columns={[
                                    { Header: "Invitation Email", accessor: "originalEmail" },
                                    {
                                        Header: "Expiration Date",
                                        accessor: "expires",
                                        Cell: (t: any) => (
                                            <span
                                                style={
                                                    t.value < DateTime.now() ? { color: "red" } : { color: "primary" }
                                                }>
                                                {toDisplayDateString(t.value)}
                                            </span>
                                        ),
                                    },
                                    {
                                        Header: "",
                                        accessor: "id",
                                        Cell: (t: any) => (
                                            <Button
                                                disabled={checkDate(t.value)}
                                                onClick={() => resendInvitation(t.value)}
                                                variant="contained"
                                                color="primary"
                                                size="small">
                                                Resend Invitation
                                            </Button>
                                        ),
                                    },
                                ]}
                                data={inactives}
                            />
                        </AccordionDetails>
                    </Accordion>
                </>
            )}

            {canAdd && (
                <Dialog
                    data-testid={"addPartnerUserInvitationModal"}
                    fullScreen={makeFullScreen}
                    open={true}
                    onClose={() => setCanAdd(false)}
                    aria-labelledby="responsive-dialog-title"
                    style={{ boxShadow: "none", padding: "10px" }}>
                    <DialogTitle id="responsive-dialog-title">Invite user</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the email of the person you would like to invite. We will send them an invitation with
                            a link to connect.
                        </DialogContentText>
                        <TextField
                            label="Email Address"
                            value={email ?? ""}
                            onChange={setEmail}
                            validate={validateEmail}
                            onEnterPress={invite}
                            inputProps={{
                                [`data-testid`]: "partnerUserInvitationEmail",
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCanAdd(false)} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button
                            data-testid={"invitePartnerUserButton"}
                            disabled={!isEmailValid(email)}
                            onClick={invite}
                            variant="contained"
                            color="primary">
                            Invite
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {confirmationModalProps.isDialogOpen && (
                <ConfirmationModal
                    message="Are you sure you want to remove this user's access to your account?"
                    title="Remove user?"
                    {...confirmationModalProps}
                />
            )}
        </>
    );
}
