import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField as MuiTextField } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input";
import { InputBaseProps } from "@mui/material/InputBase";
import React, { KeyboardEvent, useState } from "react";

export interface ITextFieldProps<T> {
    name?: string;
    label: string;
    isDisabled?: boolean;
    value: T;
    helperText?: string;
    onChange?: (v: T) => void;
    validate?: (v: T) => string | undefined;
    isPassword?: boolean;
    onEnterPress?: () => void;
    onBlur?: () => void;
    isAutoFocus?: boolean;
    inputRef?: React.Ref<any>;
    margin?: "none" | "dense" | "normal";
    isMultiline?: boolean;
    rows?: number;
    isRequired?: boolean;
    showFullWidth?: boolean;
    style?: any;
    className?: string;
    variant?: "outlined" | "filled" | "standard";
    type?: React.InputHTMLAttributes<unknown>["type"];
    // these items are two different things and the names match usage.
    InputProps?: Partial<StandardInputProps>;
    inputProps?: InputBaseProps["inputProps"];
}

const noop = () => undefined;

const enterPress = (event: KeyboardEvent<HTMLDivElement>, f: () => void) => {
    if (event.key === "Enter") {
        f();
    }
};

export function TextField({
    name,
    label,
    value,
    onChange = noop,
    isDisabled,
    helperText,
    validate = noop,
    isPassword = false,
    onEnterPress = noop,
    inputRef,
    isAutoFocus,
    onBlur,
    margin = "normal",
    isMultiline,
    rows,
    isRequired = false,
    showFullWidth = true,
    style,
    className,
    type = "text",
    variant = "outlined",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    InputProps = {}, // Case matches the object in MUI
    inputProps = {},
    ...rest
}: ITextFieldProps<any>) {
    const [error, setError] = useState<string | undefined>(undefined);
    const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false);

    const handleBlur = () => {
        setError(undefined);
        const e = validate(value);
        setError(e);

        if (onBlur) {
            onBlur();
        }
    };

    let InputPropsToUse = InputProps;
    if (isPassword) {
        // endAdornment in intentionally listed first allowing inputProps to override
        InputPropsToUse = {
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton aria-label="Show password" onClick={() => setShouldShowPassword(!shouldShowPassword)}>
                        {shouldShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                </InputAdornment>
            ),
            ...InputPropsToUse,
        };
    }

    let typeToUse = type;
    if (isPassword && !shouldShowPassword) {
        typeToUse = "password";
    }

    return (
        <MuiTextField
            name={name}
            label={label}
            autoFocus={isAutoFocus}
            className={className}
            error={!!error}
            type={typeToUse}
            helperText={error || helperText || "  "}
            margin={margin}
            disabled={isDisabled}
            fullWidth={showFullWidth}
            InputProps={InputPropsToUse}
            inputProps={inputProps}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            variant={variant}
            onKeyDown={(event) => enterPress(event, onEnterPress)}
            inputRef={inputRef}
            onBlur={handleBlur}
            multiline={isMultiline}
            // NOTE: # of rows for multiline
            rows={rows}
            required={isRequired}
            style={style}
            {...rest}
        />
    );
}
